import axiosInstance from '../../helpers/axiosInstance'

export const fetchRolesForModule = async (selectedModule) => {
    const payload = {
      module_name: selectedModule,
    };

    try {
        const response = await axiosInstance.post("/persona/fetch_roles", payload);

        if (response.status !== 200) {
            throw new Error(`Error: ${response.status}`);
        }

        return response.data;
    } catch (error) {
        throw error;
    }
};