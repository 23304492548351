// src/authToken.js
const getToken = () => {
    const storedToken = localStorage.getItem('REACT_TOKEN_AUTH_KEY');
    if (storedToken) {
        const authData = JSON.parse(storedToken);
        return authData.access_token; // Assuming 'access_token' is the key
    }
    return null;
};

export default getToken;
