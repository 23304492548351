import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Stack,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  RadioGroup,
  Radio,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";

const OverflowButton = ({ text, onClick }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      setIsOverflowing(
        buttonRef.current.scrollWidth > buttonRef.current.clientWidth
      );
    }
  }, [text]);

  return (
    <Tooltip
      label={text}
      isDisabled={!isOverflowing}
      aria-label={`Full name: ${text}`}
      bg="white"
      boxShadow="xl"
      color="black"
      p={2}
      border="1px solid"
      borderColor="blackAlpha.300"
      borderRadius="lg"
    >
      <Button
        ref={buttonRef}
        onClick={onClick}
        width="90%"
        isTruncated
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        textAlign="left" // Left-align the text
        justifyContent="flex-start"
      >
        {text}
      </Button>
    </Tooltip>
  );
};

// Define the default modules structure to match your database schema
const defaultModules = {
  Home: "allowed",
  NexusGenie: "allowed",
  "Minute Master": "allowed",
  "DDQ Master": "allowed",
  PitchComply: "allowed",
  "Market Master": "allowed",
  NexusScribe: "coming soon",
};

const ModulesAllowed = () => {
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [moduleAccess, setModuleAccess] = useState(null);
  const [updatedModuleAccess, setUpdatedModuleAccess] = useState({});
  const [hasSettings, setHasSettings] = useState(true); // To track if org has settings
  const toast = useToast();

  // Fetch organizations
  const fetchOrganizations = async () => {
    try {
      const response = await axiosInstance.get("/org/get_organizations");
      if (Array.isArray(response.data.organizations)) {
        setOrganizations(response.data.organizations); // Organizations as an array of names
      } else {
        setOrganizations([]);
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
      toast({
        title: "Error",
        description: "Unable to fetch organizations.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Fetch module access for the selected organization
  const fetchModuleAccess = async (orgName) => {
    try {
      const response = await axiosInstance.get(
        `/org/get-module-access/${orgName}`
      );
      if (response.data["module-access"]) {
        setModuleAccess(response.data["module-access"]);
        setUpdatedModuleAccess(response.data["module-access"]); // Set the editable values
        setHasSettings(true); // Organization already has settings
      } else {
        setHasSettings(false); // No settings found
        setModuleAccess(null);
      }
    } catch (error) {
      // Check for 404 status, indicating no data found for the organization
      if (error.response && error.response.status === 404) {
        setHasSettings(false); // No settings found
        setModuleAccess(null);
        toast({
          title: "No Module Access Data",
          description: `No module access data found for ${orgName}. You can add default settings.`,
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error("Error fetching module access:", error);
        toast({
          title: "Error",
          description: `Unable to fetch module access for ${orgName}.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  // Add default modules for the organization
  const addDefaultModules = async (orgName) => {
    try {
      const response = await axiosInstance.post(`/org/add-modules/${orgName}`, {
        organization: orgName,
        "module-access": defaultModules,
      });
      if (response.status === 201) {
        setModuleAccess(defaultModules); // Set default modules
        setUpdatedModuleAccess(defaultModules); // Initialize the state with default modules
        setHasSettings(true); // Indicate that settings are now added
        toast({
          title: "Modules Added",
          description: `Default modules added for ${orgName}.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error adding default modules:", error);
      toast({
        title: "Error",
        description: `Failed to add default modules for ${orgName}.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Update the module access state when radio buttons are changed
  const handleModuleAccessChange = (module, value) => {
    setUpdatedModuleAccess((prevAccess) => ({
      ...prevAccess,
      [module]: value,
    }));
  };

  // Send the updated module access to the backend
  const updateModuleAccess = async () => {
    try {
      const response = await axiosInstance.put(
        `/org/update-module-access/${selectedOrg}`,
        {
          "module-access": updatedModuleAccess,
        }
      );
      toast({
        title: "Success",
        description: `Module access for ${selectedOrg} updated successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating module access:", error);
      toast({
        title: "Error",
        description: `Failed to update module access for ${selectedOrg}.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  return (
    <Box display="flex" height="100%">
      {/* Left Panel: Organizations List */}
      <Box
        width="20%"
        borderRight="1px solid #ccc"
        borderColor="blackAlpha.100"
        p={4}
        height="100%"
        overflowY="auto"
      >
        <Heading size="md" mb="4">
          Organizations
        </Heading>
        <Stack spacing={3}>
          {organizations.map((org, index) => (
            <OverflowButton
              key={index}
              text={org}
              onClick={() => {
                setSelectedOrg(org);
                fetchModuleAccess(org);
              }}
            />
          ))}
        </Stack>
      </Box>

      {/* Right Panel: Module Access Information */}
      <Box width="80%" p={4}>
        {selectedOrg ? (
          <>
            <Heading size="md" mb="4" ml={4}>
              Modules Allowed for {selectedOrg}
            </Heading>
            {hasSettings ? (
              moduleAccess ? (
                <>
                  {/* Box for the Table */}
                  <Box
                    h="79vh"
                    border="1px solid #ccc"
                    borderRadius="md"
                    borderColor="blackAlpha.100"
                    p={4}
                    overflowY="auto"
                    m={4}
                  >
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Module</Th>
                          <Th>Allowed</Th>
                          <Th>Please Inquire</Th>
                          <Th>Coming Soon</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {Object.keys(moduleAccess).map((module) => (
                          <Tr key={module}>
                            <Td>{module}</Td>
                            <Td>
                              <RadioGroup
                                value={updatedModuleAccess[module]}
                                onChange={(value) =>
                                  handleModuleAccessChange(module, value)
                                }
                              >
                                <Radio value="allowed">Allowed</Radio>
                              </RadioGroup>
                            </Td>
                            <Td>
                              <RadioGroup
                                value={updatedModuleAccess[module]}
                                onChange={(value) =>
                                  handleModuleAccessChange(module, value)
                                }
                              >
                                <Radio value="please inquire">
                                  Please Inquire
                                </Radio>
                              </RadioGroup>
                            </Td>
                            <Td>
                              <RadioGroup
                                value={updatedModuleAccess[module]}
                                onChange={(value) =>
                                  handleModuleAccessChange(module, value)
                                }
                              >
                                <Radio value="coming soon">Coming Soon</Radio>
                              </RadioGroup>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                    <Button
                      colorScheme="blue"
                      mt={4}
                      onClick={updateModuleAccess}
                    >
                      Update Module Access
                    </Button>
                  </Box>
                </>
              ) : (
                <Text>Loading module access...</Text>
              )
            ) : (
              <Box textAlign="center" mt={6}>
                <Text mb={4}>No module settings found for {selectedOrg}.</Text>
                <Button
                  colorScheme="green"
                  onClick={() => addDefaultModules(selectedOrg)}
                >
                  Add Default Settings
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Text>Select an organization to view modules allowed.</Text>
        )}
      </Box>
    </Box>
  );
};

export default ModulesAllowed;
