import usePersistedState from "./../usePersistedState";
import { useEffect, useState, useRef } from "react";
import { logout } from "../../auth";
import {
  Box,
  HStack,
  Heading,
  Text,
  Button,
  Flex,
  useToast,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon, DeleteIcon } from "@chakra-ui/icons";
import "../../styles/test.css";
import { useColorMode } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid"; // Import uuid
import { fetchFund } from "../../api/FundDocs/fileApi";
import axiosInstance from "../../helpers/axiosInstance";

const Funddocs_fund = ({
  selectedFund,
  setSelectedFund,
  fetchedFunds,
  setFetchedFunds,
  fundsList,
  setFundsList,
  setCrossRefCheck,
  setCountryCheck,
  setSelectedCountries,
  setMarketingMaterial,
  setFundProspectus,
  setFundType,
  setMarketingToRetail,
  setInsuranceProduct,
  setSfdrArticle,
  fundName,
  setFundName,
  setReport,
  setSummary,
}) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [currentFund, setCurrentFund] = usePersistedState(
    "currentFundUploadFund",
    ""
  );

  const [showInputField, setShowInputField] = usePersistedState(
    "showInputFieldUploadFund",
    false
  );

  const handleRemoveFund = async (fundToRemove) => {
    if (fundToRemove._id) {
      // console.log("Removing", fundToRemove._id);
      try {
        // Assuming you have an API endpoint to delete a fund by its _id
        const response = await axiosInstance.post(
          "fund_docs/delete-fund-docs-by-id",
          {
            str_id: fundToRemove._id,
          }
        );
        if (response.status === 200) {
          // Update the state after successful deletion
          setFetchedFunds((prevState) =>
            prevState.filter((fund) => fund._id !== fundToRemove._id)
          );
          setSelectedFund(null);
          //console.log("setSelectedFund to null");
          setFundName("");
          setCrossRefCheck("No");
          setCountryCheck("No");
          setSelectedCountries([]);
          setMarketingMaterial(null);
          setFundProspectus(null);
          setFundType("");
          setMarketingToRetail("No");
          setInsuranceProduct("No");
          setSfdrArticle("");
          setSummary([]);
          toast({
            title: "Success",
            description: `Fund ${fundToRemove.fund_name} has been removed`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-center",
          });
        }
      } catch (error) {
        console.error(error);
        toast({
          title: "Error",
          description: `Error deleting Fund: ${fundToRemove.fund_name}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-center",
        });
      }
    } else {
      // If the fund doesn't have an _id, it's a newly created fund and not committed yet
      setFundsList((prevState) =>
        prevState.filter((fund) => fund.id !== fundToRemove.id)
      );
    }
  };

  const handleAddFund = () => {
    if (currentFund) {
      // Generate a UUID for the new fund
      const fundId = uuidv4();

      // Use the generated UUID as a key and currentFund as value
      setFundsList((prevState) => [
        ...prevState,
        {
          id: fundId,
          text: currentFund,
        },
      ]);

      // Set the selectedFund with the new fund object
      setSelectedFund({ id: fundId, text: currentFund });
      setFundName("");
      setCrossRefCheck("No");
      setCountryCheck("No");
      setSelectedCountries([]);
      setMarketingMaterial(null);
      setFundProspectus(null);
      setFundType("");
      setMarketingToRetail("No");
      setInsuranceProduct("No");
      setSfdrArticle("");
      setCurrentFund("");
      setReport([]);
      setSummary([]);
    }
  };

  useEffect(() => {
    fetchFund(setFetchedFunds, toast);
  }, []);

  const allFunds = [...fetchedFunds, ...fundsList]; // Ensure both are arrays

  return (
    <Box
      // bg={colorMode === "dark" ? "gray.700" : "white"}
      pt={6}
      p={4}
      pb={2}
      borderRadius="lg"
    >
      <Text
        fontSize="md"
        fontWeight="semibold"
        ml={2}
        mb="4px"
        color={colorMode === "dark" ? "white" : "#1A202C"}
        // color="#1A202C"
      >
        Fund
      </Text>
      <Heading
        fontSize="xs"
        color={colorMode === "dark" ? "white" : "blackAlpha.700"}
        mb={0}
        pl={2}
        pb={2}
      >
        Add the Fund.
      </Heading>
      <Divider mt={0} mb={0} borderColor="#E2E8F0" />
      <Flex justify="space-between" align="center" mb={0}>
        <Heading
          fontSize="md"
          fontWeight="bold"
          color={colorMode === "dark" ? "white" : "blackAlpha.700"}
          mb={0}
          pl={2}
          p={2}
          pr={0}
        >
          Create Fund
        </Heading>
        <IconButton
          variant="ghost"
          aria-label="Add Fund"
          icon={showInputField ? <MinusIcon /> : <AddIcon />}
          colorScheme="gray"
          onClick={() => setShowInputField(!showInputField)}
          size="xs"
          ml={4}
        />
      </Flex>
      {showInputField && (
        <Box pl={2} pb={1}>
          <Input
            placeholder="Enter Fund"
            size="sm"
            mb={2}
            mt={2}
            value={currentFund}
            onChange={(e) => setCurrentFund(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") handleAddFund();
            }}
          />
          <Button
            size="sm"
            color={colorMode === "dark" ? "white" : "blackAlpha.700"}
            onClick={handleAddFund}
          >
            Add Fund
          </Button>
        </Box>
      )}
      <Box
        mt={0}
        mb={2}
        pt={0}
        overflowY="scroll"
        maxHeight={
          showInputField
            ? "calc(100vh - 156px - 118px)"
            : "calc(100vh - 70px - 118px)"
        }
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <Accordion allowMultiple>
          {allFunds
            .filter(
              (fundObj) =>
                (fundObj.text && fundObj.text.trim() !== "") ||
                (fundObj.fund_name && fundObj.fund_name.trim() !== "")
            )
            .map((fundObj) => (
              <AccordionItem
                key={
                  (fundObj._id ? "fetched-" : "created-") +
                  (fundObj._id || fundObj.id)
                }
              >
                <HStack spacing={4}>
                  <AccordionButton onClick={() => setSelectedFund(fundObj)}>
                    <Text
                      mb={0}
                      color={
                        (fundObj._id || fundObj.id) ===
                        (selectedFund
                          ? selectedFund._id || selectedFund.id
                          : null)
                          ? "blue.300"
                          : colorMode === "dark"
                          ? "gray.200"
                          : "#1A202C"
                      }
                    >
                      {fundObj.fund_name || fundObj.text}
                    </Text>
                  </AccordionButton>
                  <IconButton
                    variant="ghost"
                    aria-label={`Delete ${fundObj.fund_name || fundObj.text}`}
                    icon={<DeleteIcon />}
                    color={colorMode === "dark" ? "white" : "black"}
                    onClick={() => {
                      if (
                        window.confirm(
                          `Are you sure you want to delete the Fund: ${
                            fundObj.fund_name || fundObj.text
                          }?`
                        )
                      ) {
                        handleRemoveFund(fundObj);
                      }
                    }}
                    size="xs"
                  />
                </HStack>
              </AccordionItem>
            ))}
        </Accordion>
      </Box>
    </Box>
  );
};
export default Funddocs_fund;
