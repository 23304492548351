import React, { useState, useEffect } from "react";
import { useColorMode, useToast, Select } from "@chakra-ui/react";
import { fetchRolesForModule } from "../api/api";
import { logout } from "../../auth";

const RoleDropdown = ({
  selectedRole, // Expecting the whole role object to be passed here
  setSelectedRole, // Will set the whole role object
  selectedModule,
  size,
}) => {
  const { colorMode } = useColorMode();
  const [roles, setRoles] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rolesData = await fetchRolesForModule(selectedModule);
        const filteredRoles = rolesData.filter(
          (role) => selectedModule || !role.is_default
        );
        setRoles(filteredRoles);
      } catch (error) {
        handleError(error);
      }
    };

    fetchData();
  }, [selectedModule]);

  const handleError = (error) => {
    if (error.response && error.response.status === 401) {
      logout();
      toast({
        title: "Session expired",
        description: "Your session has expired. Please login again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      console.error("An error occurred:", error);
    }
  };

  const handleDropdownChange = (e) => {
    const roleId = e.target.value;

    // Find the selected role object by its _id
    const role = roles.find((r) => r._id === roleId);

    // Set the entire role object in the selectedRole state
    setSelectedRole(role);  // This will store the full role object
  };

  return (
    <Select
      flex="1"
      mr={4}
      borderRadius="md"
      size={size}
      placeholder="Select persona"
      value={selectedRole?._id || ""} // Ensure the correct role is selected using the _id
      onChange={handleDropdownChange}
      _focus={{
        borderColor: "blue.300",
        boxShadow: "0 0 0 1px blue.300",
      }}
      color={colorMode === "dark" ? "white" : "black"}
    >
      {roles.map((role) => (
        <option key={role._id} value={role._id}>
          {role.name}
        </option>
      ))}
    </Select>
  );
};

export default RoleDropdown;
