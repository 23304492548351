import React from 'react';
import { Flex, ListItem, ListIcon, Text } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { Link as ChakraLink } from "@chakra-ui/react";

function SidebarItem({ icon, to, label, index }) {
  const location = useLocation();
  const isActive = location.pathname === to;

  // Define Roman numerals for the first six items
  const romanNumerals = ["I", "II", "III", "IV", "V", "VI"];
  const numeral = romanNumerals[index] || "";

  return (
    <ListItem
      _hover={{
        bg: isActive ? "#E9F2FF" : "blackAlpha.50",
        borderRadius: "md"
      }}
      p={1}
      bg={isActive ? "#E9F2FF" : "transparent"}
      borderRadius={isActive ? "md" : "none"}
    >
      <Flex align="center">
        <ListIcon as={icon} color={isActive ? "white" : "gray.300"} mr={4} />
        <ChakraLink
          as={Link}
          to={to}
          style={{ textDecoration: "none" }}
        >
          <Flex align="center">
            <Text fontSize="md" fontWeight="bold" m={0} mr={1} w="25px" textAlign="left">
              {numeral}.
            </Text>
            <Text fontSize="md" fontWeight="semibold" m={0}>
              {label}
            </Text>
          </Flex>
        </ChakraLink>
      </Flex>
    </ListItem>
  );
}

export default SidebarItem;
