import React, { useEffect, useState, useRef } from "react";
import { logout } from "../../auth";
import {
  useColorMode,
  Box,
  List,
  Text,
  HStack,
  Flex,
  Heading,
  FormControl,
  FormLabel,
  IconButton,
  Divider,
  Input,
  ListItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  useBreakpointValue,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tooltip,
} from "@chakra-ui/react";
import {
  AddIcon,
  ArrowUpIcon,
  MinusIcon,
  InfoOutlineIcon,
  DeleteIcon,
  CopyIcon,
  DownloadIcon,
} from "@chakra-ui/icons";
import { AiOutlineFileZip } from "react-icons/ai";
import Tabs from "../Tabs";
import usePersistedState from "../usePersistedState";
import axiosInstance from "../../helpers/axiosInstance";
import FileInputArea from "./FileInputArea";
export const areas = [];

const Modules = () => {
  // //console.log("In Modules...")
  const { colorMode } = useColorMode();
  const [modules, setModules] = useState([]);
  const [newModule, setNewModule] = useState("");
  // const [selectedModule, setSelectedModule] = useState('');
  const [selectedModule, setSelectedModule] = usePersistedState(
    "selectedModule",
    ""
  );
  const [areas, setAreas] = useState([]);
  const [error, setError] = useState(null);
  const [isCreatingModule, setIsCreatingModule] = useState(false);
  // const [selectedArea, setSelectedArea] = useState(null);
  const [selectedArea, setSelectedArea] = usePersistedState(
    "selectedArea",
    null
  );
  const [isCreatingArea, setIsCreatingArea] = useState(false);
  const [newArea, setNewArea] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false); //Added this
  const [isUploading, setIsUploading] = useState(false);
  // const fileInputRef = useRef();
  const dateInputRef = useRef(null);
  const toast = useToast();

  const getModules = async () => {
    //console.log("In getModule Function");

    try {
      const response = await axiosInstance.get("/genie/modules");

      if (response.status !== 200) {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }
      // Handle the response data
      setModules(response.data);
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to fetch modules.");
    }
  };

  // this useEffect function executes when the component is mounted
  useEffect(() => {
    getModules();
  }, []);

  const createModule = async () => {
    //console.log("In Create Module function");
    try {
      const response = await axiosInstance.post("/genie/modules", {
        module_name: newModule,
      });

      //console.log(response);

      if (response.status === 201) {
        await getModules();
        setSelectedModule(newModule);
        setNewModule("");
      } else if (response.status === 401) {
        logout();
        toast({
          title: "Session expired",
          description: "Your session has expired. Please login again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setError(`Error: ${response.status}`);
      }
    } catch (error) {
      //console.log(error);
      if (error.response) {
        switch (error.response.status) {
          case 400:
            if (
              error.response.data &&
              error.response.data.message === "Module name already taken"
            ) {
              toast({
                title: "Agent Already Exists.",
                description:
                  "The agent you're trying to create already exists.",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            }
            break;
          case 403: // Handle 403 error
            toast({
              title: "Limit Reached!",
              description:
                "You have reached the maximum number of allowed modules. Please delete some to create more.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            break;
          default:
            setError("An error occurred while trying to create a new agent.");
            toast({
              title: "Error",
              description:
                "An error occurred while trying to create a new agent.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
        }
      } else {
        // Handle other non-axios related errors
        toast({
          title: "Error",
          description: "An unexpected error occurred.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const copyModules = async (selectedModuleName, duplicateModuleName) => {
    //console.log("In duplicate Modules Function");
    const payload = {
      module_name: selectedModuleName,
      duplicate_name: duplicateModuleName, // Add this line
    };
    //console.log("copyModules payload", payload);
    try {
      const response = await axiosInstance.post(
        "genie/create-duplicate-module",
        payload
      );

      //console.log("Copying module : ", response.data);

      if (response.status === 200) {
        getModules();
        //console.log(response.data);
      } else {
        setError(`Error: ${response.status}`);
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to duplicate module.");
    }
  };

  const deleteModule = async (moduleName) => {
    //console.log("In deleteModule function");
    try {
      const response = await axiosInstance.post(`/genie/delete_module`, {
        module_name: moduleName,
      });

      //console.log(response);

      if (response.status === 200) {
        // After successful module deletion, fetch the modules again to update the frontend
        await getModules();
        // Clear the selectedModule and selectedArea
        setSelectedModule(""); // Clear the selected module
        setSelectedArea(null); // Clear the selected area
        localStorage.removeItem("selectedModule");
        localStorage.removeItem("selectedArea");
        setAreas([]);
        // Show a success toast
        toast({
          title: "Agent Deleted",
          description: `The agent '${moduleName}' has been successfully deleted.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to delete the agent.");
      toast({
        title: "Error",
        description: "An error occurred while trying to delete the agent.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchAreas = async (moduleName) => {
    //console.log("In Feching Area Function");
    try {
      const response = await axiosInstance.post("genie/get_module_area", {
        module_name: moduleName,
      });

      //console.log("Feching folder : ", response.data);

      if (response.status !== 200) {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }

      if (Array.isArray(response.data.areas)) {
        setAreas(response.data.areas);
        //console.log("Respone.data.areas Data: ", response.data.areas);
      } else {
        console.error("Fetched folders data is not an array", response.data);
      }
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to fetch areas.");
    }
  };

  useEffect(() => {
    if (selectedModule) {
      fetchAreas(selectedModule);
    }
  }, [selectedModule]);

  const createArea = async () => {
    //console.log("Into Create an Area Funtion");
    try {
      const response = await axiosInstance.post("/genie/add_module_area", {
        module_name: selectedModule,
        area_name: newArea,
      });
      //console.log("createArea Response Data: ", response);

      if (response.status !== 200) {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }

      // Refresh areas of the current module after creating a new area
      await fetchAreas(selectedModule);
      // Clear the input field after successful module creation
      setNewArea("");

      //console.log("Area Response Data: ", response);
    } catch (error) {
      //console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Folder name already in use"
      ) {
        toast({
          title: "Training Folder.",
          description: "The folder you're trying to create already exsits.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setError("An error occurred while trying to create a new folder.");
        toast({
          title: "Error",
          description: "An error occurred while trying to create a new folder.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const deleteArea = async (areaName) => {
    //console.log("In Delete an Area Function");
    try {
      const response = await axiosInstance.post("/genie/delete_area", {
        module_name: selectedModule,
        area_name: areaName,
      });

      //console.log("deleteArea Response Data: ", response);

      if (response.status !== 200) {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }

      // Refresh areas of the current module after deleting an area
      await fetchAreas(selectedModule);

      // Show a success toast
      toast({
        title: "Folder Deleted",
        description: `The folder '${areaName}' has been successfully deleted.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to delete an area.");
    }
  };

  const uploadFile = (e, areaName) => {
    //console.log("Uploading File...");

    return new Promise((resolve, reject) => {
      const file = e.target.files[0];
      //console.log(file);
      if (!file) {
        resolve();
        return;
      }

      setUploadedFile(file); // setting the state here
      resolve();
    });
  };

  useEffect(() => {
    // this code will be executed every time `uploadedFile` changes
    //console.log("Uploaded File updated:", uploadedFile);
  }, [uploadedFile]);

  const handleFileUpload = async () => {
    setIsUploading(true);

    try {
      const files = Array.from(uploadedFile); // Use the files from the state
      const uploadDate = dateInputRef.current.value;

      if (!uploadDate) {
        alert("Please enter a date before uploading files.");
        setIsUploading(false);
        return;
      }

      const allowedExtensions = ["xlsx", "csv", "txt", "docx", "pdf"];

      // Filter out files with disallowed extensions
      const validFiles = files.filter((file) => {
        const parts = file.name.split(".");
        const extension = parts[parts.length - 1];
        if (!allowedExtensions.includes(extension)) {
          toast({
            title: "Wrong Extension.",
            description: `The extension .${extension} is not allowed for ${file.name}.`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return false;
        }
        return true;
      });

      if (validFiles.length === 0) {
        setIsUploading(false);
        return;
      }

      // Send the valid files to the backend
      await submitAreaData(validFiles, uploadDate);

      setIsOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  };

  const submitAreaData = async (validFiles, uploadDate) => {
    const formData = new FormData();

    // Add the module name, area name, and other metadata to the form data
    formData.append("module_name", selectedModule);
    formData.append("area_name", selectedArea);
    formData.append("data_validity_date", uploadDate);
    formData.append("source_type", "local"); // Example of additional data
    formData.append("upload_source_related_info", ""); // Example of additional data

    // Add each valid file to the form data
    validFiles.forEach((file) => {
      formData.append("file", file); // Append each file under the same key
    });

    // Log the form data with more details for the file
    for (let [key, value] of formData.entries()) {
      if (value instanceof File) {
        console.log(
          `${key}: ${value.name}, size: ${value.size} bytes, type: ${value.type}`
        );
      } else {
        console.log(`${key}: ${value}`);
      }
    }

    const toastId = toast({
      title: "Training",
      description: "Training In Progress...",
      status: "info",
      isClosable: false,
      duration: null,
    });

    try {
      const response = await axiosInstance.post(
        "/genie/upload_area_file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 200) {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }

      // Close the loading toast and show success toast
      toast.close(toastId);
      toast({
        title: "Training Completed Successfully",
        description: "NexusGenie has been successfully trained over the files",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      // Close the loading toast and show error toast
      toast.close(toastId);
      toast({
        title: "Training Error",
        description: "An error occurred while Training your files.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setError("An error occurred while trying to submit area data.");
    }
  };

  const deleteFile = async (fileName, areaName) => {
    //console.log("Deleting File...");
    try {
      const response = await axiosInstance.post("/genie/delete_file", {
        module_name: selectedModule,
        area_name: areaName,
        filename: fileName,
      });

      if (response.status !== 200) {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }

      // Refresh areas of the current module after deleting a file
      await fetchAreas(selectedModule);
      toast({
        title: "File Deletion",
        description: "The file has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to delete a file.");
    }
  };

  const handleDownloadFile = async (fileName, areaName, id) => {
    try {
      const response = await axiosInstance.post(
        "/genie/download_file",
        {
          // Pass the data as the request body in a POST request
          module_name: selectedModule,
          area_name: areaName,
          filename: fileName,
        },
        {
          responseType: "blob", // Ensuring the response is treated as a binary large object
        }
      );
  
      // Convert the blob to text to check if it's a JSON error message
      const responseText = await response.data.text();
      
      // Try to parse it as JSON to detect if it's an error message
      try {
        const jsonResponse = JSON.parse(responseText);
  
        // If the JSON has a 'message' and 'success' properties, assume it's an error
        if (jsonResponse.message && jsonResponse.success === false) {
          toast({
            title: "Error",
            description: jsonResponse.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return; // Exit the function if it's an error
        }
      } catch (e) {
        // If JSON.parse fails, then it is not an error message, and we proceed with downloading
      }
  
      // If no error, create a URL from the blob data for the file download
      const url = window.URL.createObjectURL(new Blob([response.data]));
  
      // Create an anchor element to trigger the download
      const link = document.createElement("a");
      link.href = url;
  
      // Set the filename for the downloaded file
      link.setAttribute(
        "download",
        `${fileName}` // You can directly use fileName here or modify as needed
      );
  
      // Append the link to the document body and click it to start the download
      document.body.appendChild(link);
      link.click();
  
      // Clean up by removing the link after the download starts
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
      toast({
        title: "Error",
        description: "An unexpected error occurred while downloading the file.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  // This function is called when a module is clicked. It sets the selectedModule state and fetches the relevant areas for that module.
  const handleModuleClick = (module) => {
    setSelectedModule(module);
    fetchAreas(module);
  };

  // This function is called when the upload button in an accordion is clicked.
  // It stops the click event from propagating to the AccordionButton to avoid unnecessary opening/closing.
  // Then it sets the selectedArea state and triggers the hidden file input click event.
  const handleUploadButtonClick = (e, area) => {
    e.stopPropagation();
    setSelectedArea(area);
    // fileInputRef.current.click();
    setIsOpen(true); //Added this
  };

  const handleClose = () => setIsOpen(false);

  // const handleFileUpload = async (e) => {
  //   setIsUploading(true);

  //   // Clear the file input field on any error
  //   try {
  //     const uploadedFile = e.target.files[0];
  //     const uploadDate = dateInputRef.current.value;

  //     if (!uploadDate) {
  //       alert("Please enter a date before uploading a file.");
  //       e.target.value = null;
  //       setIsUploading(false);
  //       return;
  //     }

  //     // Check file extension
  //     const parts = uploadedFile.name.split(".");
  //     const extension = parts[parts.length - 1];
  //     const allowedExtensions = ["xlsx", "csv", "txt", "docx", "pdf"];

  //     if (!allowedExtensions.includes(extension)) {
  //       //console.log(`The extension .${extension} is not allowed.`);
  //       toast({
  //         title: "Wrong Extension.",
  //         description: `The extension .${extension} is not allowed.`,
  //         status: "error",
  //         duration: 5000,
  //         isClosable: true,
  //       });
  //       return; // return here to stop the function execution
  //     }

  //     // Update state with new file
  //     const areaIndex = areas.findIndex(
  //       (area) => area.area_name === selectedArea
  //     );
  //     //console.log(areaIndex);
  //     if (areaIndex !== -1) {
  //       const updatedAreas = [...areas];
  //       //console.log(updatedAreas);
  //       updatedAreas[areaIndex] = {
  //         ...updatedAreas[areaIndex],
  //         files: [
  //           ...updatedAreas[areaIndex].files,
  //           { filename: uploadedFile.name },
  //         ],
  //       };
  //       //console.log("Setting Area Value");
  //       setAreas(updatedAreas);
  //     }

  //     const result = await submitAreaData(uploadedFile, uploadDate);
  //     if (result.success) {
  //       setIsOpen(false);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     e.target.value = null;
  //     setIsUploading(false);
  //   }
  // };

  // This function is called when the "add module" button is clicked.
  // It checks if a new module is being created, and if so, it calls the function to create a new module and toggles the module creation state off.

  const handleSaveModuleClick = () => {
    if (newModule) {
      createModule();
      //toggles the module creation state off.
      setIsCreatingModule(false);
      // getModules();
    }
  };

  // This function is called when the "save area" button is clicked.
  const handleSaveAreaClick = () => {
    if (!selectedModule) {
      toast({
        title: "No Module Selected",
        description: "Please select or create a module first.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    } else {
      // Add your code to save the area here
      if (newArea) {
        createArea();
        setIsCreatingArea(false);
      }
    }
  };

  const direction = useBreakpointValue({ base: "column", sm: "row" });
  const paddingX = useBreakpointValue({ base: 5, sm: 10 });

  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [moduleToDuplicate, setModuleToDuplicate] = useState("");
  const [duplicateModuleName, setDuplicateModuleName] = useState("");

  const handleDuplicateModule = async () => {
    // Here you can also check if duplicateModuleName is not empty before proceeding.
    await copyModules(moduleToDuplicate, duplicateModuleName);
    setDuplicateModuleName(""); // Reset the input field
  };

  return (
    <Box
      display={{ base: "block", md: "flex" }}
      width="100%"
      height="calc(100vh - 52px)"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"} // Dark mode background color
    >
      <Box
        w="20%"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        mr={0}
        mt={2}
        ml={2}
        mb={2}
        border="1px"
        borderTopRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
      >
        <Tabs />
      </Box>
      {/* Modules Container */}
      <Box
        width={{ base: "100%", md: "20%" }}
        bg={colorMode === "dark" ? "gray.700" : "white"}
        p={2}
        mr={0}
        m={2}
        border="1px"
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
        overflow="scroll"
        overflowX="hidden"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
        height="calc(100vh - 64px)" // Subtract NavBar height from viewport height
      >
        {/* <Heading fontSize="md" fontWeight="bold" color="blackAlpha.700" mb={1} p={2} >Modules</Heading> */}
        <Heading
          fontSize="md"
          fontWeight="semibold"
          color={colorMode === "dark" ? "white" : "#1A202C"} // Dark mode text color
          mb={1}
          p={2}
          pb={1}
        >
          Agents
        </Heading>
        <Heading
          fontSize="xs"
          color={colorMode === "dark" ? "gray.400" : "blackAlpha.700"}
          mb={0}
          pl={2}
          pb={2}
        >
          Choose an Agent.
        </Heading>
        <Divider mt={0} mb={0} borderColor="gray.200" />
        <Flex justify="space-between" align="center" mb={0} h="36px">
          <Heading
            fontSize="md"
            fontWeight="bold"
            color={colorMode === "dark" ? "white" : "blackAlpha.700"}
            mb={0}
            pl={2}
          >
            Create Agent
          </Heading>
          <IconButton
            aria-label="Add module"
            icon={isCreatingModule ? <MinusIcon /> : <AddIcon />}
            _hover={{}}
            bgColor={colorMode === "dark" ? "gray.100" : "gray.100"}
            color="black"
            onClick={() => setIsCreatingModule(!isCreatingModule)}
            size="xs"
            ml={4}
          />
        </Flex>
        {isCreatingModule && (
          <Box pl={2}>
            <Input
              placeholder="Agent name"
              size="sm"
              mb={2}
              mt={2}
              value={newModule}
              onChange={(e) => setNewModule(e.target.value)}
            />
            <Button
              size="sm"
              _hover={{}}
              bgColor="blue.500"
              textColor="white"
              onClick={handleSaveModuleClick}
            >
              Add Agent
            </Button>
          </Box>
        )}
        <Box pt={1} ml={0}>
          <Accordion allowMultiple>
            {modules.map((module, index) => (
              <AccordionItem key={index}>
                <HStack spacing={4}>
                  <AccordionButton onClick={() => handleModuleClick(module)}>
                    <Text
                      mb={0}
                      color={
                        module === selectedModule
                          ? "blue.300"
                          : colorMode === "dark"
                          ? "gray.200"
                          : "blackAlpha.700"
                      }
                    >
                      {module}
                    </Text>
                  </AccordionButton>
                  <Tooltip label="Coming Soon" fontSize="sm">
                    <IconButton
                      variant="ghost"
                      aria-label={`Duplicate ${module}`}
                      icon={<CopyIcon />} // Import this icon from Chakra icons or any other library you are using
                      color={colorMode === "dark" ? "white" : "black"}
                      // onClick={() => {
                      //   setModuleToDuplicate(module); // Set the module to be duplicated
                      //   setIsDuplicateModalOpen(true); // Open the modal
                      // }}
                      size="xs"
                    />
                  </Tooltip>
                  <Tooltip label="Delete" fontSize="sm">
                    <IconButton
                      variant="ghost"
                      aria-label={`Delete ${module}`}
                      icon={<DeleteIcon />}
                      color={colorMode === "dark" ? "white" : "black"}
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure you want to delete the agent: ${module}?`
                          )
                        ) {
                          deleteModule(module);
                        }
                      }}
                      size="xs"
                    />
                  </Tooltip>
                </HStack>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
        <Modal
          isOpen={isDuplicateModalOpen}
          onClose={() => setIsDuplicateModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader pb={1} mb={1}>
              Copy Agent
            </ModalHeader>
            <p style={{ marginLeft: "24px", marginBottom: "8px" }}>
              Make a copy of your agent with the same data
            </p>
            <ModalCloseButton />
            <ModalBody>
              <Input
                placeholder="Enter new agent name"
                value={duplicateModuleName}
                onChange={(e) => setDuplicateModuleName(e.target.value)}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                onClick={() => {
                  handleDuplicateModule(); // Assuming you've defined this function to handle duplication logic
                  setIsDuplicateModalOpen(false);
                }}
              >
                Make a copy
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>

      {/* Areas Container */}
      <Box
        width={{ base: "100%", md: "60%" }}
        bg={colorMode === "dark" ? "gray.700" : "white"}
        p={2}
        m={2}
        ml={0}
        border="1px"
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
        height="calc(100vh - 64px)" // Subtract NavBar height from viewport height
      >
        <Heading
          fontSize="md"
          fontWeight="semibold"
          color={colorMode === "dark" ? "white" : "#1A202C"} // Dark mode text color
          mb={1}
          p={2}
          pb={1}
        >
          Training
        </Heading>
        <Heading
          fontSize="xs"
          color={colorMode === "dark" ? "gray.400" : "blackAlpha.700"}
          mb={0}
          pl={2}
          pb={2}
        >
          Choose a Folder.
        </Heading>
        <Divider mt={0} mb={0} borderColor="gray.200" />
        <Flex justify="space-between" align="center" mb={0} h="36px">
          <Heading
            fontSize="md"
            fontWeight="bold"
            color={colorMode === "dark" ? "white" : "blackAlpha.700"}
            mb={0}
            pl={2}
          >
            Folders
          </Heading>
          <IconButton
            aria-label="Add Folder"
            icon={isCreatingArea ? <MinusIcon /> : <AddIcon />}
            _hover={{}}
            bgColor={colorMode === "dark" ? "gray.100" : "gray.100"}
            color="black"
            onClick={() => setIsCreatingArea(!isCreatingArea)}
            size="xs"
          />
        </Flex>
        {isCreatingArea && (
          <Box pl={2}>
            <Input
              placeholder="Folder name"
              size="sm"
              mb={2}
              mt={2}
              value={newArea}
              onChange={(e) => setNewArea(e.target.value)}
            />
            <Button
              size="sm"
              _hover={{}}
              bgColor="blue.500"
              textColor="white"
              onClick={handleSaveAreaClick}
              disabled={!selectedModule}
            >
              Add Folder
            </Button>
          </Box>
        )}
        <Box
          overflowY={isCreatingArea ? "auto" : "auto"}
          maxH={isCreatingArea ? "70%" : "75vh"}
          scrollBehavior="hidden"
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Accordion
            allowMultiple
            pt={1}
            color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
          >
            {areas.map((area, index) => (
              <AccordionItem key={index}>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {area.area_name}
                    </Box>
                    <IconButton
                      variant="ghost"
                      aria-label="Delete area"
                      icon={<DeleteIcon />}
                      color={colorMode === "dark" ? "white" : "black"}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          window.confirm(
                            `Are you sure you want to delete the folder: ${area.area_name}?`
                          )
                        ) {
                          deleteArea(area.area_name);
                        }
                      }}
                      size="sm"
                      mr={1}
                    />
                    <IconButton
                      variant="ghost"
                      aria-label="Upload to area"
                      icon={<ArrowUpIcon />}
                      colorScheme="gray"
                      mr={1}
                      size="sm"
                      onClick={(e) =>
                        handleUploadButtonClick(e, area.area_name)
                      }
                      isDisabled={isUploading} // disable the button when a file is being uploaded
                    />
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Heading size="xs" alignItems="left" mb={2}>
                    Files
                  </Heading>
                  <List spacing={2} styleType="none">
                    {area.files.map((file, fileIndex) => (
                      <ListItem key={fileIndex}>
                        <Flex align="center">
                          <AiOutlineFileZip mb={2} />
                          <Text mb={0} ml={2}>
                            {file.filename}
                          </Text>
                          <IconButton
                            variant="ghost"
                            aria-label="Delete file"
                            icon={<DeleteIcon />}
                            color={colorMode === "dark" ? "white" : "black"}
                            _hover="none"
                            onClick={() => {
                              if (
                                window.confirm(
                                  `Are you sure you want to delete the file: ${file.filename}?`
                                )
                              ) {
                                deleteFile(file.filename, area.area_name);
                              }
                            }}
                            size="sm"
                            ml={2}
                          />
                          <DownloadIcon
                            boxSize="4"
                            color="blue.500"
                            cursor="pointer"
                            onClick={() =>
                              handleDownloadFile(file.filename, area.area_name)
                            }
                            ml={1}
                          />
                        </Flex>
                      </ListItem>
                    ))}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            ))}
            {/* <Modal isOpen={isOpen} onClose={handleClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader pb={2}>{selectedArea}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <FormControl mt={0}>
                    <FormLabel>
                      Validity Date
                      <Box as="span" ml={2}>
                        <Tooltip
                          hasArrow
                          label="This data will be valid upto the selected date"
                        >
                          <InfoOutlineIcon w={4} h={4} />
                        </Tooltip>
                      </Box>
                    </FormLabel>
                    <Input
                      ref={dateInputRef}
                      type="date"
                      placeholder="Enter date"
                    />
                  </FormControl>
                  <Input
                    type="file"
                    onChange={(e) => handleFileUpload(e, selectedArea)}
                    p={1}
                    mt={2}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    _hover={{}}
                    bgColor="blue.500"
                    textColor="white"
                    mr={3}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal> */}

            <Modal isOpen={isOpen} onClose={handleClose}>
              <ModalOverlay />
              <ModalContent h="70vh">
                <ModalHeader pb={2}>{selectedArea}</ModalHeader>
                <ModalCloseButton />
                <ModalBody h="40vh" overflow="auto">
                  <FormControl mt={0} mb={4}>
                    <FormLabel>
                      Validity Date
                      <Box as="span" ml={2}>
                        <Tooltip
                          hasArrow
                          label="This data will be valid up to the selected date"
                        >
                          <InfoOutlineIcon w={4} h={4} />
                        </Tooltip>
                      </Box>
                    </FormLabel>
                    <Input
                      ref={dateInputRef}
                      type="date"
                      placeholder="Enter date"
                    />
                  </FormControl>
                  <FormLabel>Upload Files</FormLabel>
                  <FileInputArea onFilesSelected={setUploadedFile} />{" "}
                  {/* Use FileInputArea */}
                </ModalBody>
                <ModalFooter>
                  <Button
                    _hover={{}}
                    bgColor="blue.500"
                    textColor="white"
                    onClick={handleFileUpload} // Call handleFileUpload on click
                    disabled={!uploadedFile || uploadedFile.length === 0} // Disable button if no files are selected
                  >
                    Train
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};

export default Modules;
