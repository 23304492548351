import React, { useState, useEffect } from "react";
import { useColorMode, useToast, Select } from "@chakra-ui/react";
import { fetchRolesForModule } from "../api/api";
import { logout } from "../../auth";

const RoleDropdown = ({
  selectedRole,
  setSelectedRole,
  selectedModule,
  setSelectedModule,
  size,
}) => {
  const { colorMode } = useColorMode();
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(null);
  const toast = useToast();
  const [dropdownSelections, setDropdownSelections] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rolesData = await fetchRolesForModule(selectedModule);
        const filteredRoles = rolesData.filter(
          (role) => selectedModule || !role.is_default
        );
        setRoles(filteredRoles);
        // Use the roles data however you need in this component
        // setRoles(rolesData);
      } catch (error) {
        handleError(error);
      }
    };

    fetchData();
  }, [selectedModule]);

  const handleError = (error) => {
    // console.log(error);
    if (error.response && error.response.status === 401) {
      logout();
      toast({
        title: "Session expired",
        description: "Your session has expired. Please login again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setError("An error occurred.");
    }
  };

  const handleDropdownChange = (module, roleId) => {
    // Update the dropdown selection for the specific module
    setDropdownSelections((prev) => ({
      ...prev,
      [module]: roleId,
    }));

    // Update the active or "used" role
    const role = roles.find((r) => r._id === roleId);
    setSelectedRole(role);
  };
  return (
    <Select
      flex="1"
      mr={4}
      borderRadius="md"
      size={size}
      placeholder="Select persona"
      value={dropdownSelections[selectedModule] || ""}
      onChange={(e) => handleDropdownChange(selectedModule, e.target.value)}
      _focus={{
        borderColor: "blue.300",
        boxShadow: "0 0 0 1px blue.300",
      }}
      color={colorMode === "dark" ? "white" : "black"}
    >
      {roles
     //   .filter((role) => selectedModule || !role.is_default) // Filter out default roles when no module is selected
        .map((role, index) => (
          <option key={index} value={role._id}>
            {role.name}
          </option>
        ))
        }
    </Select>
  );
};

export default RoleDropdown;

// import React, { useState, useEffect } from "react";
// import { useToast } from "@chakra-ui/react";
// import { fetchRolesForModule } from "../api/api";
// import CustomSelect from "../common/CustomSelect";
// import { logout } from "../../auth";

// const RoleDropdown = ({ selectedRole, setSelectedRole, selectedModule }) => {
// const [roles, setRoles] = useState([]);
// const [error, setError] = useState(null);
// const toast = useToast();

// useEffect(() => {
//   const fetchData = async () => {
//     if (selectedModule) {
//       try {
//         const rolesData = await fetchRolesForModule(selectedModule);
//         setRoles(
//           rolesData.map((role) => ({ label: role.name, value: role._id }))
//         );
//       } catch (error) {
//         console.error("Error fetching roles:", error);
//         handleError(error);
//       }
//     }
//   };
//   fetchData();
// }, [selectedModule]);

// const handleError = (error) => {
//   console.log(error);
//   if (error.response && error.response.status === 401) {
//     logout();
//     toast({
//       title: "Session expired",
//       description: "Your session has expired. Please login again.",
//       status: "error",
//       duration: 5000,
//       isClosable: true,
//     });
//   } else {
//     setError("An error occurred.");
//   }
// };

// const handleRoleChange = (e) => {
//   const roleId = e.target.value;
//   const role = roles.find((r) => r.value === roleId);
//   setSelectedRole(role);
// };

//   return (
//     <CustomSelect
//       flex="1"
//       size="sm"
//       borderRadius="md"
//       options={roles}
//       value={selectedRole?.value}
//       onChange={handleRoleChange}
//       placeholder="Select persona"
//       mr={2}
//     />
//   );
// };

// export default RoleDropdown;