import React from "react";
import {
  Box,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  useColorMode,
} from "@chakra-ui/react";

const SequenceInsights = ({
  sequence,
  agreed_for_meeting_count,
  bounced_count,
  ooo_count,
  opened_count,
  replied_count,
  sent_count,
  // subscribed_count,
  total_active_email,
  sequenceInsight,
}) => {
  // console.log("sequenceInsight",sequenceInsight);
  const { colorMode } = useColorMode();
  return (
    <>
      {" "}
      <Table variant="unstyled" size="sm">
        <Tbody>
          <Tr>
            <Td>
              <Text
                // fontSize="12px"
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                fontWeight="bold"
                color={colorMode === "dark" ? "gray.50" : "blackAlpha.700"}
                mb={1}
                lineHeight="1.2"
                height={{ base: "22px", md: "22px", lg: "32px" }}
                textAlign="center"
              >
                Active
              </Text>
              <Text fontSize="12px" mb={0} textAlign="center">
                {total_active_email || 0}
              </Text>
            </Td>

            <Td>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                fontWeight="bold"
                color={colorMode === "dark" ? "gray.50" : "blackAlpha.700"}
                mb={1}
                lineHeight="1.2"
                height={{ base: "22px", md: "22px", lg: "32px" }}
                textAlign="center"
              >
                Sent
              </Text>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                mb={0}
                textAlign="center"
              >
                {sent_count || 0}
              </Text>
            </Td>

            <Td>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                fontWeight="bold"
                color={colorMode === "dark" ? "gray.50" : "blackAlpha.700"}
                mb={1}
                lineHeight="1.2"
                height={{ base: "22px", md: "22px", lg: "32px" }}
                textAlign="center"
              >
                Opened
              </Text>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                mb={0}
                textAlign="center"
              >
                {opened_count || 0}
              </Text>
            </Td>

            <Td>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                fontWeight="bold"
                color={colorMode === "dark" ? "gray.50" : "blackAlpha.700"}
                mb={1}
                lineHeight="1.2"
                height={{ base: "22px", md: "22px", lg: "32px" }}
                textAlign="center"
              >
                Replied
              </Text>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                mb={0}
                textAlign="center"
              >
                {replied_count || 0}
              </Text>
            </Td>

            {/* <Td>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                fontWeight="bold"
                color={colorMode === "dark" ? "gray.50" : "blackAlpha.700"}
                mb={1}
                lineHeight="1.2"
                height={{ base: "22px", md: "22px", lg: "32px" }}
                textAlign="center"
              >
                Subscribed
              </Text>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                mb={0}
                textAlign="center"
              >
                {subscribed_count || 0}
              </Text>
            </Td> */}

            <Td>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                fontWeight="bold"
                color={colorMode === "dark" ? "gray.50" : "blackAlpha.700"}
                mb={1}
                lineHeight="1.2"
                height={{ base: "22px", md: "22px", lg: "32px" }}
                textAlign="center"
              >
                Bounced
              </Text>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                mb={0}
                textAlign="center"
              >
                {bounced_count || 0}
              </Text>
            </Td>

            <Td>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                fontWeight="bold"
                noOfLines={2}
                color={colorMode === "dark" ? "gray.50" : "blackAlpha.700"}
                mb={1}
                lineHeight="1.2"
                height={{ base: "22px", md: "22px", lg: "32px" }}
                textAlign="center"
              >
                Booked Meeting
              </Text>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                mb={0}
                textAlign="center"
              >
                {agreed_for_meeting_count || 0}
              </Text>
            </Td>

            <Td>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                fontWeight="bold"
                color={colorMode === "dark" ? "gray.50" : "blackAlpha.700"}
                mb={1}
                lineHeight="1.2"
                height={{ base: "22px", md: "22px", lg: "32px" }}
                textAlign="center"
              >
                Delay Time
              </Text>
              <Text
                fontSize={{ base: "10px", md: "10px", lg: "12px" }}
                mb={0}
                textAlign="center"
              >
                {sequence.delay_time || 0}
              </Text>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </>
  );
};

export default SequenceInsights;
