import React, { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { fetchModules } from "../../api/fetchModules";
import CustomSelect from "../common/CustomSelect";
import { logout } from "../../auth";
import usePersistedState from "../../components/usePersistedState";

const ModulesDropdown = ({ selectedModule, setSelectedModule }) => {
  const [error, setError] = useState(null);
  const toast = useToast();
  const [modules, setModules] = usePersistedState("modulesDDQ_RFP", []);
  
  const handleSession = (response) => {
    if (response.status === 401) {
      logout();
      toast({
        title: "Session expired",
        description: "Your session has expired. Please login again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setError(`Error: ${response.status}`);
    }
  };

  useEffect(() => {
    const fetchModuleData = async () => {
      try {
        const modulesData = await fetchModules();
        // Use the roles data however you need in this component
        setModules( modulesData.map((module) => ({ label: module, value: module})));
      } catch (error) {
        handleSession(error);
      }
    };

    fetchModuleData();
  }, []);

  const handleModuleClick = (module) => {
    setSelectedModule(module);
  };

  return (
    <CustomSelect
      options={modules}
      value={selectedModule}
      onChange={(e) => handleModuleClick(e.target.value)}
      placeholder="Select agent"
      size="sm"
      borderRadius="md"
      flex="1"
      mr={2}
    />
  );
};

export default ModulesDropdown;
