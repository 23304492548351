import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import { useForm } from "react-hook-form";
import { login } from "../auth";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Logo } from "./Logo";
import "../styles/login.css";
import { useUser } from "./userRole/UserContext";

const LoginPage = () => {
  //console.log("In LoginPage function");
  const toast = useToast();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { user, setUser } = useUser();
  const { colorMode } = useColorMode();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");

  const loginUser = async (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    // Redirect immediately to the 2FA page before making the API call
    history.push("/two-factor-auth", { email: data.email });

    try {
      const response = await fetch("/auth/login", requestOptions);
      const result = await response.json();

      if (response.status !== 200) {
        // If login fails, bring the user back to the login page from the 2FA page
        history.push("/login");
        setErrorMessage(result.message || "Login failed");

        toast({
          title: "Login failed",
          description: "Your credentials are invalid. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        // If the call was successful, notify the user (already on the 2FA page)
        toast({
          title: "2FA code sent",
          description: "Check your email for the 2FA code.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      // In case of a server connection error, redirect back to the login page
      history.push("/login");
      setErrorMessage("Failed to connect to the server");
      toast({
        title: "Error",
        description: "Failed to connect to the server.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    reset();
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  //Password Field code
  const PasswordField = forwardRef((props, ref) => {
    const { isOpen, onToggle } = useDisclosure();
    const inputRef = useRef(null);
    const mergeRef = useMergeRefs(inputRef, ref);
    const onClickReveal = () => {
      onToggle();
      if (inputRef.current) {
        inputRef.current.focus({
          preventScroll: true,
        });
      }
    };
    return (
      <FormControl>
        <FormLabel htmlFor="password">Password</FormLabel>
        <InputGroup>
          <InputRightElement>
            <IconButton
              variant="text"
              aria-label={isOpen ? "Mask password" : "Reveal password"}
              icon={isOpen ? <HiEyeOff /> : <HiEye />}
              onClick={onClickReveal}
            />
          </InputRightElement>
          <Input
            id="password"
            ref={mergeRef}
            name="password"
            type={isOpen ? "text" : "password"}
            autoComplete="current-password"
            required
            {...props}
            placeholder="Your password"
            {...register("password", { required: true, minLength: 8 })}
          />
        </InputGroup>
        {errors.password && (
          <p style={{ color: "red" }}>
            <small>Password is required</small>
          </p>
        )}
        {errors.password?.type === "maxLength" && (
          <p style={{ color: "red" }}>
            <small>Password should be more than 8 characters</small>
          </p>
        )}
      </FormControl>
    );
  });
  PasswordField.displayName = "PasswordField";

  // Function to determine the boxShadow based on colorMode and screen size
  const boxShadow = () => ({
    base: "none",
    sm:
      colorMode === "light"
        ? "0px 4px 6px -1px rgba(0, 0, 0, 0.15)"
        : "0px 4px 6px -1px rgba(255, 255, 255, 0.2)",
  });

  const bgColor = useColorModeValue("white", "#171923");

  useEffect(() => {
    document.body.style.backgroundColor = bgColor;
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.backgroundColor = ""; // Reset to default
      document.body.style.overflow = "unset";
    };
  }, [bgColor]);
  return (
    <Container
      maxW="lg"
      py={{
        base: "10",
        md: "20",
      }}
      px={{
        base: "0",
        sm: "8",
      }}
      bg={colorMode === "light" ? "white" : "#171923"}
    >
      <Stack spacing="4">
        <Stack spacing="6">
          <Logo />
          <Stack
            spacing={{
              base: "2",
              md: "3",
            }}
            textAlign="center"
          >
            <Heading
              size={{
                base: "xs",
                md: "30px",
              }}
            >
              Log in to your account
            </Heading>
          </Stack>
        </Stack>
        <Box
          py={{
            base: "0",
            sm: "8",
          }}
          px={{
            base: "4",
            sm: "10",
          }}
          bg={{
            base: "transparent",
            sm: "bg.surface",
          }}
          boxShadow={boxShadow()}
          borderRadius={{
            base: "none",
            sm: "xl",
          }}
        >
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  type="email"
                  placeholder="Your email"
                  {...register("email", { required: true, maxLength: 30 })}
                />
                {errors.email && (
                  <p style={{ color: "red" }}>
                    <small>email is required</small>
                  </p>
                )}
                {errors.email?.type === "maxLength" && (
                  <p style={{ color: "red" }}>
                    <small>email should be 30 characters</small>
                  </p>
                )}
              </FormControl>
              <PasswordField />
            </Stack>
            <HStack justify="space-between">
              <Checkbox defaultChecked>Remember me</Checkbox>
              <Button variant="text" size="sm">
                <Link href="/forgot_password" color="#2B6CB0">
                  Forgot password?
                </Link>
              </Button>
            </HStack>
            <Stack spacing="6">
              <Button onClick={handleSubmit(loginUser)}>Sign in</Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};
export default LoginPage;
