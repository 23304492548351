import React, { useState, useEffect } from "react";
import axiosInstance from "../../helpers/axiosInstance";
import {
  Flex,
  Text,
  Box,
  Heading,
  Button,
  useColorMode,
  useDisclosure,
  Divider,
  Checkbox,
  Icon,
  Switch,
  VStack,
  useToast,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { AddIcon, DeleteIcon, FaDownload, ViewIcon } from "@chakra-ui/icons";
import usePersistedState from "./../usePersistedState";
import Sidebar from "./Sidebar";
import { fetchData } from "./Sequences/apiService";
import CampaignModal from "./CampaignModal";
import FileDownload from "./FileDownload";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";

function Campaign() {
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [campaigns, setCampaigns] = usePersistedState("campaigns", []);
  const [selectedModule, setSelectedModule] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedDataId, setSelectedDataId] = useState("");
  const [selectedRows, setSelectedRows] = useState(0);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [emailRate, setEmailRate] = useState("");
  const [inputError, setInputError] = useState("");
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [frequency, setFrequency] = useState("");
  // const [lastEmail, setLastEmail] = useState("");
  const [isAutomaticSending, setIsAutomaticSending] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [missionStatement, setMissionStatement] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [editing, setEditing] = useState(false);
  const [allowedDays, setAllowedDays] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [timeZone, setTimeZone] = useState("");

  const history = useHistory();
  const toast = useToast();
  const documentId = 1;

  const navigateToSequences = (campaign) => {
    // Ensure isFromBackend is defined
    const isFromBackend = campaign.isFromBackend
      ? campaign.isFromBackend
      : false;
    const campaignData = `${campaign.campaign_id}:${isFromBackend}`;

    history.push(`/sequences/${campaignData}`);
  };

  const fetchCampaigns = async () => {
    try {
      const response = await axiosInstance.get("campaign/get-all-campaign"); // Adjust the base URL as needed
      // console.log("response", response.data.data);
      return response.data.data; // Adjust according to the actual response structure
    } catch (error) {
      console.error("Failed to fetch campaigns:", error);
      return [];
    }
  };

  // Function to reset all form fields and related state
  const resetForm = () => {
    setSelectedModule("");
    setSelectedRole(null);
    setSelectedDataId("");
    setSelectedRows(0);
    setSelectedEmail([]);
    setEmailRate("");
    setFrequency("");
    setIsAutomaticSending(false);
    setCampaignName("");
    setMissionStatement("");
    setAllowedDays([]);
    setStartTime("");
    setEndTime("");
    setTimeZone("");
    setIsActive(false);
  };

  const handleAddCampaign = (event) => {
    event.preventDefault();
    const form = event.target;

    // Extract email rate and validate
    const emailRate = form.elements.emailsPerDay.value;
    if (!validateEmailRate(emailRate)) {
      return; // Stop the function if validation fails
    }

    if (allowedDays.length === 0) {
      alert("Please select at least one allowed day.");
      return;
    }

    // Validate that end time is after start time
    if (endTime <= startTime) {
      toast({
        title: "Error",
        description: "End time must be later than start time.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Stop the function if validation fails
    }

    const newCampaign = {
      campaign_id: uuidv4(), // Generates a universally unique identifier
      campaign_name: form.elements.campaignName.value,
      creation_time: new Date().toISOString(),
      module_name: selectedModule,
      persona: selectedRole,
      connected_email: selectedEmail,
      mission_statement: form.elements.missionStatement.value,
      email: localStorage.getItem("email"),
      allowed_days: allowedDays,
      start_time: startTime,
      end_time: endTime,
      time_zone: timeZone,
      emails_per_day: form.elements.emailsPerDay.value,
      data_id: selectedDataId,
      no_of_rows: selectedRows,
      ai_sent_allowed: form.elements.isActive.checked,
      is_active: false,
      ai_scheduling: {
        frequency,
        // lastEmail: parseInt(lastEmail, 10),
        module_name: selectedModule,
        persona: selectedRole,
        exclude: "joe@example.com",
      },
      sequences: [],
    };
    // console.log("newCampaign",newCampaign);
    setCampaigns([...campaigns, newCampaign]);
    resetForm(); // Call reset after adding campaign
    onClose();
  };

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await fetchData();
        if (response && Array.isArray(response.data)) {
          setDropdownOptions(response.data);
          // console.log('Dropdown', response.data);
        } else {
          console.error("Data is not an array:", response);
        }
      } catch (error) {
        console.error("Error fetching dropdown data:", error);
      }
    };

    const loadCampaigns = async () => {
      try {
        const backendCampaigns = await fetchCampaigns();
        if (Array.isArray(backendCampaigns)) {
          const markedNewCampaigns = backendCampaigns.map((campaign) => ({
            ...campaign,
            campaign_id: campaign.campaign_id,
            isFromBackend: true,
            sequences: [],
          }));
          setCampaigns((prevCampaigns) => {
            const existingCampaignIds = new Set(
              prevCampaigns.map((c) => c.campaign_id)
            );
            const newUniqueCampaigns = markedNewCampaigns.filter(
              (campaign) => !existingCampaignIds.has(campaign.campaign_id)
            );
            return [...prevCampaigns, ...newUniqueCampaigns];
          });
        } else {
          console.error(
            "Expected an array of campaigns but received:",
            backendCampaigns
          );
        }
      } catch (error) {
        console.error("Failed to load campaigns:", error);
      }
    };

    fetchDropdownData();
    loadCampaigns();
  }, []);

  const populateFormFields = (campaign) => {
    setCampaignName(campaign.campaign_name || "");
    setSelectedModule(campaign.module_name || "");
    setSelectedRole(campaign.persona || {});
    setSelectedEmail(campaign.connected_email || []);
    setMissionStatement(campaign.mission_statement || "");
    setAllowedDays(campaign.allowed_days || []);
    setStartTime(campaign.start_time || "");
    setEndTime(campaign.end_time || "");
    setTimeZone(campaign.time_zone || "");
    setEmailRate(campaign.emails_per_day || "");
    setSelectedDataId(campaign.data_id || "");
    setFrequency(campaign.ai_scheduling?.frequency || "");
    setIsActive(campaign.ai_sent_allowed || false);
  };

  const handleEditCampaign = (campaign) => {
    populateFormFields(campaign);
    setEditing(true);
    onOpen(); // Open the modal or form for editing
  };

  // Handle dropdown selection
  const handleDropdownChange = (e) => {
    const selectedOption = dropdownOptions.find(
      (option) => option._id === e.target.value
    );
    if (selectedOption) {
      setSelectedDataId(selectedOption._id);
      setSelectedRows(selectedOption.number_of_rows); // Set the rows associated with the selected ID
    }
  };

  // function handleFrequencyChange(e) {
  //   const value = parseInt(e.target.value, 10);
  //   // If the parsed value is not negative, set the value, otherwise reset to 0 or keep the positive value
  //   if (!isNaN(value) && value >= 0) {
  //     setLastEmail(value.toString());
  //   } else if (isNaN(value)) {
  //     // If the field is cleared, reset the state
  //     setLastEmail("");
  //   }
  // }

  const toggleIsActive = async (index) => {
    const campaign = campaigns[index];
    const newIsActiveState = !campaign.is_active; // Determine the new state based on the current state

    if (campaign.isFromBackend) {
      try {
        // Optimistically update the UI
        const updatedCampaigns = campaigns.map((c, idx) =>
          idx === index ? { ...c, is_active: newIsActiveState } : c
        );
        setCampaigns(updatedCampaigns);

        // console.log("campaign id :", campaign.campaign_id);
        // console.log("newIsActiveState:", newIsActiveState);
        // Send the toggle request to the backend with the new state
        const response = await axiosInstance.post(
          `/campaign/${campaign.campaign_id}/toggle`,
          {
            is_active: newIsActiveState, // Sending new active state as payload
          }
        );
        // console.log("Toggle response:", response.data);

        // Optionally handle response to update UI based on actual backend confirmation
        // This is where you might adjust the state again if the backend response differs from the expected result
      } catch (error) {
        console.error("Failed to toggle campaign:", error);
        // Rollback on error to previous state
        setCampaigns(campaigns);
      }
    } else {
      // For locally created campaigns, just toggle the state without API call
      const updatedCampaigns = campaigns.map((c, idx) =>
        idx === index ? { ...c, is_active: newIsActiveState } : c
      );
      setCampaigns(updatedCampaigns);
    }
  };

  const handleDeleteSelected = async () => {
    const updatedCampaigns = campaigns.filter(
      (campaign) => !selectedCampaigns.includes(campaign.campaign_id)
    );
    setCampaigns(updatedCampaigns);
    localStorage.setItem("campaigns", JSON.stringify(updatedCampaigns));

    for (let campaignId of selectedCampaigns) {
      const campaign = campaigns.find((c) => c.campaign_id === campaignId);
      if (campaign.isFromBackend) {
        try {
          await axiosInstance.delete(`/campaign/delete-campaign/${campaignId}`);
        } catch (error) {
          console.error("Failed to delete campaign from backend:", error);
          toast({
            title: "Error",
            description: `Failed to delete campaign: ${campaign.campaign_name}`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    }
    setSelectedCampaigns([]);
    toast({
      title: "Success",
      description: "Selected campaigns deleted successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const handleCheckboxChange = (campaignId) => {
    setSelectedCampaigns((prevSelected) =>
      prevSelected.includes(campaignId)
        ? prevSelected.filter((id) => id !== campaignId)
        : [...prevSelected, campaignId]
    );
  };

  const validateEmailRate = (value) => {
    const num = Number(value);
    if (num < 1 || num > 1000) {
      setInputError("Please enter a value between 1 and 1000.");
      return false; // Return false if validation fails
    } else {
      setInputError("");
      return true; // Return true if validation passes
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEmailRate(value);
    validateEmailRate(value);
  };

  const handleOpen = () => {
    resetForm(); // Call resetForm to clear all fields
    onOpen(); // Call onClose to close the modal
    setEditing(false); // Call
  };

  return (
    <Flex>
      <Sidebar />
      <Box flex="1" bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}>
        <Box
          p={5}
          pl={6}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          h="10.7vh"
          borderBottom="1px"
          borderColor="blackAlpha.200"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="lg" fontWeight="semibold" mt={4} m={0}>
            Campaign
          </Heading>
        </Box>
        <Box
          as="section"
          m="2"
          h="86.5vh"
          bg={colorMode === "dark" ? "gray.700" : "white"}
          borderRadius="lg"
          border="1px"
          borderColor="blackAlpha.200"
        >
          <Flex direction="column" h="100%">
            <Box
              flex="1"
              borderBottom="1px"
              borderColor="blackAlpha.200"
              // p="1"
              pr="4"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <IconButton
                onClick={handleDeleteSelected}
                size="sm"
                leftIcon={<Icon as={DeleteIcon} marginLeft={2} />}
                colorScheme="red"
                isDisabled={selectedCampaigns.length === 0}
                ml={4}
              />
              <Heading></Heading>

              <Button
                onClick={handleOpen}
                size="sm"
                leftIcon={<Icon as={AddIcon} />}
                colorScheme="blue"
              >
                New Campaign
              </Button>
            </Box>
            <Box
              flex="9"
              m={4}
              borderRadius="md"
              border="1px"
              borderColor="blackAlpha.400"
              overflow="auto"
            >
              {campaigns.map((campaign, index) => (
                <Flex
                  key={index}
                  h="20%"
                  // p={4}
                  bg={colorMode === "dark" ? "gray.700" : "white"}
                  borderTopRadius="md"
                  borderBottom="1px"
                  borderColor="blackAlpha.400"
                  alignItems="center"
                  justifyContent="space-between"
                  overflowY="scroll"
                  scrollBehavior="hidden"
                  sx={{
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                  _hover={{
                    bg: colorMode === "dark" ? "gray.600" : "blackAlpha.50",
                  }} // Hover effect
                >
                  <Checkbox
                    isChecked={selectedCampaigns.includes(campaign.campaign_id)}
                    onChange={() => handleCheckboxChange(campaign.campaign_id)}
                    ml={4}
                  />
                  <Flex
                    flex="9"
                    h="100%"
                    p={4}
                    onClick={() => navigateToSequences(campaign)}
                    cursor="pointer"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <VStack
                      alignItems="left"
                      justifyContent="left"
                      spacing={1}
                      w="50%"
                      mb={1}
                      mt={1}
                      // minWidth="20%"
                    >
                      <Text fontSize="16px" fontWeight="semibold" mt={2} mb={0}>
                        {campaign.campaign_name}
                      </Text>
                      <Text fontSize="12px" mb={0}>
                        {campaign.email}
                      </Text>
                      {campaign.isFromBackend && (
                      <HStack
                        spacing={1}
                        alignItems="center"
                        justifyContent="left"
                        mr={4}
                        //  w="30%"
                      >
                        <Text fontSize="xs" color="gray.500" mb={0} textAlign="center">
                          {DateTime.fromISO(
                            campaign.campaign_start_time
                          ).toFormat("MMM d, yyyy, h:mm a ZZZZ")}
                        </Text>

                        <Text
                          fontSize="xs"
                          color="gray.500"
                          mb={0}
                          textAlign="center"
                        >
                          -
                        </Text>
                        <Text
                          fontSize="xs"
                          color="gray.500"
                          mb={0}
                          textAlign="center"
                       
                        >
                          {DateTime.fromISO(
                            campaign.campaign_end_time
                          ).toFormat("MMM d, yyyy, h:mm a ZZZZ")}
                        </Text>
                      </HStack>
                    )}
                    </VStack>
                 
                    <Divider
                      orientation="vertical"
                      height="7vh"
                      borderColor="gray.400"
                      sx={{ borderStyle: "dashed" }}
                    />
                    <VStack
                      alignItems="left"
                      justifyContent="left"
                      spacing={1}
                      maxWidth="15%"
                      minWidth="15%"
                    >
                      <Text
                        fontSize="12px"
                        color={
                          colorMode === "dark" ? "gray.50" : "blackAlpha.700"
                        }
                        mb={1}
                      >
                        Creation Time
                      </Text>
                      <Text fontSize="12px" mb={0}>
                        {new Date(campaign.creation_time).toLocaleString()}
                      </Text>
                    </VStack>

                    <VStack
                      alignItems="left"
                      justifyContent="left"
                      spacing={1}
                      maxWidth="15%"
                      minWidth="15%"
                    >
                      <Text
                        fontSize="12px"
                        color={
                          colorMode === "dark" ? "gray.50" : "blackAlpha.700"
                        }
                        mb={1}
                      >
                        Module
                      </Text>
                      <Text fontSize="12px" mb={0}>
                        {campaign.module_name}
                      </Text>
                    </VStack>
                  </Flex>
                  {campaign.isFromBackend ? (
                    <Flex flex="1" h="100%" p={4} cursor="pointer">
                      <VStack
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        w="100%"
                      >
                        <Text
                          fontSize="12px"
                          color={
                            colorMode === "dark" ? "gray.50" : "blackAlpha.700"
                          }
                          mb={1}
                        >
                          Details
                        </Text>
                        <IconButton
                          icon={<ViewIcon />}
                          size="sm"
                          bg="none"
                          onClick={() => handleEditCampaign(campaign)}
                        />
                      </VStack>
                    </Flex>
                  ) : (
                    <Flex flex="1" h="100%" p={4}>
                      <VStack
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        w="100%"
                      >
                        <Text
                          fontSize="12px"
                          color="transparent" // Invisible text to keep space
                          mb={1}
                        ></Text>
                      </VStack>
                    </Flex>
                  )}

                  {campaign.isFromBackend ? (
                    <Flex
                      flex="1"
                      h="100%"
                      p={4}
                      cursor="pointer"
                      // bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
                    >
                      <VStack
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        w="100%"
                      >
                        <Text
                          fontSize="12px"
                          color={
                            colorMode === "dark" ? "gray.50" : "blackAlpha.700"
                          }
                          mb={1}
                        >
                          Download
                        </Text>
                        <FileDownload
                          documentId={documentId}
                          campaign_id={campaign.campaign_id}
                        />
                      </VStack>
                    </Flex>
                  ) : (
                    <Flex
                      flex="1"
                      h="100%"
                      p={4}
                      // bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
                    >
                      <VStack
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                        w="100%"
                      >
                        <Text
                          fontSize="12px"
                          color="transparent" // Invisible text to keep space
                          mb={1}
                        ></Text>
                      </VStack>
                    </Flex>
                  )}

                  <Flex
                    flex="1"
                    h="100%"
                    p={4}
                    cursor="pointer"
                    bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
                  >
                    <VStack
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                      w="100%"
                    >
                      <Text
                        fontSize="12px"
                        color={
                          colorMode === "dark" ? "gray.50" : "blackAlpha.700"
                        }
                        mb={1}
                      >
                        Status
                      </Text>
                      <Switch
                        isChecked={campaign.is_active}
                        onChange={(e) => {
                          e.stopPropagation();
                          toggleIsActive(index);
                        }}
                        onClick={(e) => e.stopPropagation()}
                        mb={0}
                      />
                    </VStack>
                  </Flex>
                </Flex>
              ))}
            </Box>
          </Flex>
        </Box>
      </Box>
      <CampaignModal
        isOpen={isOpen}
        onClose={onClose}
        handleAddCampaign={handleAddCampaign}
        selectedModule={selectedModule}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        setSelectedModule={setSelectedModule}
        dropdownOptions={dropdownOptions}
        handleDropdownChange={handleDropdownChange}
        emailRate={emailRate}
        setEmailRate={setEmailRate}
        inputError={inputError}
        setInputError={setInputError}
        handleChange={handleChange}
        selectedEmail={selectedEmail}
        setSelectedEmail={setSelectedEmail}
        frequency={frequency}
        setFrequency={setFrequency}
        // lastEmail={lastEmail}
        // setLastEmail={setLastEmail}
        // handleFrequencyChange={handleFrequencyChange}
        campaignName={campaignName} // Pass down new states
        setCampaignName={setCampaignName}
        missionStatement={missionStatement}
        setMissionStatement={setMissionStatement}
        selectedDataId={selectedDataId}
        setSelectedDataId={setSelectedDataId}
        isAutomaticSending={isAutomaticSending}
        setIsAutomaticSending={setIsAutomaticSending}
        isActive={isActive} // Pass isActive state
        setIsActive={setIsActive} // Pass setIsActive setter function
        editing={editing}
        allowedDays={allowedDays}
        setAllowedDays={setAllowedDays}
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
        timeZone={timeZone}
        setTimeZone={setTimeZone}
      />
    </Flex>
  );
}

export default Campaign;
