import usePersistedState from "./../usePersistedState";
import { useEffect, useState, useRef } from "react";
import { logout } from "../../auth";
import {
  Box,
  HStack,
  Heading,
  Text,
  Button,
  Flex,
  useToast,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon, DeleteIcon } from "@chakra-ui/icons";
import "../../styles/test.css";
import { useColorMode } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid"; // Import uuid
import { fetchDDQRFP } from "../../api/DDQ_RFP/fileApi";
import axiosInstance from "../../helpers/axiosInstance";

const DDQ_RFP_minute = ({
  selectedMinute,
  setSelectedMinute,
  fetchedMinutes,
  setFetchedMinutes,
  minutesList,
  setMinutesList,
  agendaPoints,
  setAgendaPoints,
}) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [currentMinute, setCurrentMinute] = usePersistedState(
    "currentMinuteUploadDDQ_RFP",
    ""
  );
  // const [minutesList, setMinutesList] = usePersistedState(
  //   "minutesListUploadDDQ_RFP",
  //   []
  // );
  const [showInputField, setShowInputField] = usePersistedState(
    "showInputFieldUploadDDQ_RFP",
    false
  );

  const handleRemoveDDQRFP = async (minuteToRemove) => {
    if (minuteToRemove._id) {
      // console.log("Removing", minuteToRemove._id);
      try {
        // Assuming you have an API endpoint to delete a minute by its _id
        const response = await axiosInstance.post("/ddq-rfp/delete-ddq", {
          str_id: minuteToRemove._id,
        });
        if (response.status === 200) {
          // Update the state after successful deletion
          setFetchedMinutes((prevState) =>
            prevState.filter((minute) => minute._id !== minuteToRemove._id)
          );
          setSelectedMinute(null);
          //console.log("setSelectedMinute to null")
          setAgendaPoints([]);
          toast({
            title: "Success",
            description: `DDQ/RFP ${minuteToRemove.ddq_name} has been removed`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-center",
          });
        }
      } catch (error) {
        console.error(error);
        toast({
          title: "Error",
          description: `Error deleting DDQ/RFP: ${minuteToRemove.ddq_name}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-center",
        });
      }
    } else {
      // If the minute doesn't have an _id, it's a newly created minute and not committed yet
      setMinutesList((prevState) =>
        prevState.filter((minute) => minute.id !== minuteToRemove.id)
      );
    }
  };

  const handleAddMinute = () => {
    if (currentMinute) {
      // Generate a UUID for the new minute
      const minuteId = uuidv4();

      // Use the generated UUID as a key and currentMinute as value
      setMinutesList((prevState) => [
        ...prevState,
        {
          id: minuteId,
          text: currentMinute,
        },
      ]);

      // Set the selectedMinute with the new minute object
      setSelectedMinute({ id: minuteId, text: currentMinute });
      setCurrentMinute("");
    }
  };

  useEffect(() => {
    fetchDDQRFP(setFetchedMinutes, toast);
  }, []);

  const allMinutes = [...fetchedMinutes, ...minutesList];

  return (
    <Box
      // bg={colorMode === "dark" ? "gray.700" : "white"}
      pt={6}
      p={4}
      pb={2}
      borderRadius="lg"
    >
      <Text
        fontSize="md"
        fontWeight="semibold"
        ml={2}
        mb="4px"
        color={colorMode === "dark" ? "white" : "#1A202C"}
        // color="#1A202C"
      >
        DDQ/RFP
      </Text>
      <Heading
        fontSize="xs"
        color={colorMode === "dark" ? "white" : "blackAlpha.700"}
        mb={0}
        pl={2}
        pb={2}
      >
        Add the DDQ/RFP.
      </Heading>
      <Divider mt={0} mb={0} borderColor="#E2E8F0" />
      <Flex justify="space-between" align="center" mb={0}>
        <Heading
          fontSize="md"
          fontWeight="bold"
          color={colorMode === "dark" ? "white" : "blackAlpha.700"}
          mb={0}
          pl={2}
          p={2}
          pr={0}
        >
          Create DDQ/RFP
        </Heading>
        <IconButton
          variant="ghost"
          aria-label="Add DDQ/RFP"
          icon={showInputField ? <MinusIcon /> : <AddIcon />}
          colorScheme="gray"
          onClick={() => setShowInputField(!showInputField)}
          size="xs"
          ml={4}
        />
      </Flex>
      {showInputField && (
        <Box pl={2} pb={1}>
          <Input
            placeholder="Enter DDQ/RFP"
            size="sm"
            mb={2}
            mt={2}
            value={currentMinute}
            onChange={(e) => setCurrentMinute(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter") handleAddMinute();
            }}
          />
          <Button
            size="sm"
            color={colorMode === "dark" ? "white" : "blackAlpha.700"}
            onClick={handleAddMinute}
          >
            Add DDQ/RFP
          </Button>
        </Box>
      )}
      <Box
        mt={0}
        mb={2}
        pt={0}
        overflowY="scroll"
        maxHeight={
          showInputField
            ? "calc(100vh - 156px - 118px)"
            : "calc(100vh - 70px - 118px)"
        }
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        <Accordion allowMultiple>
          {allMinutes
            .filter(
              (minuteObj) =>
                (minuteObj.text && minuteObj.text.trim() !== "") ||
                (minuteObj.ddq_name && minuteObj.ddq_name.trim() !== "")
            )
            .map((minuteObj) => (
              <AccordionItem
                key={
                  (minuteObj._id ? "fetched-" : "created-") +
                  (minuteObj._id || minuteObj.id)
                }
              >
                <HStack spacing={4}>
                  <AccordionButton onClick={() => setSelectedMinute(minuteObj)}>
                    <Text
                      mb={0}
                      color={
                        (minuteObj._id || minuteObj.id) ===
                        (selectedMinute
                          ? selectedMinute._id || selectedMinute.id
                          : null)
                          ? "blue.300"
                          : colorMode === "dark"
                          ? "gray.200"
                          : "#1A202C"
                      }
                    >
                      {minuteObj.ddq_name || minuteObj.text}
                    </Text>
                  </AccordionButton>
                  <IconButton
                    variant="ghost"
                    aria-label={`Delete ${
                      minuteObj.ddq_name || minuteObj.text
                    }`}
                    icon={<DeleteIcon />}
                    color={colorMode === "dark" ? "white" : "black"}
                    onClick={() => {
                      if (
                        window.confirm(
                          `Are you sure you want to delete the DDQ/RFP: ${
                            minuteObj.ddq_name || minuteObj.text
                          }?`
                        )
                      ) {
                        handleRemoveDDQRFP(minuteObj);
                      }
                    }}
                    size="xs"
                  />
                </HStack>
              </AccordionItem>
            ))}
        </Accordion>
      </Box>
    </Box>
  );
};
export default DDQ_RFP_minute;
