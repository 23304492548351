export const convertHtmlToPlainText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  
  export const decodeCampaignData = (campaignData) => {
    if (!campaignData) return { campaignId: null, isFromBackend: false };
    const [id, flag] = campaignData.split(":");
    return { campaignId: id, isFromBackend: flag === "true" };
  };
  
  export const modifySequenceTemplate = (template) => {
    return template.replace(/<p>/g, '<p style="margin: 0;">');
  };
  