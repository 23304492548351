import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../helpers/axiosInstance";
import Sidebar from "./Sidebar";
import { fetchData } from "./Sequences/apiService";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorMode,
  Heading,
  Icon,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FaFileAlt } from "react-icons/fa";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const Upload = () => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileNames, setFileNames] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 20,
  });
  const [currentFileId, setCurrentFileId] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchFileNames = async () => {
      try {
        const data = await fetchData();
        setFileNames(data.data);
      } catch (error) {
        console.error("Error fetching file names:", error);
      }
    };
    fetchFileNames();
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axiosInstance.post(
        "/campaign_file_upload/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        const updatedData = await fetchData();
        setFileNames(updatedData.data);
        setSelectedFile(null);
        fileInputRef.current.value = null;
      } else {
        throw new Error(response.data.message || "Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleDeleteFile = async (id) => {
    try {
      const response = await axiosInstance.delete(
        `/campaign_file_upload/delete/${id}`
      );
      if (response.data.success) {
        toast({
          title: "Success",
          description: "File deleted successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        const updatedData = await fetchData();
        setFileNames(updatedData.data);
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      toast({
        title: "Error",
        description: "An error occurred while deleting the file.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDownloadFile = async (id) => {
    try {
      const response = await axiosInstance.get(
        `/campaign_file_upload/download/${id}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${fileNames.find((file) => file._id === id).filename}`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
      toast({
        title: "Error",
        description: "An error occurred while downloading the file.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleViewFile = async (id, page = 1) => {
    try {
      setCurrentFileId(id); // Save the file ID for pagination purposes
      const response = await axiosInstance.get(
        `/campaign_file_upload/fetch_paginated/${id}`,
        {
          params: {
            page: page,
            per_page: pagination.per_page,
          },
        }
      );

      if (response.data.success) {
        setFileData(response.data.data);
        onOpen();
      } else {
        toast({
          title: "Error",
          description: response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
      toast({
        title: "Error",
        description: "An error occurred while fetching the file data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleNextPage = () => {
    const newPage = pagination.page + 1;
    setPagination((prev) => ({
      ...prev,
      page: newPage,
    }));
    handleViewFile(currentFileId, newPage); // Fetch data for the new page
  };

  const handlePreviousPage = () => {
    const newPage = pagination.page > 1 ? pagination.page - 1 : 1;
    setPagination((prev) => ({
      ...prev,
      page: newPage,
    }));
    handleViewFile(currentFileId, newPage); // Fetch data for the new page
  };

  return (
    <Flex>
      <Sidebar />
      <Box flex="1" bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}>
        <Box
          p={5}
          pl={6}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          h="10.7vh"
          borderBottom="1px"
          borderColor="blackAlpha.200"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading fontSize="lg" fontWeight="semibold" mt={4} m={0}>
            Upload
          </Heading>
        </Box>
        <Box
          as="section"
          m="2"
          h="20vh"
          p={4}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          borderRadius="lg"
          border="1px"
          borderColor="blackAlpha.200"
        >
          <FormControl>
            <FormLabel>Upload Excel or CSV File</FormLabel>
            <Input
              pt={1}
              pl={1}
              ref={fileInputRef}
              type="file"
              accept=".xlsx,.csv"
              onChange={handleFileChange}
              w="50%"
            />
            <Button onClick={handleUpload} colorScheme="blue" ml={2} mb={1}>
              Upload
            </Button>
          </FormControl>
        </Box>
        <Box
          as="section"
          m="2"
          h="65.2vh"
          p={4}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          borderRadius="lg"
          border="1px"
          borderColor="blackAlpha.200"
          overflowY="scroll"
          scrollBehavior="hidden"
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Flex
            pl={4}
            bg={colorMode === "dark" ? "gray.700" : "white"}
            display="flex"
            alignItems="center"
            h="4vh"
            w="100%"
          >
            <Text fontSize="sm" fontWeight="bold" w="33%" align="left">
              Filename
            </Text>
            <Spacer />
            <Text fontSize="sm" fontWeight="bold" align="center" mr={12}>
              Rows
            </Text>
            <Text fontSize="sm" fontWeight="bold" mr={4} align="right"></Text>
          </Flex>
          <Box mt={0}>
            {fileNames.map((file, index) => (
              <Flex
                key={index}
                pl={4}
                borderWidth="1px"
                borderRadius="md"
                overflow="hidden"
                bg={colorMode === "dark" ? "gray.700" : "white"}
                display="flex"
                alignItems="center"
                h="8vh"
                w="100%"
              >
                <Icon as={FaFileAlt} color="green.500" mr={2} mt={0} />
                <Text fontSize="sm" fontWeight="semibold" mt={4}>
                  {file.filename}
                </Text>
                <Divider
                  ml={4}
                  orientation="vertical"
                  height="7vh"
                  borderColor="gray.400"
                  sx={{ borderStyle: "dashed" }}
                />
                <Spacer />
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  mt={3}
                  align="center"
                  mr={4}
                >
                  {file.number_of_rows}
                </Text>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<MoreHorizIcon />}
                    variant="outline"
                    size="sm"
                    align="right"
                    mr={4}
                  />
                  <MenuList>
                    <MenuItem
                      color="red"
                      onClick={() => handleDeleteFile(file._id)}
                    >
                      Delete
                    </MenuItem>
                    <MenuItem onClick={() => handleDownloadFile(file._id)}>
                      Download
                    </MenuItem>
                    <MenuItem onClick={() => handleViewFile(file._id)}>
                      View
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            ))}
          </Box>
        </Box>
      </Box>

      {/* Modal for Viewing File Data */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent h="78vh" maxW="80%">
          <ModalCloseButton />
          <ModalBody overflow="auto">
            {fileData && (
              <Box>
                <ModalHeader>{fileData.filename}</ModalHeader>

                <Table variant="simple">
                  <Thead>
                    <Tr>
                      {Object.keys(fileData.data[0]).map((key) => (
                        <Th key={key}>{key}</Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {fileData.data.map((row, index) => (
                      <Tr
                        key={index}
                        sx={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {Object.values(row).map((value, idx) => (
                          <Td key={idx}>{value}</Td>
                        ))}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                <Flex mt={4} justifyContent="space-between">
                  <Button
                    onClick={handlePreviousPage}
                    isDisabled={pagination.page === 1}
                    colorScheme="blue"
                    borderRadius="full"
                    size="sm"
                    fontSize="sm"
                  >
                    Previous
                  </Button>
                  <Text fontSize="sm" mb={0} color="black">
                    Page {pagination.page} of {fileData.pagination.total_pages}
                  </Text>

                  <Button
                    onClick={handleNextPage}
                    isDisabled={
                      pagination.page === fileData.pagination.total_pages
                    }
                    colorScheme="blue"
                    borderRadius="full"
                    size="sm"
                    fontSize="sm"
                  >
                    Next
                  </Button>
                </Flex>
              </Box>
            )}
          </ModalBody>
          {/* <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Upload;
