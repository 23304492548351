import React from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Checkbox,
  Flex,
  Button,
  Select,
  Text,
  Tooltip,
  Textarea,
} from "@chakra-ui/react";
import { InfoOutlineIcon, InfoIcon } from "@chakra-ui/icons";
import ModulesDropdown from "../common/ModulesDropdown";
import RoleDropdown from "./RoleDropdown";
import EmailDropdown from "../common/EmailDropdown";
import TimezoneSelect from "react-timezone-select";

const CampaignModal = ({
  isOpen,
  onClose,
  handleAddCampaign,
  selectedModule,
  setSelectedModule,
  selectedRole,
  setSelectedRole,
  dropdownOptions,
  handleDropdownChange,
  emailRate,
  setEmailRate,
  inputError,
  setInputError,
  handleChange,
  selectedEmail,
  setSelectedEmail,
  frequency,
  setFrequency,
  // lastEmail,
  // setLastEmail,
  // handleFrequencyChange,
  campaignName,
  setCampaignName,
  missionStatement,
  setMissionStatement,
  selectedDataId,
  setSelectedDataId,
  isActive,
  setIsActive,
  resetForm,
  editing,
  allowedDays,
  setAllowedDays,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  timeZone,
  setTimeZone,
}) => {
  const daysOptions = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleAllowedDaysChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setAllowedDays((prev) => [...prev, value]);
    } else {
      setAllowedDays((prev) => prev.filter((day) => day !== value));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a New Campaign</ModalHeader>
        <ModalCloseButton />
        <Box
          h="68vh"
          overflowY="scroll"
          scrollBehavior="hidden"
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <form onSubmit={handleAddCampaign}>
            <ModalBody pb={6}>
              <FormControl isRequired>
                <FormLabel fontSize="sm" mb={0}>
                  Campaign
                </FormLabel>
                <Text fontSize="xs" color="gray.500" mb={2}>
                  Give your campaign an appropriate name.
                </Text>
                <Input
                  id="campaignName"
                  placeholder="Enter campaign name"
                  name="campaignName"
                  size="sm"
                  borderRadius="md"
                  value={campaignName} // Use state value here
                  onChange={(e) => setCampaignName(e.target.value)} // Update state on change
                  isReadOnly={editing}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel fontSize="sm" mb={0}>
                  Agent
                </FormLabel>
                <Text fontSize="xs" color="gray.500" mb={2}>
                  Please select agent which you want to use to generate email
                  response.
                </Text>
                <ModulesDropdown
                  selectedModule={selectedModule}
                  setSelectedModule={setSelectedModule}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel fontSize="sm" mb={0}>
                  Persona
                </FormLabel>
                <Text fontSize="xs" color="gray.500" mb={2}>
                  Please give your agent a personality which derives your
                  approach of writing emails.
                </Text>
                <RoleDropdown
                  selectedModule={selectedModule}
                  selectedRole={selectedRole}
                  setSelectedRole={setSelectedRole}
                  setSelectedModule={setSelectedModule}
                  size="sm"
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel fontSize="sm" mb={0}>
                  Sender Email Address
                </FormLabel>
                <Text fontSize="xs" color="gray.500" mb={2}>
                  Please select the sender's email address.
                </Text>
                <EmailDropdown
                  selectedEmail={selectedEmail}
                  setSelectedEmail={setSelectedEmail}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel fontSize="sm" mb={0}>
                  Mission Statement
                </FormLabel>
                <Text fontSize="xs" color="gray.500" mb={2}>
                  Please provide the purpose for which the Agent should generate
                  the email, e.g scheduling a meeting with a prospect.
                </Text>
                <Textarea
                  id="missionStatement"
                  placeholder="Enter mission statement"
                  name="missionStatement"
                  size="sm"
                  borderRadius="md"
                  value={missionStatement} // Use state value here
                  onChange={(e) => setMissionStatement(e.target.value)} // Update state on change
                  isReadOnly={editing}
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel fontSize="sm" mb={0}>
                  Allowed Days
                </FormLabel>
                <Text fontSize="xs" color="gray.500" mb={2}>
                  Please select the days on which the campaign will run.
                </Text>
                <Flex wrap="wrap">
                  {daysOptions.map((day) => (
                    <Checkbox
                      key={day}
                      value={day}
                      isChecked={allowedDays.includes(day)}
                      onChange={handleAllowedDaysChange}
                      mr={4}
                      size="sm"
                    >
                      {day}
                    </Checkbox>
                  ))}
                </Flex>
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel fontSize="sm" mb={0}>
                  Start Time
                </FormLabel>
                <Text fontSize="xs" color="gray.500" mb={2}>
                  Set the time when the email campaign should begin each day.
                </Text>
                <Input
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel fontSize="sm" mb={0}>
                  End Time
                </FormLabel>
                <Text fontSize="xs" color="gray.500" mb={2}>
                  Set the time when the email campaign should stop each day.
                </Text>
                <Input
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel fontSize="sm" mb={0}>
                  Time Zone
                </FormLabel>
                <Text fontSize="xs" color="gray.500" mb={2}>
                  Select the time zone for the start and end times.
                </Text>
                <TimezoneSelect
                  value={timeZone}
                  onChange={setTimeZone}
                />
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel fontSize="sm" mb={0}>
                  Emails per day
                  {/* (Recommended value: 30) */}
                </FormLabel>
                <Text fontSize="xs" color="gray.500" mb={2}>
                  Specify the maximum number of emails to be sent per day.
                </Text>
                {/* <Tooltip
                  hasArrow
                  label={inputError ? inputError : "Recommended value: 30"}
                  isDisabled={!inputError}
                  shouldWrapChildren
                  mt="1"
                > */}
                {/* <Box> */}
                <Input
                  id="emailsPerDay"
                  placeholder="Enter no of emails"
                  name="emailsPerDay"
                  size="sm"
                  borderRadius="md"
                  // width="215%" // Ensure Input takes full width
                  type="number"
                  value={emailRate}
                  onChange={handleChange}
                  borderColor={inputError ? "red.500" : "gray.200"}
                  isReadOnly={editing}
                />
                {/* </Box> */}
                {/* </Tooltip> */}
                {inputError && (
                  <FormLabel fontSize="sm" color="red.500" mb={0}>
                    {inputError}
                  </FormLabel>
                )}
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel fontSize="sm" mb={0}>
                  Import Prospect List
                </FormLabel>
                <Text fontSize="xs" color="gray.500" mb={2}>
                  Please select the data file containing the information for
                  your prospects.
                </Text>
                <Select
                  placeholder="Select data"
                  value={selectedDataId}
                  onChange={handleDropdownChange}
                  size="sm"
                >
                  {dropdownOptions.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.filename}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mt={4} isRequired>
                <FormLabel fontSize="sm" mb={0}>
                  Frequency
                </FormLabel>
                <Text fontSize="xs" color="gray.500" mb={2}>
                  Agent response frequency to replies from campaign emails.
                </Text>
                <Select
                  placeholder="Select frequency"
                  value={frequency}
                  onChange={(e) => setFrequency(e.target.value)}
                  size="sm"
                >
                  <option value="3 minutes">3 minutes</option>
                  <option value="30 minutes">30 minutes</option>
                  <option value="60 minutes">60 minutes</option>
                  <option value="2 hours">2 hours</option>
                  <option value="3 hours">3 hours</option>
                  <option value="5 hours">5 hours</option>
                </Select>
              </FormControl>

              <FormControl mt={4}>
                <Flex alignItems="center">
                  <Checkbox
                    id="isActive"
                    name="isActive"
                    size="sm"
                    mb={2}
                    isChecked={isActive} // Map the isActive state to the checkbox
                    onChange={(e) => setIsActive(e.target.checked)} // Update state when clicked
                    isReadOnly={editing}
                  />
                  <FormLabel fontSize="sm" ml={2}>
                    Active Agent
                    <Tooltip
                      label="When enabled agent will send the emails directly to prospects otherwise drafts emails will be generated and can be accessed in the draft section."
                      aria-label="A tooltip"
                      bgColor="white"
                      textColor="black"
                      borderColor="gray.500"
                      border="1px solid"
                      borderRadius="md"
                    >
                      <span>
                        <InfoOutlineIcon
                          color="gray.500" // Adjust this color to make the icon visible
                          ml={2}
                          cursor="pointer"
                          mb={1}
                          boxSize="12px"
                        />
                      </span>
                    </Tooltip>
                  </FormLabel>
                </Flex>
              </FormControl>
              
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                Save
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default CampaignModal;
