import usePersistedState from "./../usePersistedState";
import GenerateMinutes from "./GenerateMinutes/GenerateMinutes";
import { useEffect, useState, useRef } from "react";
import axios from "axios"; // Ensure you have axios installed
import { logout } from "../../auth";
import {
  Box,
  HStack,
  Heading,
  Text,
  Button,
  Flex,
  useToast,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Spacer,
  Input,
  IconButton,
  List,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tooltip,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon, CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import "../../styles/test.css";
import { useColorMode } from "@chakra-ui/react";
import Information from "./Information";
import { v4 as uuidv4 } from "uuid"; // Import uuid
import duplicateMinute from "./Information";
import axiosInstance from "../../helpers/axiosInstance";

const FileHandlingPage = () => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [minutesId, setMinutesId] = useState(null);
  const [fetchedMinutes, setFetchedMinutes] = useState([]);
  const [error, setError] = useState(null);
  const [currentMinute, setCurrentMinute] = usePersistedState(
    "currentMinuteUpload",
    ""
  );
  const [minutesList, setMinutesList] = usePersistedState(
    "minutesListUpload",
    []
  );
  const [selectedMinute, setSelectedMinute] = usePersistedState(
    "selectedMinuteUpload",
    null
  );
  const [showInputField, setShowInputField] = usePersistedState(
    "showInputFieldUpload",
    false
  );
  const [showInputFieldMinute, setShowInputFieldMinute] = usePersistedState(
    "showInputFieldMinuteUpload",
    false
  );
  const [currentFund, setCurrentFund] = useState(""); // New state for current fund name

  const informationRef = useRef();

  const handleRemoveMinute = async (minuteToRemove) => {
    if (minuteToRemove._id) {
      //console.log("Removing", minuteToRemove._id);
      try {
        // Assuming you have an API endpoint to delete a minute by its _id
        const response = await axiosInstance.post("/minutes/delete-minute", {
          str_id: minuteToRemove._id,
        });
        if (response.status === 200) {
          // Update the state after successful deletion
          setFetchedMinutes((prevState) =>
            prevState.filter((minute) => minute._id !== minuteToRemove._id)
          );
          toast({
            title: "Success",
            description: `Minute ${minuteToRemove.minutes_name} has been removed`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-center",
          });
          informationRef.current.clearFields();
        }
      } catch (error) {
        console.error(error);
        toast({
          title: "Error",
          description: `Error deleting minute: ${minuteToRemove.minutes_name}`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-center",
        });
      }
    } else {
      // If the minute doesn't have an _id, it's a newly created minute and not committed yet
      setMinutesList((prevState) =>
        prevState.filter((minute) => minute.id !== minuteToRemove.id)
      );
    }
  };

  const handleError = (response) => {
    //console.log(error);
    if (response && response.status === 401) {
      logout();
      toast({
        title: "Session expired",
        description: "Your session has expired. Please login again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setError("An error occurred.");
    }
  };

  const fetchMinutes = async () => {
    //console.log("Fetching minutes...");

    try {
      const response = await axiosInstance.get("/minutes/fetch-minutes");

      if (response.status === 200) {
        setFetchedMinutes(response.data); // Update fetchedMinutes state
        //console.log("Fetch Minutes response", response.data);
        //console.log("Minutes List", minutesList);
      } else {
        handleError(response);
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "Error in fetching minutes.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-center",
      });
    }
  };

  const handleMinuteCleared = () => {
    fetchMinutes(); // Re-fetch minutes when a minute is cleared in the child component.
  };

  useEffect(() => {
    fetchMinutes(); // Fetch minutes when the component mounts
  }, []);

  const allMinutes = [...fetchedMinutes, ...minutesList];

  const groupedMinutes = allMinutes.reduce((groups, minute) => {
    if (!groups[minute.fund_name]) {
      groups[minute.fund_name] = [];
    }
    groups[minute.fund_name].push(minute);
    return groups;
  }, {});
  //console.log("groupedMinutes", groupedMinutes);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newMinuteName, setNewMinuteName] = useState(() => {
    // This function ensures that if selectedMinute or its property is undefined,
    // it defaults to an empty string or any default value you want.
    return selectedMinute?.minutes_name || "";
  });

  // This useEffect will update newMinuteName if selectedMinute.minutes_name changes.
  useEffect(() => {
    setNewMinuteName(selectedMinute?.minutes_name || "");
  }, [selectedMinute]);

  const [copiedFromMinuteId, setCopiedFromMinuteId] = useState(null);
  const [duplicatingMinute, setDuplicatingMinute] = useState(null);

  const handleOpenModal = (minuteObj) => {
    //console.log("In handleOpenModal function");
    setIsModalOpen(true);
    setCopiedFromMinuteId(minuteObj._id);
    setDuplicatingMinute(minuteObj);
  };

  const handleDuplicateMinute = () => {
    //console.log("In handleDuplicateMinute function");
    if (newMinuteName.trim() !== "") {
      const newId = uuidv4(); // Assigning new UUID
      const existingFundName = duplicatingMinute.fund_name;
      handleAddMinute(existingFundName, newMinuteName); // Assuming handleAddMinute takes id and name

      const newMinute = {
        id: newId,
        fund_name: existingFundName,
        text: newMinuteName,
      };
      //console.log("newly created minute details", newMinute);
      //console.log("newly created uuid", newId);
      setSelectedMinute(newMinute);
      //console.log("Selected minute set", selectedMinute);
      setMinutesList((prevState) => [...prevState, newMinute]);

      // Here we're calling the duplicateMinute function from Information.js using the ref.
      if (copiedFromMinuteId) {
        informationRef.current.duplicateMinute(copiedFromMinuteId, newMinute);
      }

      setIsModalOpen(false);
    } else {
      alert("Please provide a valid name for the duplicated minute.");
    }
  };

  const handleAddFund = () => {
    if (currentFund) {
      const fundExists = minutesList.some(
        (item) => item.fund_name === currentFund
      );

      if (!fundExists) {
        setMinutesList((prevState) => [
          ...prevState,
          {
            //id: uuidv4(), // Create a new ID for this fund "entry"
            fund_name: currentFund,
            userCreated: true,
          },
        ]);
        setCurrentFund(""); // Clear current fund after adding
      } else {
        alert("This fund already exists.");
      }
    }
  };

  const handleAddMinute = (fundName) => {
    if (currentMinute) {
      const minuteId = uuidv4();
      setMinutesList((prevState) => [
        ...prevState,
        {
          id: minuteId,
          fund_name: fundName,
          text: currentMinute,
        },
      ]);
      setCurrentMinute("");
      setSelectedMinute({
        id: minuteId,
        fund_name: fundName,
        text: currentMinute,
      });
    }
  };

  // const handleRemoveFund = (fundToRemove) => {
  //   // First, check if the fund is user-created
  //   const isUserCreatedFund = minutesList.some(
  //     (minute) => minute.fund_name === fundToRemove && minute.userCreated
  //   );

  //   if (!isUserCreatedFund) {
  //     toast({
  //       title: "Error",
  //       description: `Fund "${fundToRemove}" cannot be removed because it's not user-created.`,
  //       status: "error",
  //       duration: 5000,
  //       isClosable: true,
  //       position: "top-center",
  //     });
  //     return;
  //   }

  //   // If the fund is user-created, remove all associated minutes from minutesList
  //   setMinutesList((prevState) =>
  //     prevState.filter((minute) => minute.fund_name !== fundToRemove)
  //   );

  //   toast({
  //     title: "Success",
  //     description: `All minutes associated with user-created fund "${fundToRemove}" have been removed`,
  //     status: "success",
  //     duration: 5000,
  //     isClosable: true,
  //     position: "top-center",
  //   });
  // };

  const handleRemoveFund = async (fundToRemove) => {
    const isUserCreatedFund = minutesList.some(
      (minute) => minute.fund_name === fundToRemove && minute.userCreated
    );

    if (isUserCreatedFund) {
      // Existing logic for removing user-created funds
      setMinutesList((prevState) =>
        prevState.filter((minute) => minute.fund_name !== fundToRemove)
      );
      toast({
        title: "Success",
        description: `Fund "${fundToRemove}" and its minutes have been removed.`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top-center",
      });
    } else {
      // New logic for removing minutes under non-user-created funds
      const minutesToRemove = fetchedMinutes.filter(
        (minute) => minute.fund_name === fundToRemove
      );

      if (minutesToRemove.length === 0) {
        toast({
          title: "Information",
          description: `No minutes found for fund "${fundToRemove}".`,
          status: "info",
          duration: 5000,
          isClosable: true,
          position: "top-center",
        });
        return;
      }

      if (
        !window.confirm(
          `Are you sure you want to delete all minutes under the fund: ${fundToRemove}?`
        )
      ) {
        return;
      }

      for (const minute of minutesToRemove) {
        await handleRemoveMinute(minute);
      }
    }
  };

  return (
    <Box
      minHeight="calc(100vh - 52px)"
      maxHeight="calc(100vh - 52px)"
      display="flex"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
      overflow="-moz-hidden-unscrollable"
    >
      <Box
        flex="2"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        display="flex"
        flexDirection="column"
        maxHeight="calc(100vh - 51px - 8px - 8px)"
        minHeight="calc(100vh - 51px - 8px - 8px)"
        minW="calc(20% + 8px)"
        mr={0}
        mt={2}
        ml={2}
        mb={2}
        borderRadius="lg"
      >
        <Box
          bg={colorMode === "dark" ? "gray.700" : "white"}
          pt={6}
          p={4}
          pb={2}
          borderRadius="lg"
        >
          <Text
            fontSize="md"
            fontWeight="semibold"
            ml={2}
            mb="4px"
            color={colorMode === "dark" ? "white" : "#1A202C"}
            // color="#1A202C"
          >
            Minutes
          </Text>
          <Heading
            fontSize="xs"
            color={colorMode === "dark" ? "white" : "blackAlpha.700"}
            mb={0}
            pl={2}
            pb={2}
          >
            Add the Minutes.
          </Heading>
          <Divider mt={0} mb={0} borderColor="#E2E8F0" />
          <Flex justify="space-between" align="center" mb={0}>
            <Heading
              fontSize="md"
              fontWeight="bold"
              color={colorMode === "dark" ? "white" : "blackAlpha.700"}
              mb={0}
              pl={2}
              p={2}
              pr={0}
            >
              Create Fund
            </Heading>
            <IconButton
              variant="ghost"
              aria-label="Add minute"
              icon={showInputField ? <MinusIcon /> : <AddIcon />}
              _hover={{}}
              bgColor={colorMode === "dark" ? "gray.100" : "gray.100"}
              color="black"
              onClick={() => setShowInputField(!showInputField)}
              size="xs"
              ml={4}
            />
          </Flex>
          {showInputField && (
            <Box pl={2} pb={1}>
              <Input
                placeholder="Enter Fund Name"
                size="sm"
                mb={2}
                value={currentFund}
                onChange={(e) => setCurrentFund(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter" && currentFund) handleAddFund();
                }}
              />
              <Button
                size="sm"
                _hover={{}}
                bgColor="blue.500"
                textColor="white"
                onClick={handleAddFund}
              >
                Add Fund
              </Button>
            </Box>
          )}

          <Box
            mt={0}
            mb={2}
            pt={0}
            overflowY="scroll"
            maxHeight={
              showInputField
                ? "calc(100vh - 156px - 118px)"
                : "calc(100vh - 70px - 118px)"
            }
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <Accordion allowMultiple>
              {Object.entries(groupedMinutes).map(([fundName, minutes]) => (
                <AccordionItem key={fundName}>
                  <HStack spacing={4}>
                    <AccordionButton>
                      <Text
                        mb={0}
                        color={
                          minutes.some(
                            (minuteObj) =>
                              (minuteObj._id || minuteObj.id) ===
                              (selectedMinute
                                ? selectedMinute._id || selectedMinute.id
                                : null)
                          )
                            ? "blue.300"
                            : colorMode === "dark"
                            ? "gray.200"
                            : "#1A202C"
                        }
                      >
                        {fundName}
                      </Text>
                      <Spacer />
                      {/* {minutesList.some(
                        (minute) =>
                          minute.fund_name === fundName && minute.userCreated
                      ) && (
                        <IconButton
                          variant="ghost"
                          aria-label={`Delete ${fundName}`}
                          icon={<DeleteIcon />}
                          color={colorMode === "dark" ? "white" : "black"}
                          onClick={(e) => {
                            e.stopPropagation(); // This stops the accordion from toggling when the delete button is clicked
                            if (
                              window.confirm(
                                `Are you sure you want to delete the fund: ${fundName}?`
                              )
                            ) {
                              handleRemoveFund(fundName);
                            }
                          }}
                          size="xs"
                        />
                      )} */}
                      <IconButton
                        variant="ghost"
                        aria-label={`Delete ${fundName}`}
                        icon={<DeleteIcon />}
                        color={colorMode === "dark" ? "white" : "black"}
                        onClick={(e) => {
                          e.stopPropagation(); // This stops the accordion from toggling when the delete button is clicked
                          if (
                            window.confirm(
                              `Are you sure you want to delete the fund: ${fundName}?`
                            )
                          ) {
                            handleRemoveFund(fundName);
                          }
                        }}
                        size="xs"
                      />

                      <AccordionIcon />
                    </AccordionButton>
                  </HStack>
                  <AccordionPanel pt={0} pb={0}>
                    <Flex justify="space-betweenf" align="center" mb={0}>
                      <Heading
                        fontSize="xs"
                        fontWeight="bold"
                        color={
                          colorMode === "dark" ? "white" : "blackAlpha.700"
                        }
                        mb={0}
                        pl={0}
                        p={2}
                        pr={0}
                      >
                        Create Minute
                      </Heading>
                      <Spacer />
                      <IconButton
                        variant="solid"
                        aria-label="Add minute"
                        icon={
                          showInputFieldMinute ? <MinusIcon /> : <AddIcon />
                        }
                        _hover={{}}
                        bgColor={colorMode === "dark" ? "gray.100" : "gray.100"}
                        color="black"
                        onClick={() =>
                          setShowInputFieldMinute(!showInputFieldMinute)
                        }
                        size="xs"
                        ml={4}
                      />
                    </Flex>
                    {showInputFieldMinute && (
                      <Box pl={2} pb={1}>
                        <Input
                          placeholder="Board Date"
                          size="sm"
                          mb={2}
                          value={currentMinute}
                          onChange={(e) => setCurrentMinute(e.target.value)}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") handleAddMinute(fundName);
                          }}
                        />
                        <Button
                          size="sm"
                          _hover={{}}
                          bgColor={
                            colorMode === "dark" ? "blue.500" : "blue.500"
                          }
                          textColor="white"
                          onClick={() => handleAddMinute(fundName)}
                        >
                          Add
                        </Button>
                      </Box>
                    )}
                    <List styleType="disc" pl={6}>
                      {/* {minutes.map((minuteObj) => ( */}
                      {minutes
                        .filter(
                          (minuteObj) =>
                            (minuteObj.text && minuteObj.text.trim() !== "") ||
                            (minuteObj.minutes_name &&
                              minuteObj.minutes_name.trim() !== "")
                        )
                        .map((minuteObj) => (
                          <ListItem key={minuteObj._id || minuteObj.id}>
                            <HStack spacing={3}>
                              <Text
                                maxW="70%"
                                as="button"
                                whiteSpace="normal"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                maxWidth="90%"
                                color={
                                  (minuteObj._id || minuteObj.id) ===
                                  (selectedMinute
                                    ? selectedMinute._id || selectedMinute.id
                                    : null)
                                    ? "blue.300"
                                    : colorMode === "dark"
                                    ? "gray.200"
                                    : "#1A202C"
                                }
                                onClick={() => {
                                  setSelectedMinute(minuteObj);
                                }}
                              >
                                {minuteObj.minutes_name || minuteObj.text}
                              </Text>
                              <Spacer />
                              {minuteObj.minutes_name && (
                                <Tooltip label="Copy" fontSize="sm">
                                  <CopyIcon
                                    variant="ghost"
                                    cursor="pointer"
                                    onClick={() => {
                                      setSelectedMinute(minuteObj);
                                      handleOpenModal(minuteObj);
                                    }}
                                  />
                                </Tooltip>
                              )}
                              <Tooltip label="Delete" fontSize="sm">
                                <IconButton
                                  variant="ghost"
                                  aria-label={`Delete ${
                                    minuteObj.minutes_name || minuteObj.text
                                  }`}
                                  icon={<DeleteIcon />}
                                  color={
                                    colorMode === "dark" ? "white" : "black"
                                  }
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        `Are you sure you want to delete the minute: ${
                                          minuteObj.minutes_name ||
                                          minuteObj.text
                                        } of the fund: ${fundName}?`
                                      )
                                    ) {
                                      handleRemoveMinute(minuteObj);
                                    }
                                  }}
                                  size="xs"
                                />
                              </Tooltip>
                            </HStack>
                          </ListItem>
                        ))}
                    </List>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>

            {isModalOpen && (
              <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader pb={1} mb={1}>
                    Copy Minute
                  </ModalHeader>
                  <p style={{ marginLeft: "24px", marginBottom: "8px" }}>
                    Make a copy of your minute with the same information
                  </p>
                  <ModalCloseButton />
                  <ModalBody>
                    <Input
                      placeholder="Enter New Minute Name"
                      value={newMinuteName}
                      onChange={(e) => setNewMinuteName(e.target.value)}
                    />
                    <Button
                      _hover={{}}
                      bgColor="blue.500"
                      textColor="white"
                      mt={4}
                      mb={2}
                      onClick={handleDuplicateMinute}
                    >
                      Make a copy
                    </Button>
                  </ModalBody>
                </ModalContent>
              </Modal>
            )}
          </Box>
        </Box>
      </Box>
      {/* middle container */}

      <Information
        ref={informationRef}
        minutesId={minutesId}
        selectedMinutesId={selectedMinute?._id}
        setMinutesId={setMinutesId}
        selectedMinute={selectedMinute}
        setSelectedMinute={setSelectedMinute}
        minutesList={minutesList}
        setMinutesList={setMinutesList}
        onMinuteCleared={handleMinuteCleared}
      />
      {/* right container */}
      <GenerateMinutes
        minutesId={minutesId}
        setMinutesId={setMinutesId}
        selectedMinutesId={selectedMinute?._id}
        selectedMinute={selectedMinute}
      />
    </Box>
  );
};
export default FileHandlingPage;
