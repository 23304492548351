import React from "react";
import {
  Box,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  Flex,
} from "@chakra-ui/react";
import { FaCaretDown } from "react-icons/fa";

export const SubjectEditorMenuBar = ({
  editor,
  dynamicVariables,
  handleVariableClick,
}) => {
  if (!editor) {
    return null;
  }

  return (
    <HStack spacing={2} borderRadius="md" boxShadow="sm" pl={2}>
      <Popover placement="top">
        <PopoverTrigger>
          <Flex p={1} alignItems="center">
            <Text mb={0} mr={1} cursor="pointer" color="blue">
              Variables
            </Text>
            <FaCaretDown color="blue" style={{ cursor: "pointer" }} />
          </Flex>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Dynamic Variables</PopoverHeader>
          <PopoverBody h="50vh" overflowY="scroll">
            {dynamicVariables.map((variable, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                p={2}
                cursor="pointer"
                onClick={() => handleVariableClick(variable)}
              >
                <Text fontWeight="bold" fontSize="xs" mr={2} w="50%">
                  {variable.key}
                </Text>
                <Text fontSize="xs" w="50%">
                  {variable.value}
                </Text>
              </Box>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );
};
