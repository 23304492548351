import { useState } from "react";
import {
  Box,
  Heading,
  Button,
  Flex,
  Input,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { useColorMode } from "@chakra-ui/react";

const DynamicInput = ({ title, onAdd }) => {
  const { colorMode } = useColorMode();
  const [showInputField, setShowInputField] = useState(false);
  const [value, setValue] = useState("");

  const handleAdd = () => {
    if (value) {
      onAdd(value);
      setValue("");
      setShowInputField(false);
    }
  };

  return (
    <Box>
      <Flex
        align="center"
        mb={0}
        pt={1}
        color={colorMode === "dark" ? "white" : "black"}
      >
        <Heading
          fontSize="sm"
          fontWeight="bold"
          color={colorMode === "dark" ? "white" : "blackAlpha.700"}
          pt={1}
          mt={0}
          mb={2}
          pl={0}
        >
          {title}
        </Heading>
        <IconButton
          variant="solid"
          aria-label={`Add ${title}`}
          icon={showInputField ? <MinusIcon /> : <AddIcon />}
          _hover={{}}
          bgColor={colorMode === "dark" ? "gray.100" : "gray.100"}
          color = "black"
          onClick={() => setShowInputField(!showInputField)}
          size="xs"
          ml={4}
        />
      </Flex>
      {showInputField && (
        <Box pl={0} pt={0}>
          <Input
            size="sm"
            mb={2}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={`Enter ${title.toLowerCase()}`}
            onKeyUp={(e) => {
              if (e.key === "Enter") handleAdd();
            }}
          />
          <Button
            size="sm"
            _hover={{}}
            bgColor="blue.500"
            textColor="white"
            mb={2}
            onClick={handleAdd}
          >
            Add {title}
          </Button>
        </Box>
      )}
    </Box>
  );
};
export default DynamicInput;
