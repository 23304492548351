import React, { useState, useEffect } from "react";
import {
  Select,
  HStack,
  FormControl,
  Flex,
  Input,
  VStack,
  Textarea,
  Checkbox,
  useToast,
  Menu,
  Box,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { logout } from "../../auth";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useColorMode } from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";

const ChatDropDown = ({
  selectedArea = [],
  setSelectedArea,
  selectedModule,
  setSelectedModule,
}) => {
  const { colorMode } = useColorMode();
  const [error, setError] = useState(null);
  const [modules, setModules] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedModuleChanged, setSelectedModuleChanged] = useState(false);
  const toast = useToast(); // Initialize the hook

  useEffect(() => {
    const storedModule = localStorage.getItem("selectedModuleChat");
    const storedArea = JSON.parse(localStorage.getItem("selectedAreaChat")); // parse array from string
    const storedAreaModule = localStorage.getItem("selectedAreaModuleChat");

    if (storedModule) {
      setSelectedModule(storedModule);
    }

    if (storedArea && storedAreaModule === storedModule) {
      setSelectedArea(storedArea);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedModuleChat", selectedModule);
    localStorage.setItem("selectedAreaChat", JSON.stringify(selectedArea)); // stringify array to string
    localStorage.setItem("selectedAreaModuleChat", selectedModule);
  }, [selectedModule, selectedArea]);

  useEffect(() => {
    if (selectedModuleChanged) {
      setSelectedArea([]);
      localStorage.removeItem("selectedAreaChat");
      localStorage.removeItem("selectedAreaModuleChat");
      setSelectedModuleChanged(false); // Reset back to false
    }
  }, [selectedModule]);

  const handleModuleChange = (module) => {
    setSelectedModule(module);
    setSelectedModuleChanged(true);
  };

  const fetchModules = async () => {
    //console.log("In fetch Modules function");

    try {
      const response = await axiosInstance.get("/genie/modules");

      if (response.status !== 200) {
        handleError(response);
        return;
      }

      setModules(response.data);
      //console.log("Modules : ", response.data);
    } catch (error) {
      //console.log(error);
      handleSession(error);
    }
  };

  useEffect(() => {
    fetchModules();
  }, []);

  const fetchAreas = async (moduleName) => {
    //console.log("In fetch area function");

    try {
      const response = await axiosInstance.post("/genie/get_module_area", {
        module_name: moduleName,
      });

      if (response.status !== 200) {
        handleError(response);
        return;
      }

      if (Array.isArray(response.data.areas)) {
        setAreas(response.data.areas);
        //console.log("Respone.data.areas Data: ", response.data.areas);
        // Automatically select all areas by default after fetching
        setSelectedArea(response.data.areas.map((area) => area.area_name));
      } else {
        console.error("Fetched areas data is not an array", response.data);
      }

      //console.log("Areas : ", response.data);
    } catch (error) {
      //console.log(error);
      handleSession(error);
    }
  };

  useEffect(() => {
    if (selectedModule && selectedModule !== "Select agent") {
      fetchAreas(selectedModule);
    } else {
      setAreas([]);
      // setSelectedArea([]);
    }
  }, [selectedModule]);

  const handleAreaChange = (areaName, e) => {
    if (e.target.checked) {
      setSelectedArea((prevSelectedArea) => [...prevSelectedArea, areaName]);
    } else {
      setSelectedArea((prevSelectedArea) =>
        prevSelectedArea.filter((area) => area !== areaName)
      );
    }
  };

  const handleAllChange = (e) => {
    if (e.target.checked) {
      setSelectedArea(areas.map((area) => area.area_name));
    } else {
      setSelectedArea([]);
    }
  };
  const handleAreaSelect = (areaName) => {
    if (selectedArea.includes(areaName)) {
      setSelectedArea((prevSelectedArea) =>
        prevSelectedArea.filter((area) => area !== areaName)
      );
    } else {
      setSelectedArea((prevSelectedArea) => [...prevSelectedArea, areaName]);
    }
  };

  const handleError = (response) => {
    if (response.status === 401) {
      logout();
      toast({
        title: "Session expired",
        description: "Your session has expired. Please login again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setError(`Error: ${response.status}`);
    }
  };

  const handleSession = (error) => {
    if (error.response && error.response.status === 401) {
      logout();
      toast({
        title: "Session expired",
        description: "Your session has expired. Please login again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setError(`Error: ${error.response.status}`);
    }
  };

  return (
    <HStack
      spacing={4}
      mt={0}
      mr={4}
      // color="blackAlpha.700"
      // width="50%"
    >
      <Select
        placeholder="Select agent"
        value={selectedModule}
        onChange={(e) => handleModuleChange(e.target.value)}
        // bg="alpha.100"
        width="200px"
      >
        {modules.map((module, index) => (
          <option key={index} value={module}>
            {module}
          </option>
        ))}
      </Select>
      <Box>
        <Menu closeOnSelect={false} width="150px">
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            variant="outline"
            borderColor={colorMode === "dark" ? "#4F5765" : "#E2E8F0"}
            // borderColor="#E2E8F0"
            borderRadius="md"
            p={2}
            colorScheme={colorMode === "dark" ? "#4F5765" : "#E2E8F0"}
            fontWeight="normal"
          >
            Select Areas
          </MenuButton>
          <MenuList
            bg={colorMode === "dark" ? "gray.700" : "white"}
            border="1px"
            borderColor="gray.200"
            borderRadius="md"
            mt={1}
          >
            <MenuItem>
              <Checkbox
                isChecked={selectedArea.length === areas.length}
                onChange={(e) => handleAllChange(e)}
              >
                All
              </Checkbox>
            </MenuItem>
            {areas.map((area, index) => (
              <MenuItem key={index}>
                <Checkbox
                  isChecked={selectedArea.includes(area.area_name)}
                  onChange={(e) => handleAreaChange(area.area_name, e)}
                >
                  {area.area_name}
                </Checkbox>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
    </HStack>
  );
};

export default ChatDropDown;
