import {
  Box,
  Flex,
  Heading,
  Button,
  Icon,
  IconButton,
  useColorMode,
  VStack,
  Text,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { AddIcon, EmailIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";

const SequenceHeader = ({
  handleSaveCampaign,
  campaignId,
  formData,
  dataId,
  isFromBackend,
  delayNumber,
  delayUnit,
  sequenceCount,
  setSequenceCount,
  currentCampaign,
}) => {
  const { colorMode } = useColorMode();
  const history = useHistory();

  const handleNewSequence = () => {
    if (currentCampaign) {
      setSequenceCount(currentCampaign.sequences.length);
    }
    history.push({
      pathname: `/campaign/${campaignId}/new-sequence`,
      state: {
        dataId,
        isFromBackend,
        delayNumber,
        delayUnit,
        sequenceCount: currentCampaign.sequences.length,
        campaignId,
      },
    });
  };

  const handleBackClick = () => {
    history.push(`/campaign`);
  };

  return (
    <Box
      p={5}
      pl={2}
      bg={colorMode === "dark" ? "gray.700" : "white"}
      h="10.7vh"
      borderBottom="1px"
      borderColor="blackAlpha.200"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <HStack>
        <IconButton
          icon={<ArrowBackIcon boxSize={5}/>}
          onClick={handleBackClick}
          size="md"
          bg="none"
          _hover="none"
          aria-label="Go back"
        />
        {!isFromBackend && (
          <Heading fontSize="lg" fontWeight="semibold" mt={4} m={0}>
            Sequences
          </Heading>
        )}
        {isFromBackend && (
          <VStack spacing={0} align="left">
            <Heading fontSize="lg" mb={1}>
              {currentCampaign?.campaign_name}
            </Heading>
            <Divider
              m={0}
              p={0}
              orientation="horizontal"
              borderColor="gray.300"
            />
            <HStack mt={1} justifyContent="left" alignItems="center">
              <Icon as={EmailIcon} boxSize={5} color="gray.500" />
              <Text fontSize="xs" color="gray.500" mb={0}>
                {currentCampaign?.connected_email}
              </Text>
            </HStack>
          </VStack>
        )}
      </HStack>

      <Flex>
        {!isFromBackend && (
          <Button
            mr={4}
            size="sm"
            onClick={() => handleSaveCampaign(campaignId)}
            colorScheme="blue"
          >
            Publish
          </Button>
        )}
        {!isFromBackend && (
          <Button
            size="sm"
            onClick={handleNewSequence}
            leftIcon={<Icon as={AddIcon} />}
            colorScheme="blue"
          >
            New Sequence
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default SequenceHeader;
