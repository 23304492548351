import axios from 'axios';
import { getAuthHeader } from '../../helpers/auth';
import axiosInstance from '../../helpers/axiosInstance';

const API_BASE_URL = '/app'; // Base URL for your APIs

const getMicrosoftAuthUrl = async () => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/get-microsoft-authentication-url`);
    return response;
  } catch (error) {
    throw error;
  }
};

// You can add more API calls related to authentication here

export const authApi = {
  getMicrosoftAuthUrl,
};