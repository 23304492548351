import React from "react";
import {
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  useColorMode,
  Box,
  Text,
  Icon,
} from "@chakra-ui/react";
import { ChevronDownIcon, CheckIcon, InfoIcon } from "@chakra-ui/icons";
import { FaCheckSquare } from "react-icons/fa";

const truncateText = (text, maxWordCount) => {
  const words = text.split(" ");
  return words.length > maxWordCount
    ? words.slice(0, maxWordCount).join(" ") + "..."
    : text;
};

const DropdownWithCheckboxes = ({
  questions,
  currentQuestionIndex,
  onSelect,
  selectedOptions,
}) => {
  const { colorMode } = useColorMode();

  const handleSelectChange = (index) => {
    let updatedSelectedOptions = [...selectedOptions];
    const isSelected = updatedSelectedOptions.includes(index);

    if (isSelected) {
      updatedSelectedOptions = updatedSelectedOptions.filter(
        (i) => i !== index
      );
    } else if (updatedSelectedOptions.length < 4) {
      updatedSelectedOptions.push(index);
    }

    onSelect(currentQuestionIndex, updatedSelectedOptions);
  };

  const tooltipStyles = {
    bg: colorMode === "dark" ? "gray.700" : "white", // Background color changes based on color mode
    color: colorMode === "dark" ? "white" : "black", // Text color changes based on color mode
    border: `1px solid ${colorMode === "dark" ? "gray.600" : "gray.400"}`, // Border color changes based on color mode
    borderRadius: "md",
    "&[data-popper-arrow]": {
      bg: colorMode === "dark" ? "gray.700" : "white", // Arrow background color changes based on color mode
    },
  };

  return (
    <Menu closeOnSelect={false} placement="top-start">
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        variant="outline"
        mt={2}
      >
        Link Questions
      </MenuButton>
      <MenuList
        bg={colorMode === "dark" ? "gray.700" : "white"}
        border="1px"
        borderColor="gray.200"
        borderRadius="md"
        maxH="200px" // Set maximum height for the dropdown container
        overflowY="auto" // Enable vertical scrolling when content exceeds max height
      >
        <Box
          px={4}
          py={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          borderBottomWidth="1px"
        >
          <Text fontSize="sm" fontWeight="bold" mb={0}>
            Selected Questions
          </Text>
          <Box display="flex" alignItems="center">
            <FaCheckSquare
              color={colorMode === "dark" ? "white" : "green.500"}
              size="16px"
              style={{ marginRight: "8px" }}
            />
            <Text fontSize="sm" mb={0}>{selectedOptions.length}</Text>
          </Box>
        </Box>

        {questions.slice(0, currentQuestionIndex).map((question, index) => (
          <Tooltip
            key={index}
            label={question.question}
            placement="right"
            sx={tooltipStyles}
          >
            <MenuItem>
              <Checkbox
                isChecked={selectedOptions.includes(index)}
                onChange={() => handleSelectChange(index)}
                isDisabled={
                  !selectedOptions.includes(index) &&
                  selectedOptions.length >= 4
                }
              >
                {truncateText(question.question, 6)}
              </Checkbox>
            </MenuItem>
          </Tooltip>
        ))}
      </MenuList>
    </Menu>
  );
};

export default DropdownWithCheckboxes;
