import React from "react";
import {
  Box,
  Image,
  Text,
  Flex,
  useColorMode,
} from "@chakra-ui/react";

const ConnectBox = ({ imageSrc, altText, platformName, handleConnect, isDisabled }) => {
  const { colorMode } = useColorMode();
  const bgColor = { light: "white", dark: "gray.700" };
  const borderColor = { light: "gray.300", dark: "gray.600" };

  // Styles for disabled state
  const disabledStyle = {
    opacity: 0.4,
    cursor: "not-allowed",
    _hover: { boxShadow: "none" }
  };

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      w="full"
      h="full"
      p={4}
      bg={bgColor[colorMode]}
      border="1px solid"
      borderColor={borderColor[colorMode]}
      borderRadius="md"
      cursor="pointer"
      _hover={{ boxShadow: "md" }}
      onClick={!isDisabled ? handleConnect : undefined} // Disable click function if isDisabled is true
      {...(isDisabled && disabledStyle)} // Apply disabled styles if isDisabled is true
    >
      <Image
        src={imageSrc}
        alt={altText}
        boxSize="40px"
        m={2}
      />
      <Text fontSize="sm" fontWeight="medium">{platformName}</Text>
    </Flex>
  );
};

export default ConnectBox;
