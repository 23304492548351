import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../auth";
import { useUser } from "./userRole/UserContext"; // <-- Import the user context hook

const ProtectedRoute = ({
  component: Component,
  roles: requiredRoles,
  module, // Pass the module name
  ...rest
}) => {
  const [logged] = useAuth();
  const { user, loading } = useUser();
  const userRole = user ? user.role : null;
  const moduleAccess = user && user.moduleAccess && user.moduleAccess[module];

  if (loading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!logged) {
          return <Redirect to="/login" />;
        }

        if (requiredRoles && !requiredRoles.includes(userRole)) {
          return <Redirect to="/login" />;
        }

        if (module && moduleAccess !== "allowed") {
          return <Redirect to="/" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;