import FileUpload from "../common/FileUpload";
import usePersistedState from "./../usePersistedState";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { v4 as uuidv4 } from "uuid";
import { logout } from "../../auth";
import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  useToast,
  Divider,
  Input,
  IconButton,
  Alert,
  AlertIcon,
  Tooltip,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon, EditIcon,DeleteIcon } from "@chakra-ui/icons";
import "../../styles/test.css";
import { useColorMode } from "@chakra-ui/react";
import { fetchMinuteDetails } from "./api";
import axiosInstance from "../../helpers/axiosInstance";

const Information = forwardRef(
  (
    {
      minutesId,
      setMinutesId,
      selectedMinutesId,
      selectedMinute,
      setSelectedMinute,
      minutesList,
      setMinutesList,
      onMinuteCleared,
    },
    ref
  ) => {
    const toast = useToast();
    const { colorMode } = useColorMode();
    const [selectedFiles, setSelectedFiles] = useState({
      // guidelines_file: null,
      transcript_file: null,
      boardpack_file: null,
    });
    const [reset, setReset] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(
      localStorage.getItem("setIsLoadingUpload") === "true"
    );
    const [error, setError] = useState(null);

    const [companyName, setCompanyName] = usePersistedState(
      "companyNameUpload",
      ""
    );
    const [companyAlias, setCompanyAlias] = usePersistedState(
      "companyAliasUpload",
      ""
    );
    const [fundName, setFundName] = usePersistedState("fundNameUpload", "");
    const [fundAlias, setFundAlias] = usePersistedState("fundAliasUpload", "");
    const [corporateSecretary, setCorporateSecretary] = usePersistedState(
      "corporateSecretaryUpload",
      ""
    );
    const [depositoryBank, setDepositoryBank] = usePersistedState(
      "depositoryBankUpload",
      ""
    );
    const [fundAdministrator, setFundAdministrator] = usePersistedState(
      "fundAdministratorUpload",
      ""
    );
    const [fundAuditor, setFundAuditor] = usePersistedState(
      "fundAuditorUpload",
      ""
    );
    const [investmentManagementEntity, setInvestmentManagementEntity] =
      usePersistedState("investmentManagementEntityUpload", "");
    const [startDate, setStartDate] = usePersistedState("startDateUpload", "");
    const [endDate, setEndDate] = usePersistedState("endDateUpload", "");
    const [directors, setDirectors] = usePersistedState("directorsUpload", []);
    const [currentDirector, setCurrentDirector] = usePersistedState(
      "currentDirectorUpload",
      ""
    );
    const [showInputDirector, setShowInputDirector] = usePersistedState(
      "showInputDirectorUpload",
      false
    );

    const [mainValues, setMainValues] = usePersistedState(
      "mainValuesUpload",
      []
    );
    const [currentMainValue, setCurrentMainValue] = usePersistedState(
      "currentMainValueUpload",
      ""
    );
    const [showMainValueInput, setShowMainValueInput] = usePersistedState(
      "showMainValueInputUpload",
      false
    );

    const [subValues, setSubValues] = usePersistedState("subValuesUpload", {});
    const [currentSubValue, setCurrentSubValue] = usePersistedState(
      "currentSubValueUpload",
      {}
    );
    const [showSubValueInput, setShowSubValueInput] = usePersistedState(
      "showSubValueInputUpload",
      false
    );

    const [editingDirector, setEditingDirector] = useState(null);
    const [editedDirector, setEditedDirector] = useState(null);
    const [editingMainValue, setEditingMainValue] = useState(null);
    const [editedMainValue, setEditedMainValue] = useState(null);
    const [editingSubValue, setEditingSubValue] = useState({
      mainValue: null,
      subValueIndex: null,
    });
    const [editedSubValue, setEditedSubValue] = useState(null);
    const [minutesData, setMinutesData] = usePersistedState("minutesData", {});
    const [isEditable, setIsEditable] = useState(false);
    const [isLoadingFetch, setIsLoadingFetch] = useState(false);

    const [agendaPointError, setAgendaPointError] = useState("");

    const [files, setFiles] = useState({
      docx: { filename: "" },
      pdf: { filename: "" },
    });

    const changeHandler = (name, file) => {
      const extension = file.name.split(".").pop();
      const newFilename = `${uuidv4()}.${extension}`;

      setFiles((prevFiles) => ({
        ...prevFiles,
        [name === "transcript_file" ? "docx" : "pdf"]: {
          file,
          filename: newFilename,
        },
      }));

      setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [name]: file,
      }));
    };

    const handleSubmission = () => {
      return new Promise((resolve, reject) => {
        //console.log("In handleSubmission function");
        setIsLoading(true); // start loading
        localStorage.setItem("setIsLoadingUpload", "true"); // Save state in localStorage

        // Check if all files are selected
        if (Object.values(selectedFiles).some((file) => !file)) {
          setIsLoading(false); // stop loading on error
          localStorage.removeItem("setIsLoadingUpload");
          toast({
            title: "Error",
            description: "All files are required",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top-center",
          });
          reject("Files missing");
          return;
        }

        // Notify user that uploading has started
        toast({
          title: "Training...",
          description: "Training in progress. You can continue using the app.",
          status: "info",
          duration: 5000, // persistent toast until manually closed or replaced
          isClosable: true,
          position: "bottom-right",
        });

        // Clear any existing error message
        setErrorMessage("");
        const formData = new FormData();

        // Loop through the selectedFiles and append each to formData
        // Object.entries(selectedFiles).forEach(([fileKey, file]) => {
        //   formData.append(fileKey, file);
        // });
        // Loop through the selectedFiles and append each to formData with the corresponding UUID filename
        Object.entries(selectedFiles).forEach(([fileKey, file]) => {
          const uuidFilename =
            files[fileKey === "transcript_file" ? "docx" : "pdf"].filename;
          formData.append(fileKey, file, uuidFilename);
        });

        axiosInstance
          .post("/minutes/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Overrides the default Content-Type
            },
          })
          .then((res) => {
            setSelectedFiles({
              transcript_file: null,
              boardpack_file: null,
            });
            setReset(true);

            // Notify user of successful upload
            toast({
              title: "Success",
              description: "File saved successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "bottom-right",
            });
            setIsLoading(false); // stop loading on success
            localStorage.removeItem("setIsLoadingUpload");
            resolve(res);
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              logout();
              toast({
                title: "Session expired",
                description: "Your session has expired. Please login again.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-center",
              });
            } else {
              // Notify user of the error
              setIsLoading(false); // stop loading on error
              localStorage.removeItem("setIsLoadingUpload");
              toast({
                title: "Generating Minutes Failed",
                description: "There was an error in training your files.",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-center",
              });
              console.error(err);
            }
            setIsLoading(false); // stop loading on error
            localStorage.removeItem("setIsLoadingUpload");
            reject(err);
          });
      });
    };

    useImperativeHandle(ref, () => ({
      clearFields,
      duplicateMinute,
    }));
    // Define a function to clear the input fields
    const clearFields = () => {
      setCompanyName("");
      setCompanyAlias("");
      setCorporateSecretary("");
      setDepositoryBank("");
      setDirectors([]);
      setEndDate("");
      setFundAdministrator("");
      setFundAlias("");
      setFundAuditor("");
      setFundName("");
      setInvestmentManagementEntity("");
      setStartDate("");
      setMainValues([]);
      setSubValues({});
    };

    const handleSubmit = () => {
      //console.log("In handle Submit");
      let isValid = true;

      if (selectedMinute && selectedMinute.id) {
        // Check if it’s a user-created minute

        // Define the fields and their error messages
        const fields = {
          companyName: "Company Name is required",
          fundName: "Fund Name is required",
          corporateSecretary: "Corporate Secretary is required",
          depositoryBank: "Depository Bank is required",
          fundAdministrator: "Fund Administrator is required",
          fundAuditor: "Fund Auditor is required",
          investmentManagementEntity:
            "Investment Management Entity is required",
          startDate: "Start Date is required",
          endDate: "End Date is required",
        };

        // Iterate over each field and check if it's empty
        Object.keys(fields).forEach((field) => {
          if (!eval(field)) {
            handleValidationError(selectedMinute.id, field, fields[field]);
            isValid = false;
          }
        });

        // Check other conditions
        if (directors.length === 0) {
          handleValidationError(
            selectedMinute.id,
            "directors",
            "Directors field is required."
          );
          isValid = false;
        }

        if (mainValues.length === 0) {
          handleValidationError(
            selectedMinute.id,
            "agendaPoint",
            "At least one agenda point is required."
          );
          isValid = false;
        }

        if (!isValid) return; // Don't proceed if any field is invalid

        handleSubmission()
          .then(() => {
            return saveMinutesInformation();
          })
          .then(() => {
            // Check if it’s a user-created minute
            if (selectedMinute && selectedMinute.id) {
              // Reset the input fields
              clearFields();
              if (selectedMinute && selectedMinute.id) {
                // Remove the selected minute from the minutesList
                setMinutesList((prevState) =>
                  prevState.filter((minute) => minute.id !== selectedMinute.id)
                );
                // Clear the selectedMinute
                setSelectedMinute(null);
                //console.log("Setting to null");
                onMinuteCleared();
              }
            }
          })
          .catch((error) => {
            //console.log("An error occurred:", error);
            // Handle any additional logic if needed
          });
      }
    };

    const clearAgendaPointError = () => {
      if (selectedMinute && selectedMinute.id) {
        setErrorState((prevState) => ({
          ...prevState,
          [selectedMinute.id]: {
            ...prevState[selectedMinute.id],
            agendaPoint: null, // Clear the error message for agendaPoint
          },
        }));
      }
    };

    const handleAddMainValue = () => {
      if (currentMainValue) {
        // Check for duplicate values
        if (mainValues.includes(currentMainValue)) {
          alert("This value already exists.");
          return;
        }

        setMainValues([...mainValues, currentMainValue]);
        setCurrentMainValue(""); // reset the input

        if (selectedMinute && selectedMinute.id) {
          const updatedMinuteData = { ...minutesData[selectedMinute.id] };

          updatedMinuteData.system_prompt.agenda_point = [
            ...updatedMinuteData.system_prompt.agenda_point,
            { main_value: currentMainValue, sub_value: [] },
          ];

          handleDataChange(selectedMinute.id, updatedMinuteData);
        }
      }
      clearAgendaPointError(); // Clear error unconditionally, similar to handleAddDirector
    };

    // You might not need this useEffect anymore, depending on your requirements
    useEffect(() => {
      if (mainValues.length > 0) {
        clearAgendaPointError();
      }
    }, [mainValues]);

    const handleRemoveMainValue = (mainValueToRemove) => {
      const updatedMainValues = mainValues.filter(
        (mainValue) => mainValue !== mainValueToRemove
      );
      setMainValues(updatedMainValues);

      if (selectedMinute && selectedMinute.id) {
        const updatedMinuteData = {
          ...minutesData[selectedMinute.id],
          system_prompt: {
            ...minutesData[selectedMinute.id].system_prompt,
            agenda_point: minutesData[
              selectedMinute.id
            ].system_prompt.agenda_point.filter(
              (item) => item.main_value !== mainValueToRemove
            ),
          },
        };
        handleDataChange(selectedMinute.id, updatedMinuteData);

        if (updatedMainValues.length === 0) {
          handleValidationError(
            selectedMinute.id,
            "agendaPoint",
            "At least one agenda point is required."
          );
        } else {
          setErrorState((prevState) => {
            const updatedState = { ...prevState };
            if (updatedState[selectedMinute.id]) {
              delete updatedState[selectedMinute.id].agendaPoint;
            }
            return updatedState;
          });
        }
      }
    };

    const handleMainValueChange = (e, index) => {
      setEditedMainValue(e.target.value);
      if (selectedMinute && selectedMinute.id) {
        const updatedMinuteData = { ...minutesData[selectedMinute.id] };

        // Ensure system_prompt and agenda_point exist
        updatedMinuteData.system_prompt = updatedMinuteData.system_prompt || {};
        updatedMinuteData.system_prompt.agenda_point =
          updatedMinuteData.system_prompt.agenda_point || [];

        // Update the main value
        if (updatedMinuteData.system_prompt.agenda_point[index]) {
          updatedMinuteData.system_prompt.agenda_point[index].main_value =
            e.target.value;
        }

        handleDataChange(selectedMinute.id, updatedMinuteData);
      }
    };

    const handleEditMainValue = (index, mainValue) => {
      setEditingMainValue(index);
      setEditedMainValue(mainValue);
    };

    const handleSaveMainValue = (index) => {
      setEditingMainValue(null);

      if (editedMainValue && selectedMinute && selectedMinute.id) {
        // Update the mainValues state.
        const updatedMainValues = mainValues.map((value, i) =>
          i === index ? editedMainValue : value
        );
        setMainValues(updatedMainValues);

        // Update the corresponding main_value in agenda_point.
        const updatedAgendaPoints = minutesData[
          selectedMinute.id
        ].system_prompt.agenda_point.map((point, i) =>
          i === index ? { ...point, main_value: editedMainValue } : point
        );

        // Update the minutesData state with the modified agenda_point.
        const updatedMinuteData = {
          ...minutesData[selectedMinute.id],
          system_prompt: {
            ...minutesData[selectedMinute.id].system_prompt,
            agenda_point: updatedAgendaPoints,
          },
        };
        handleDataChange(selectedMinute.id, updatedMinuteData);
      }
    };

    const handleEditSubValue = (mainValue, subValueIndex, subValue) => {
      setEditingSubValue({
        mainValue,
        subValueIndex,
      });
      setEditedSubValue(subValue);
    };

    const handleAddSubValue = (mainValue) => {
      if (currentSubValue[mainValue]) {
        const existingSubValues = subValues[mainValue] || [];

        // Check for duplicate sub-value
        if (existingSubValues.includes(currentSubValue[mainValue])) {
          alert("This sub-value already exists for the selected values.");
          return;
        }

        setSubValues((prev) => ({
          ...prev,
          [mainValue]: [...existingSubValues, currentSubValue[mainValue]],
        }));

        setCurrentSubValue((prev) => ({ ...prev, [mainValue]: "" })); // reset the specific input

        if (selectedMinute && selectedMinute.id) {
          const updatedMinuteData = { ...minutesData[selectedMinute.id] };

          const mainValueIndex =
            updatedMinuteData.system_prompt.agenda_point.findIndex(
              (point) => point.main_value === mainValue
            );

          if (mainValueIndex > -1) {
            updatedMinuteData.system_prompt.agenda_point[
              mainValueIndex
            ].sub_value.push(currentSubValue[mainValue]);
          }

          handleDataChange(selectedMinute.id, updatedMinuteData);
        }
      }
    };

    const handleRemoveSubValue = (mainValue, subValueToRemove) => {
      setSubValues((prev) => {
        const updatedSubValues = {
          ...prev,
          [mainValue]: (prev[mainValue] || []).filter(
            (subValue) => subValue !== subValueToRemove
          ),
        };

        // Update the minutesData state
        if (selectedMinute && selectedMinute.id) {
          const updatedMinuteData = { ...minutesData[selectedMinute.id] };
          const mainValueIndex =
            updatedMinuteData.system_prompt.agenda_point.findIndex(
              (point) => point.main_value === mainValue
            );

          if (mainValueIndex > -1) {
            updatedMinuteData.system_prompt.agenda_point[
              mainValueIndex
            ].sub_value = updatedSubValues[mainValue];
            handleDataChange(selectedMinute.id, updatedMinuteData);
          }
        }

        return updatedSubValues;
      });
    };

    const handleSaveSubValue = (mainValue, subValueIndex) => {
      setEditingSubValue({
        mainValue: null,
        subValueIndex: null,
      });

      if (editedSubValue && selectedMinute && selectedMinute.id) {
        const updatedMinuteData = { ...minutesData[selectedMinute.id] };
        const mainValueIndex =
          updatedMinuteData.system_prompt.agenda_point.findIndex(
            (point) => point.main_value === mainValue
          );

        if (mainValueIndex > -1) {
          const updatedSubValues = updatedMinuteData.system_prompt.agenda_point[
            mainValueIndex
          ].sub_value.map((subValue, index) =>
            index === subValueIndex ? editedSubValue : subValue
          );

          // Update the subValues local state
          setSubValues((prev) => ({
            ...prev,
            [mainValue]: updatedSubValues,
          }));

          // Update the minutesData state
          updatedMinuteData.system_prompt.agenda_point[
            mainValueIndex
          ].sub_value = updatedSubValues;
          handleDataChange(selectedMinute.id, updatedMinuteData);
        }
      }
    };

    const handleSubValueChange = (e, mainValueIndex, subValueIndex) => {
      setEditedSubValue(e.target.value);
      if (selectedMinute && selectedMinute.id) {
        const updatedMinuteData = {
          ...minutesData[selectedMinute.id],
        };

        if (!updatedMinuteData.mainValues[mainValueIndex].subValues) {
          updatedMinuteData.mainValues[mainValueIndex].subValues = [];
        }

        updatedMinuteData.mainValues[mainValueIndex].subValues[subValueIndex] =
          e.target.value;
        handleDataChange(selectedMinute.id, updatedMinuteData);
      }
    };

    const handleDirectorChange = (e, index) => {
      setEditedDirector(e.target.value);
      if (selectedMinute && selectedMinute.id) {
        const updatedMinuteData = {
          ...minutesData[selectedMinute.id],
        };
        updatedMinuteData.system_prompt.directors[index] = e.target.value;
        handleDataChange(selectedMinute.id, updatedMinuteData);
      }
    };

    const clearDirectorError = () => {
      if (selectedMinute && selectedMinute.id) {
        setErrorState((prevState) => ({
          ...prevState,
          [selectedMinute.id]: {
            ...prevState[selectedMinute.id],
            directors: null,
          },
        }));
      }
    };

    const handleAddDirector = () => {
      if (currentDirector) {
        // Check for duplicate heading
        if (directors.includes(currentDirector)) {
          alert("This Name already exists.");
          return;
        }

        // Update the local state
        setDirectors((prevDirectors) => [...prevDirectors, currentDirector]);

        // Update the minutesData state
        if (selectedMinute && selectedMinute.id) {
          const updatedMinuteData = {
            ...minutesData[selectedMinute.id],
            system_prompt: {
              ...minutesData[selectedMinute.id].system_prompt,
              directors: [
                ...minutesData[selectedMinute.id].system_prompt.directors,
                currentDirector,
              ],
            },
          };
          handleDataChange(selectedMinute.id, updatedMinuteData);
        }

        setCurrentDirector(""); // reset the input
      }
      clearDirectorError();
    };

    const handleRemoveDirector = (directorToRemove) => {
      const updatedDirectors = directors.filter(
        (director) => director !== directorToRemove
      );
      setDirectors(updatedDirectors);

      // If directors are also part of minutesData state, update it too
      if (selectedMinute && selectedMinute.id) {
        const updatedMinuteData = {
          ...minutesData[selectedMinute.id],
          system_prompt: {
            ...minutesData[selectedMinute.id].system_prompt,
            directors: updatedDirectors,
          },
        };
        handleDataChange(selectedMinute.id, updatedMinuteData);
      }
    };

    const handleSaveDirector = (index) => {
      if (editedDirector) {
        // Update the minutesData state
        if (selectedMinute && selectedMinute.id) {
          const updatedMinuteData = {
            ...minutesData[selectedMinute.id],
            system_prompt: {
              ...minutesData[selectedMinute.id].system_prompt,
              directors: minutesData[
                selectedMinute.id
              ].system_prompt.directors.map((director, i) =>
                i === index ? editedDirector : director
              ),
            },
          };
          handleDataChange(selectedMinute.id, updatedMinuteData);
        }

        // Exit editing mode
        setEditingDirector(null);
        setEditedDirector("");
      }
    };

    const handleEditDirector = (index, director) => {
      setEditingDirector(index);
      setEditedDirector(director);
    };

    const handleDataChange = (minuteId, newData) => {
      setMinutesData((prevState) => ({
        ...prevState,
        [minuteId]: {
          ...prevState[minuteId],
          ...newData,
          system_prompt: {
            ...prevState[minuteId]?.system_prompt,
            ...newData.system_prompt,
          },
        },
      }));
    };

    const [errorState, setErrorState] = useState({});

    // Handle validation error
    const handleValidationError = (minuteId, field, errorMessage) => {
      setErrorState((prevState) => ({
        ...prevState,
        [minuteId]: {
          ...prevState[minuteId],
          [field]: errorMessage,
        },
      }));
    };

    const createOnChangeHandler = (field, setter, errorField) => (e) => {
      const value = e.target.value;
      setter(value);

      if (selectedMinute && selectedMinute.id) {
        // Handle data change
        handleDataChange(selectedMinute.id, {
          ...minutesData[selectedMinute.id],
          system_prompt: {
            ...minutesData[selectedMinute.id].system_prompt,
            [field]: value,
          },
        });

        // Clear the error message if the value is valid
        if (value) {
          setErrorState((prevState) => ({
            ...prevState,
            [selectedMinute.id]: {
              ...prevState[selectedMinute.id],
              [errorField]: null,
            },
          }));
        }
      }
    };

    const getErrorMessage = (field) => {
      return selectedMinute ? errorState[selectedMinute.id]?.[field] : null;
    };
    // Then, you can use this utility function to get the error message for each field
    const companyNameErrorMessage = getErrorMessage("companyName");
    const fundNameErrorMessage = getErrorMessage("fundName");
    const corporateSecretaryErrorMessage =
      getErrorMessage("corporateSecretary");
    const depositoryBankErrorMessage = getErrorMessage("depositoryBank");
    const fundAdministratorErrorMessage = getErrorMessage("fundAdministrator");
    const fundAuditorErrorMessage = getErrorMessage("fundAuditor");
    const investmentManagementEntityErrorMessage = getErrorMessage(
      "investmentManagementEntity"
    );
    const startDateErrorMessage = getErrorMessage("startDate");
    const endDateErrorMessage = getErrorMessage("endDate");
    const directorsErrorMessage = getErrorMessage("directors");
    const agendaPointErrorMessage = getErrorMessage("agendaPoint");

    //................................................................

    //non committed minute data entry
    useEffect(() => {
      //console.log("Selected Minute useEffect");
      if (selectedMinute && selectedMinute.id) {
        setIsEditable(true);
        //console.log("isEditable state user created:", isEditable);
        const selectedMinuteData = minutesData[selectedMinute.id] || {};
        const {
          system_prompt: {
            agenda_point = [],
            company_alias,
            company_name,
            corporate_secretary,
            depository_bank,
            directors,
            end_date,
            fund_administrator,
            fund_alias,
            fund_auditor,
            fund_name,
            investment_management_entity,
            start_date,
          } = {},
        } = selectedMinuteData;

        // Extracting mainValues and subValues from agenda_point
        const mainVals = agenda_point.map((point) => point.main_value);
        const subVals = agenda_point.reduce((acc, point) => {
          acc[point.main_value] = point.sub_value;
          return acc;
        }, {});

        // Setting mainValues and subValues in the state
        setMainValues(mainVals);
        setSubValues(subVals);
        setCompanyAlias(company_alias || "");
        setCompanyName(company_name || "");
        setCorporateSecretary(corporate_secretary || "");
        setDepositoryBank(depository_bank || "");
        setDirectors(directors || [""]);
        setEndDate(end_date || "");
        setFundAdministrator(fund_administrator || "");
        setFundAlias(fund_alias || "");
        setFundAuditor(fund_auditor || "");
        setFundName(fund_name || "");
        setInvestmentManagementEntity(investment_management_entity || "");
        setStartDate(start_date || "");
      }
    }, [selectedMinute, minutesData]);

    useEffect(() => {
      //console.log("Selected Minute useEffect2");

      if (
        selectedMinute &&
        selectedMinute.id &&
        !minutesData[selectedMinute.id]
      ) {
        setMinutesData((prevState) => ({
          ...prevState,
          [selectedMinute.id]: {
            minutes_name: selectedMinute.text,
            system_prompt: {
              agenda_point: [],
              company_alias: "",
              company_name: "",
              corporate_secretary: "",
              depository_bank: "",
              directors: [],
              end_date: "",
              fund_administrator: "",
              fund_alias: "",
              fund_auditor: "",
              fund_name: "",
              investment_management_entity: "",
              start_date: "",
            },
          },
        }));
      }
    }, [selectedMinute, minutesData]);

    const token = localStorage
      .getItem("REACT_TOKEN_AUTH_KEY")
      .replace(/"/g, "");

    const handleError = (title, description) => {
      toast({
        title: title,
        description: description,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    };

    //Fetched minutes information mapping
    useEffect(() => {
      // //console.log("Selected Minute ID:", selectedMinutesId);
      const fetchData = async () => {
        //console.log("isEditable state fetched start:", isEditable);
        try {
          const minuteDetails = await fetchMinuteDetails(
            selectedMinutesId,
            token,
            handleError
          );
          //console.log("minuteDetails:", minuteDetails);
          //console.log("selectedMinutesId:", selectedMinutesId);
          if (minuteDetails) {
            setCompanyName(minuteDetails.system_prompt.company_name);
            setCompanyAlias(minuteDetails.system_prompt.company_alias);
            setCorporateSecretary(
              minuteDetails.system_prompt.corporate_secretary
            );
            setDepositoryBank(minuteDetails.system_prompt.depository_bank);
            setDirectors(minuteDetails.system_prompt.directors);
            setEndDate(minuteDetails.system_prompt.end_date);
            setFundAdministrator(
              minuteDetails.system_prompt.fund_administrator
            );
            setFundAlias(minuteDetails.system_prompt.fund_alias);
            setFundAuditor(minuteDetails.system_prompt.fund_auditor);
            setFundName(minuteDetails.system_prompt.fund_name);
            setInvestmentManagementEntity(
              minuteDetails.system_prompt.investment_management_entity
            );
            setStartDate(minuteDetails.system_prompt.start_date);

            // Extracting agenda_point from the fetched minuteDetails

            // const agendaPoints = minuteDetails
            //   ? Object.values(minuteDetails.minute_user_prompt)
            //   : minuteDetails?.system_prompt?.agenda_point || [];

            // // Extracting mainValues and subValues from agendaPoints
            // const initialMainValues = agendaPoints.map(
            //   (point) => point.main_value
            // );
            // const initialSubValues = {};
            // agendaPoints.forEach((point) => {
            //   initialSubValues[point.main_value] = point.sub_value.filter(
            //     (subValue) => subValue !== ""
            //   );
            // });

            // let agendaPoints;

            // if (minuteDetails && minuteDetails.minute_user_prompt) {
            //   agendaPoints = Object.values(minuteDetails.minute_user_prompt);
            // } else if (
            //   minuteDetails &&
            //   minuteDetails.system_prompt &&
            //   minuteDetails.system_prompt.agenda_point
            // ) {
            //   agendaPoints = minuteDetails.system_prompt.agenda_point;
            // } else {
            //   agendaPoints = [];
            // }

            // // Extracting mainValues and subValues from agendaPoints
            // const initialMainValues = agendaPoints.map(
            //   (point) => point.main_value
            // );
            // const initialSubValues = {};

            // agendaPoints.forEach((point) => {
            //   if (point.sub_value && Array.isArray(point.sub_value)) {
            //     initialSubValues[point.main_value] = point.sub_value.filter(
            //       (subValue) => subValue !== ""
            //     );
            //   } else {
            //     initialSubValues[point.main_value] = [];
            //   }
            // });

            // Extracting agenda_point from the fetched minuteDetails
            const agendaPoints = minuteDetails.system_prompt.agenda_point;

            // Extracting mainValues and subValues from agendaPoints
            const initialMainValues = agendaPoints.map(
              (point) => point.main_value
            );
            const initialSubValues = {};
            agendaPoints.forEach((point) => {
              initialSubValues[point.main_value] = point.sub_value.filter(
                (subValue) => subValue !== ""
              );
            });

            setMainValues(initialMainValues);
            setSubValues(initialSubValues);
          }
          if (selectedMinute && selectedMinute.id) {
            setIsEditable(true);
          } else {
            setIsEditable(false);
          }
          //console.log("Updated isEditable state:", isEditable);
        } catch (error) {
          console.error("Error fetching minute details:", error);
        }
      };

      fetchData();
    }, [selectedMinutesId]);

    const convertToPayload = (mainValues, subValues) => {
      return {
        agenda_point: mainValues.map((mainValue) => ({
          main_value: mainValue,
          sub_value: subValues[mainValue] || [],
        })),
      };
    };

    const saveMinutesInformation = async () => {
      //console.log("In create attributes function");
      setIsLoading(true); // start loading

      if (!selectedMinute || !selectedMinute.id) {
        console.error("Selected minute ID is not available");
        setIsLoading(false);
        return;
      }

      const agendaPoints = convertToPayload(mainValues, subValues).agenda_point;
      const payload = {
        //minute_id: selectedMinute.id, // Add the UUID of the selected minute to the payload
        docx_filename: files.docx.filename, // Assuming you have the docx file name stored in this variable
        pdf_filename: files.pdf.filename, // Assuming you have the pdf file name stored in this variable
        minutes_name: selectedMinute.text || selectedMinute,
        fund_name: selectedMinute.fund_name,
        system_prompt: {
          company_name: companyName,
          company_alias: companyAlias,
          fund_name: fundName,
          fund_alias: fundAlias,
          corporate_secretary: corporateSecretary,
          directors: directors, // Assuming this is an array as shown in your example
          depository_bank: depositoryBank,
          fund_administrator: fundAdministrator,
          fund_auditor: fundAuditor,
          investment_management_entity: investmentManagementEntity,
          start_date: startDate,
          end_date: endDate,
          agenda_point: agendaPoints,
        },
      };

      //console.log("payload", payload);
      try {
        const response = await axiosInstance.post(
          "/minutes/save-minutes-information",
          payload
        );

        if (response.status === 200) {
          setIsLoading(false); // stop loading on error
          localStorage.removeItem("setIsLoadingUpload");
          setMinutesId(response.data._id);
          toast({
            title: "Note",
            description: "Files are trained successfully",
            status: "info",
            duration: 5000, // persistent toast until manually closed or replaced
            isClosable: true,
            position: "bottom-right",
          });
        } else {
          setError(`Error: ${response.status}`);
          toast({
            title: "Error",
            description: "Error occured while training",
            status: "error",
            duration: 5000,
            isClosable: true,
          });

          if (response.status === 401) {
            logout();
            toast({
              title: "Session expired",
              description: "Your session has expired. Please login again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            setIsLoading(false); // stop loading on error
            localStorage.removeItem("setIsLoadingUpload");
          }
          return;
        }
        setIsLoading(false); // stop loading on success
        localStorage.removeItem("setIsLoadingUpload");
      } catch (error) {
        //console.log(error);
        if (error.response && error.response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError("An error occurred while trying to create attribute.");
        }
        setIsLoading(false); // stop loading on error
        localStorage.removeItem("setIsLoadingUpload");
      }
    };

    const duplicateMinute = async (minuteId, newMinuteDetails) => {
      //console.log("In Duplicate Minute Function");
      const minuteDetails = await fetchMinuteDetails(
        minuteId,
        token,
        handleError
      );
      //console.log("......Fetched minute details:", minuteDetails);
      //console.log(".......newMinuteDetails:", newMinuteDetails);
      if (minuteDetails) {
        const updatedMinutesData = {
          ...minutesData,
          [newMinuteDetails.id]: minuteDetails,
        };
        setMinutesData(updatedMinutesData);
        //console.log("Updated minutesData:", updatedMinutesData);
      }
    };

    return (
      <Box
        flex="4"
        maxW="40%"
        minW="40%"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        color={colorMode === "dark" ? "white" : "black"}
        display="flex"
        flexDirection="column"
        mr={2}
        mt={2}
        ml={2}
        mb={2}
        borderRadius="lg"
        p={4}
      >
        <Text
          fontSize="md"
          fontWeight="semibold"
          ml={2}
          mb="4px"
          color={colorMode === "dark" ? "white" : "#1A202C"}
          // color="#1A202C"
        >
          Corporate Information
        </Text>
        <Heading
          fontSize="xs"
          color={colorMode === "dark" ? "white" : "blackAlpha.700"}
          mb={0}
          pl={2}
          pb={2}
        >
          Add the Information.
        </Heading>
        <Divider mt={0} mb={0} borderColor="#E2E8F0" />
        <Box
          flex="4"
          maxW="100%" // Adjust the maximum width as needed
          minW="40%"
          pl={2}
          display="flex"
          flexDirection="column"
          overflowY="scroll"
          overflowX="hidden"
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {/* Input fields with headings */}
          <Flex direction="column" mb={1}>
            <Heading
              mt={2}
              fontSize="sm"
              fontWeight="bold"
              color={colorMode === "dark" ? "white" : "blackAlpha.700"}
              mb={2}
            >
              Company Name *
            </Heading>
            <Input
              size="sm"
              mb={3}
              value={companyName}
              onChange={createOnChangeHandler(
                "company_name",
                setCompanyName,
                "companyName"
              )}
              onBlur={() => {
                if (selectedMinute && selectedMinute.id && !companyName)
                  handleValidationError(
                    selectedMinute.id,
                    "companyName",
                    "Company Name is required"
                  );
              }}
              placeholder="Enter Company Name"
              required
              isReadOnly={!isEditable}
            />
            {companyNameErrorMessage && (
              <Text fontSize="sm" color="red">
                {companyNameErrorMessage}
              </Text>
            )}

            <Heading
              fontSize="sm"
              fontWeight="bold"
              color={colorMode === "dark" ? "white" : "blackAlpha.700"}
              mb={2}
            >
              Company Alias
            </Heading>
            <Input
              size="sm"
              mb={3}
              value={companyAlias}
              onChange={createOnChangeHandler(
                "company_alias",
                setCompanyAlias,
                "companyAlias"
              )}
              placeholder="Enter  Company Alias"
              isReadOnly={!isEditable}
            />
            <Heading
              fontSize="sm"
              fontWeight="bold"
              color={colorMode === "dark" ? "white" : "blackAlpha.700"}
              mb={2}
            >
              Fund Name *
            </Heading>
            <Input
              size="sm"
              mb={3}
              value={fundName}
              onChange={createOnChangeHandler(
                "fund_name",
                setFundName,
                "fundName"
              )}
              onBlur={() => {
                if (selectedMinute && selectedMinute.id && !fundName)
                  handleValidationError(
                    selectedMinute.id,
                    "fundName",
                    "Fund Name is required"
                  );
              }}
              placeholder="Enter Fund Name"
              required
              isReadOnly={!isEditable}
            />
            {fundNameErrorMessage && (
              <Text fontSize="sm" color="red">
                {fundNameErrorMessage}
              </Text>
            )}
            <Heading
              fontSize="sm"
              fontWeight="bold"
              color={colorMode === "dark" ? "white" : "blackAlpha.700"}
              mb={2}
            >
              Fund Alias
            </Heading>
            <Input
              size="sm"
              mb={3}
              value={fundAlias}
              onChange={createOnChangeHandler(
                "fund_alias",
                setFundAlias,
                "fundAlias"
              )}
              placeholder="Enter  Fund Alias"
              isReadOnly={!isEditable}
            />
            <Heading
              fontSize="sm"
              fontWeight="bold"
              color={colorMode === "dark" ? "white" : "blackAlpha.700"}
              mb={2}
            >
              Corporate Secretary *
            </Heading>
            <Input
              size="sm"
              mb={3}
              value={corporateSecretary}
              onChange={createOnChangeHandler(
                "corporate_secretary",
                setCorporateSecretary,
                "corporateSecretary"
              )}
              onBlur={() => {
                if (selectedMinute && selectedMinute.id && !corporateSecretary)
                  handleValidationError(
                    selectedMinute.id,
                    "corporateSecretary",
                    "Corporate Secretary is required"
                  );
              }}
              placeholder="Enter here"
              required
              isReadOnly={!isEditable}
            />
            {corporateSecretaryErrorMessage && (
              <Text fontSize="sm" color="red">
                {corporateSecretaryErrorMessage}
              </Text>
            )}
            <Heading
              fontSize="sm"
              fontWeight="bold"
              color={colorMode === "dark" ? "white" : "blackAlpha.700"}
              mb={2}
            >
              Depository Bank *
            </Heading>
            <Input
              size="sm"
              mb={3}
              value={depositoryBank}
              onChange={createOnChangeHandler(
                "depository_bank",
                setDepositoryBank,
                "depositoryBank"
              )}
              onBlur={() => {
                if (selectedMinute && selectedMinute.id && !depositoryBank)
                  handleValidationError(
                    selectedMinute.id,
                    "depositoryBank",
                    "Depository Bank is required"
                  );
              }}
              placeholder="Enter Depository Bank"
              required
              isReadOnly={!isEditable}
            />
            {depositoryBankErrorMessage && (
              <Text fontSize="sm" color="red">
                {depositoryBankErrorMessage}
              </Text>
            )}
            <Heading
              fontSize="sm"
              fontWeight="bold"
              color={colorMode === "dark" ? "white" : "blackAlpha.700"}
              mb={2}
            >
              Fund Administrator *
            </Heading>
            <Input
              size="sm"
              mb={3}
              value={fundAdministrator}
              onChange={createOnChangeHandler(
                "fund_administrator",
                setFundAdministrator,
                "fundAdministrator"
              )}
              onBlur={() => {
                if (selectedMinute && selectedMinute.id && !fundAdministrator)
                  handleValidationError(
                    selectedMinute.id,
                    "fundAdministrator",
                    "Fund Administrator is required"
                  );
              }}
              placeholder="Enter Fund Administrator"
              required
              isReadOnly={!isEditable}
            />
            {fundAdministratorErrorMessage && (
              <Text fontSize="sm" color="red">
                {fundAdministratorErrorMessage}
              </Text>
            )}
            <Heading
              fontSize="sm"
              fontWeight="bold"
              color={colorMode === "dark" ? "white" : "blackAlpha.700"}
              mb={2}
            >
              Fund Auditor *
            </Heading>
            <Input
              size="sm"
              mb={3}
              value={fundAuditor}
              onChange={createOnChangeHandler(
                "fund_auditor",
                setFundAuditor,
                "fundAuditor"
              )}
              onBlur={() => {
                if (selectedMinute && selectedMinute.id && !fundAuditor)
                  handleValidationError(
                    selectedMinute.id,
                    "fundAuditor",
                    "Fund Auditor is required"
                  );
              }}
              placeholder="Enter Fund Auditor"
              required
              isReadOnly={!isEditable}
            />
            {fundAuditorErrorMessage && (
              <Text fontSize="sm" color="red">
                {fundAuditorErrorMessage}
              </Text>
            )}
            <Heading
              fontSize="sm"
              fontWeight="bold"
              color={colorMode === "dark" ? "white" : "blackAlpha.700"}
              mb={2}
            >
              Investment Management Entity *
            </Heading>
            <Input
              size="sm"
              mb={3}
              value={investmentManagementEntity}
              onChange={createOnChangeHandler(
                "investment_management_entity",
                setInvestmentManagementEntity,
                "investmentManagementEntity"
              )}
              onBlur={() => {
                if (
                  selectedMinute &&
                  selectedMinute.id &&
                  !investmentManagementEntity
                )
                  handleValidationError(
                    selectedMinute.id,
                    "investmentManagementEntity",
                    "Investment Management Entity is required"
                  );
              }}
              placeholder="Enter Investment Management Entity"
              required
              isReadOnly={!isEditable}
            />
            {investmentManagementEntityErrorMessage && (
              <Text fontSize="sm" color="red">
                {investmentManagementEntityErrorMessage}
              </Text>
            )}
            <Heading
              fontSize="sm"
              fontWeight="bold"
              color={colorMode === "dark" ? "white" : "blackAlpha.700"}
              mb={2}
            >
              Start Date *
            </Heading>
            <Input
              type="date"
              size="sm"
              mb={3}
              value={startDate}
              onChange={createOnChangeHandler(
                "start_date",
                setStartDate,
                "startDate"
              )}
              onBlur={() => {
                if (selectedMinute && selectedMinute.id && !startDate)
                  handleValidationError(
                    selectedMinute.id,
                    "startDate",
                    "Start Date is required"
                  );
              }}
              placeholder="Start Date"
              required
              isReadOnly={!isEditable}
            />
            {startDateErrorMessage && (
              <Text fontSize="sm" color="red">
                {startDateErrorMessage}
              </Text>
            )}
            <Heading
              fontSize="sm"
              fontWeight="bold"
              color={colorMode === "dark" ? "white" : "blackAlpha.700"}
              mb={2}
            >
              End Date *
            </Heading>
            <Input
              type="date"
              size="sm"
              mb={3}
              value={endDate}
              onChange={createOnChangeHandler(
                "end_date",
                setEndDate,
                "endDate"
              )}
              onBlur={() => {
                if (selectedMinute && selectedMinute.id && !endDate)
                  handleValidationError(
                    selectedMinute.id,
                    "endDate",
                    "End Date is required"
                  );
              }}
              placeholder="End Date"
              required
              isReadOnly={!isEditable}
            />
            {endDateErrorMessage && (
              <Text fontSize="sm" color="red">
                {endDateErrorMessage}
              </Text>
            )}
          </Flex>
          <Flex justify="space-between" align="center" mb={2} pt={0}>
            <Heading
              fontSize="sm"
              fontWeight="bold"
              color={colorMode === "dark" ? "white" : "blackAlpha.700"}
              pt={0}
              mt={0}
              mb={2}
              pl={0}
            >
              Directors/Managers
            </Heading>
            <IconButton
              variant="solid"
              aria-label="Add module"
              icon={showInputDirector ? <MinusIcon /> : <AddIcon />}
              _hover={{}}
              bgColor={colorMode === "dark" ? "gray.100" : "gray.100"}
              color="black"
              onClick={() => setShowInputDirector(!showInputDirector)}
              size="xs"
              ml={4}
              isDisabled={!isEditable}
            />
          </Flex>
          {showInputDirector && (
            <Box pl={0} pt={0}>
              <Input
                size="sm"
                mb={2}
                value={currentDirector}
                onChange={(e) => setCurrentDirector(e.target.value)}
                placeholder="e.g John Doe"
                onKeyUp={(e) => {
                  if (e.key === "Enter") handleAddDirector();
                }}
                isReadOnly={!isEditable}
              />
              <Button
                size="sm"
                _hover={{}}
                bgColor="blue.500"
                textColor="white"
                mb={2}
                onClick={handleAddDirector}
                isDisabled={!isEditable}
              >
                Add
              </Button>
            </Box>
          )}
          {/* Display added headings */}
          <Flex direction="column" p={4} pb={0} pt={0} pr={0}>
            {directors.map((director, index) => (
              <Box key={index} mt={0} mb="4px" mr="4px">
                <Flex
                  align="center"
                  mb="4px"
                  position="relative"
                  pl={4}
                  _before={{
                    content: '""',
                    position: "absolute",
                    top: "10px",
                    left: 0,
                    transform: "translateY(-50%)",
                    width: "4px",
                    height: "4px",
                    borderRadius: "50%",
                    bgColor: colorMode === "dark" ? "white" : "blackAlpha.700", // Bullet color
                  }}
                >
                  {editingDirector === index ? (
                    <>
                      <Input
                        size="sm"
                        mb={2}
                        value={editedDirector}
                        // onChange={(e) => setEditedDirector(e.target.value)}
                        onChange={(e) => handleDirectorChange(e, index)}
                        placeholder="Edit director's name"
                        isReadOnly={!isEditable}
                      />
                      <Button
                        size="sm"
                        _hover={{}}
                        bgColor="blue.500"
                        textColor="white"
                        mb={2}
                        onClick={() => handleSaveDirector(index)}
                        // isDisabled={!isEditable}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <>
                      <Text
                        mr={2}
                        fontSize="sm"
                        fontWeight="semibold"
                        mb={0}
                        maxW="90%"
                        onClick={() => handleEditDirector(index, director)}
                        cursor="pointer"
                      >
                        {director}
                      </Text>
                      <IconButton
                        aria-label="Edit director"
                        variant="ghost"
                        icon={<EditIcon />}
                        colorScheme="gray"
                        boxSize="10px"
                        size="xs"
                        mt="3px"
                        onClick={() => handleEditDirector(index, director)}
                        isDisabled={!isEditable}
                      />
                    </>
                  )}
                  <IconButton
                    aria-label="Delete main heading"
                    variant="ghost"
                    icon={<DeleteIcon />}
                    color="black"
                    boxSize="10px"
                    size="xs"
                    mt="3px"
                    onClick={() => handleRemoveDirector(director)}
                    isDisabled={!isEditable}
                  />
                </Flex>
              </Box>
            ))}
            {directorsErrorMessage && (
              <Text fontSize="sm" color="red">
                {directorsErrorMessage}
              </Text>
            )}
          </Flex>
          <Heading
            fontSize="sm"
            fontWeight="bold"
            color={colorMode === "dark" ? "white" : "blackAlpha.700"}
            pt={0}
            mt={0}
            mb={2}
            pl={0}
          >
            Agenda Points
          </Heading>
          {/* Display added values */}
          <Flex direction="column" pl={4} pb={0} pt={0} pr={0}>
            {agendaPointErrorMessage && (
              <Text fontSize="sm" color="red">
                {agendaPointErrorMessage}
              </Text>
            )}
            {mainValues.map((mainValue, index) => (
              <Box key={index} mt={0} mb="4px" mr="4px">
                <Flex
                  align="center"
                  mb="4px"
                  position="relative"
                  pl={4}
                  _before={{
                    content: '""',
                    position: "absolute",
                    top: "10px",
                    left: 0,
                    transform: "translateY(-50%)",
                    width: "4px",
                    height: "4px",
                    borderRadius: "50%",
                    bgColor: colorMode === "dark" ? "white" : "blackAlpha.700", // Bullet color
                  }}
                >
                  {editingMainValue === index ? (
                    <>
                      <Input
                        size="sm"
                        mb={2}
                        value={editedMainValue}
                        // onChange={(e) => setEditedMainValue(e.target.value)}
                        onChange={(e) => handleMainValueChange(e, index)}
                        placeholder="Edit main value"
                        isReadOnly={!isEditable}
                      />
                      <Button
                        size="sm"
                        _hover={{}}
                        bgColor="blue.500"
                        textColor="white"
                        mb={2}
                        onClick={() => handleSaveMainValue(index)}
                        // isDisabled={!isEditable}
                      >
                        Save
                      </Button>
                    </>
                  ) : (
                    <>
                      <Text
                        mr={2}
                        fontSize="sm"
                        fontWeight="semibold"
                        mb={0}
                        maxW="90%"
                        onClick={() => handleEditMainValue(index, mainValue)}
                        cursor="pointer"
                      >
                        {mainValue}
                      </Text>
                      <IconButton
                        aria-label="Edit main value"
                        variant="ghost"
                        icon={<EditIcon />}
                        colorScheme="gray"
                        boxSize="10px"
                        size="xs"
                        mt="3px"
                        onClick={() => handleEditMainValue(index, mainValue)}
                        isDisabled={!isEditable}
                      />
                    </>
                  )}
                  <IconButton
                    aria-label="Delete main value"
                    variant="ghost"
                    icon={<DeleteIcon />}
                    color="black"
                    boxSize="10px"
                    size="xs"
                    mt="3px"
                    onClick={() => handleRemoveMainValue(mainValue)}
                    isDisabled={!isEditable}
                  />
                </Flex>

                {/* Display added sub-valuess for the current main value */}
                <Flex direction="column" spacing={2}>
                  {(subValues[mainValue] || []).map((subValue, subIndex) => (
                    <Flex
                      key={subIndex}
                      align="center"
                      mb="4px"
                      mr="4px"
                      position="relative"
                      pl={4}
                      _before={{
                        content: '"→"',
                        position: "absolute",
                        top: "7px",
                        left: 4,
                        transform: "translateY(-50%)",
                        fontSize: "12px",
                        color:
                          colorMode === "dark" ? "white" : "blackAlpha.700",
                      }}
                    >
                      {editingSubValue.mainValue === mainValue &&
                      editingSubValue.subValueIndex === subIndex ? (
                        <>
                          <Input
                            pl={2}
                            size="xs"
                            mt={2}
                            mb={2}
                            value={editedSubValue}
                            // onChange={(e) => setEditedSubValue(e.target.value)}
                            onChange={(e) =>
                              handleSubValueChange(e, mainValue, subIndex)
                            }
                            placeholder="Edit sub-value"
                            isReadOnly={!isEditable}
                          />
                          <Button
                            size="xs"
                            _hover={{}}
                            bgColor={
                              colorMode === "dark" ? "blue.500" : "blue.500"
                            }
                            textColor="white"
                            // mb={2}
                            onClick={() =>
                              handleSaveSubValue(mainValue, subIndex)
                            }
                            // isDisabled={!isEditable}
                          >
                            Save
                          </Button>
                        </>
                      ) : (
                        <>
                          <Text pl={4} mr={2} fontSize="xs" mb={0} maxW="90%">
                            {subValue}
                          </Text>
                          <IconButton
                            aria-label="Edit sub-value"
                            variant="ghost"
                            icon={<EditIcon />}
                            colorScheme="gray"
                            boxSize="10px"
                            size="xs"
                            mt="3px"
                            onClick={() =>
                              handleEditSubValue(mainValue, subIndex, subValue)
                            }
                            isDisabled={!isEditable}
                          />
                        </>
                      )}
                      <IconButton
                        aria-label="Delete sub-value"
                        variant="ghost"
                        icon={<DeleteIcon />}
                        color="black"
                        boxSize="10px"
                        size="xs"
                        mt="3px"
                        onClick={() =>
                          handleRemoveSubValue(mainValue, subValue)
                        }
                        isDisabled={!isEditable}
                      />
                    </Flex>
                  ))}
                </Flex>

                {/* "Create Sub-value" component */}
                <Flex justify="space-between" align="center" mb={0} pt={0}>
                  <Heading
                    fontSize="xs"
                    fontWeight="bold"
                    color={colorMode === "dark" ? "white" : "blackAlpha.700"}
                    pt={0}
                    mt={0}
                    mb={2}
                    pl={8}
                  >
                    Sub Agenda
                  </Heading>
                  <IconButton
                    variant="solid"
                    aria-label="Add sub-value"
                    icon={
                      showSubValueInput === mainValue ? (
                        <MinusIcon />
                      ) : (
                        <AddIcon />
                      )
                    }
                    _hover={{}}
                    bgColor={colorMode === "dark" ? "gray.100" : "gray.100"}
                    color="black"
                    onClick={() => {
                      if (showSubValueInput === mainValue) {
                        setShowSubValueInput(null);
                      } else {
                        setShowSubValueInput(mainValue);
                      }
                    }}
                    size="xs"
                    ml={8}
                    isDisabled={!isEditable}
                  />
                </Flex>

                {showSubValueInput === mainValue && (
                  <Box pl={8} pt={0}>
                    <Input
                      pl={2}
                      size="xs"
                      mt={2}
                      mb={2}
                      value={currentSubValue[mainValue] || ""}
                      onChange={(e) =>
                        setCurrentSubValue({
                          ...currentSubValue,
                          [mainValue]: e.target.value,
                        })
                      }
                      placeholder="Enter a sub-field"
                      onKeyUp={(e) => {
                        if (e.key === "Enter") handleAddSubValue(mainValue);
                      }}
                      isReadOnly={!isEditable}
                    />
                    <Button
                      size="xs"
                      pl={2}
                      _hover={{}}
                      bgColor="blue.500"
                      textColor="white"
                      mb={2}
                      onClick={() => handleAddSubValue(mainValue)}
                      isDisabled={!isEditable}
                    >
                      Add Sub-Agenda
                    </Button>
                  </Box>
                )}
              </Box>
            ))}
            <Flex justify="space-between" align="center" mb={0} mt={1} pt={0}>
              <Heading
                fontSize="sm"
                fontWeight="bold"
                color={colorMode === "dark" ? "white" : "blackAlpha.700"}
                pt={0}
                mt={0}
                mb={2}
                pl={0}
              >
                Create Agenda
              </Heading>
              <IconButton
                variant="solid"
                aria-label="Add module"
                icon={showMainValueInput ? <MinusIcon /> : <AddIcon />}
                _hover={{}}
                bgColor={colorMode === "dark" ? "gray.100" : "gray.100"}
                color="black"
                onClick={() => setShowMainValueInput(!showMainValueInput)}
                size="xs"
                ml={4}
                isDisabled={!isEditable}
              />
            </Flex>
            {showMainValueInput && (
              <Box pl={0} pt={0}>
                <Input
                  size="sm"
                  mt={2}
                  mb={2}
                  value={currentMainValue}
                  onChange={(e) => setCurrentMainValue(e.target.value)}
                  placeholder="Enter a field"
                  onKeyUp={(e) => {
                    if (e.key === "Enter") handleAddMainValue();
                  }}
                  isReadOnly={!isEditable}
                />
                <Button
                  size="sm"
                  _hover={{}}
                  bgColor="blue.500"
                  textColor="white"
                  mb={2}
                  onClick={handleAddMainValue}
                  isDisabled={!isEditable}
                >
                  Add
                </Button>
              </Box>
            )}
          </Flex>
          <Flex direction="row" align="center" w="100%" mt={0} ml={1}>
            <Text fontWeight="bold" mr={4} pt={3}>
              Upload Files
            </Text>

            <Tooltip label="Upload Transcript" placement="top">
              <span>
                {" "}
                {/* Wrapper span for proper tooltip behavior on disabled elements */}
                <FileUpload
                  name="transcript_file"
                  onChange={changeHandler}
                  reset={reset}
                  selectedFile={selectedFiles.transcript_file}
                  isEditable={isEditable}
                />
              </span>
            </Tooltip>

            <Tooltip label="Upload Board Pack" placement="top">
              <span>
                {" "}
                {/* Wrapper span for proper tooltip behavior on disabled elements */}
                <FileUpload
                  name="boardpack_file"
                  onChange={changeHandler}
                  reset={reset}
                  selectedFile={selectedFiles.boardpack_file}
                  isEditable={isEditable}
                />
              </span>
            </Tooltip>

            {errorMessage && (
              <Alert status="error" variant="left-accent" flexShrink={1}>
                <AlertIcon />
                {errorMessage}
              </Alert>
            )}
          </Flex>
          <Button
            width="100%"
            p={3}
            mt={2}
            onClick={() => handleSubmit()}
            // onClick={() => setShouldStartSubmission(true)}
            isLoading={isLoading}
            loadingText="Training.."
            _hover={{}}
            bgColor="blue.500"
            textColor="white"
            isDisabled={!isEditable}
          >
            Train
          </Button>
        </Box>
      </Box>
    );
  }
);
export default Information;
