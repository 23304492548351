import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { Logo } from "./Logo";
import { useHistory } from "react-router-dom";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const { token } = useParams();
  const toast = useToast();
  const { colorMode } = useColorMode();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`/auth/reset_password/${token}`, { password });

      // Show a success toast
      toast({
        title: "Password Reset Successful",
        description: "Your password has been successfully reset.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      // Redirect the user to the login page
      history.push("/login");
    } catch (error) {
      console.error(error);

      // Extract the error message from the server response
      const errorMessage =
        error.response && error.response.data.error
          ? error.response.data.error
          : "An error occurred while trying to reset your password. Please try again.";

      // Show an error toast
      toast({
        title: "An error occurred.",
        description: errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  // Function to determine the boxShadow based on colorMode and screen size
  const boxShadow = () => ({
    base: "none",
    sm:
      colorMode === "light"
        ? "0px 4px 6px -1px rgba(0, 0, 0, 0.15)"
        : "0px 4px 6px -1px rgba(255, 255, 255, 0.2)",
  });

  const bgColor = useColorModeValue("white", "#171923");

  useEffect(() => {
    document.body.style.backgroundColor = bgColor;
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.backgroundColor = ""; // Reset to default
      document.body.style.overflow = "unset";
    };
  }, [bgColor]);

  return (
    <Container
      maxW="lg"
      py={{
        base: "10",
        md: "20",
      }}
      px={{
        base: "0",
        sm: "8",
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Logo />
          <Heading
            size={{
              base: "xs",
              md: "30px",
            }}
            textAlign="center"
          >
            Reset Password
          </Heading>
        </Stack>
        <Box
          py={{
            base: "0",
            sm: "8",
          }}
          px={{
            base: "4",
            sm: "10",
          }}
          bg={{
            base: "transparent",
            sm: "bg.surface",
          }}
          // boxShadow={{
          //   base: "none",
          //   sm: "md",
          // }}
          boxShadow={boxShadow()}
          borderRadius={{
            base: "none",
            sm: "xl",
          }}
        >
          <Box as="form" onSubmit={handleSubmit}>
            <Stack spacing="6">
              <FormControl>
                <FormLabel htmlFor="password">New Password</FormLabel>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </FormControl>
              <Button type="submit">Update Password</Button>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Container>
  );
}

export default ResetPassword;
