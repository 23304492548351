import { getAuthHeader, handleUnauthorized } from "../../helpers/auth";
import axiosInstance from "../../helpers/axiosInstance";




export const fetchFund = async (setFetchedFunds, showToast) => {
  //console.log("Fetching fetchFund...");

  try {
    const response = await axiosInstance.get("/fund_docs/fetch-fund-docs");

    //console.log("API Response:", response.data); // Checking the API response

    if (response.status === 200) {
      if (Array.isArray(response.data.data)) { // Assuming the iterable array is nested under `data` key
        setFetchedFunds(response.data.data);
      } else {
        console.error("Data is not an array", response.data);
        setFetchedFunds([]); // Set to empty array if data is not an array
      }
    } else {
      if (response && response.status === 401) {
        handleUnauthorized(showToast);
      }
    }
  } catch (error) {
    console.error("Error fetching funds:", error);
    showToast({
      title: "Error",
      description: "Error in fetching Fund.",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-center",
    });
  }
};





// export const fetchFundDetails = async (selectedFundsId, setters, toast) => {
//   if (selectedFundsId) {
//     const payload = { str_id: selectedFundsId };

//     try {
//       const response = await axiosInstance.post("fund_docs/fetch-fund-docs-by-id", payload);
//       //console.log("fetchFundDetails response", response.data.data);

//       if (response.status === 200) {
//         const data = response.data.data;
//         const parsedSelectedCountries = JSON.parse(data.selected_countries || "[]");

//         // Destructure setters for clarity and safety
//         const {
//           setCrossRefCheck,
//           setCountryCheck,
//           setSelectedCountries,
//           setMarketingMaterial,
//           setFundProspectus,
//           setFundType,
//           setMarketingToRetail,
//           setInsuranceProduct,
//           setSfdrArticle,
//           setReport,
//         } = setters;

//         setCrossRefCheck(data.cross_ref_check);
//         setCountryCheck(data.country_check);
//         setSelectedCountries(parsedSelectedCountries);
//         setMarketingMaterial(data.marketing_material);
//         setFundProspectus(data.fund_prospectus);
//         setFundType(data.fund_type);
//         setMarketingToRetail(data.marketing_to_retail);
//         setInsuranceProduct(data.insurance_product);
//         setSfdrArticle(data.sfdr_article);
//         setReport(Array.isArray(data.report) ? data.report : []);
//       }
//     } catch (error) {
//       toast({
//         title: "Error fetching fund details",
//         description: error.response?.data.message || "Failed to fetch data",
//         status: "error",
//         duration: 3000,
//         isClosable: true,
//       });
//     }
//   }
// };
