import React, { useRef, useState, useEffect, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  Box,
  useDisclosure,
  Flex,
  useColorMode,
  Heading,
  IconButton,
  useToast,
  Spacer,
  HStack,
  Text,
  VStack,
  Divider,
  Icon,
} from "@chakra-ui/react";
import { ArrowBackIcon, EmailIcon } from "@chakra-ui/icons";
import { useLocation, useHistory } from "react-router-dom";
import axiosInstance from "../../../../helpers/axiosInstance";
import TemplatePanel from "../SequenceFormBox/TemplatePanel";
import SequenceDetailsPanel from "../SequenceFormBox/SequenceDetailsPanel";

const EditSequenceFormBox = ({
  handleChange,
  handleMeetingCheckboxChange,
  handleSaveSequence,
  formData,
  setFormData,
  dataId, // Add null check for currentCampaign.data_id
  isFromBackend,
  delayNumber,
  setDelayNumber,
  delayUnit,
  setDelayUnit,
  sequenceCount,
  setIsEditView,
  campaignId,
  currentSequenceId,
  setCampaigns,
  currentCampaign,
}) => {
  const toast = useToast();
  const templateEditorRef = useRef(null);
  const { colorMode } = useColorMode();
  const location = useLocation();
  const history = useHistory();
  const [dynamicVariables, setDynamicVariables] = useState([]);
  const [signature, setSignature] = useState("");

  const handleBackClick = () => {
    setIsEditView(false);
    const campaignData = `${campaignId}:${isFromBackend}`;
    history.push(`/sequences/${campaignData}`);
  };

  // Fetch dynamic variables
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/template/${dataId}`);
        const data = response.data.data;
        const mappedData = Object.entries(data).map(([key, value]) => ({
          key: `{{${key}}}`,
          value: value.toString(),
        }));
        setDynamicVariables(mappedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Fetch signature
  useEffect(() => {
    const fetchSignature = async () => {
      try {
        const response = await axiosInstance.get("/signature/fetch-signature");
        if (response.data.success) {
          const fetchedSignature = response.data.data[0].signature_text; // Assuming you want the first signature
          setSignature(fetchedSignature);
        } else {
          console.error("Error fetching signature:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching signature:", error);
      }
    };

    fetchSignature();
  }, []);

  // SequenceFormBox.js

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setFormData((prevFormData) => {
      let updatedTemplate = prevFormData.sequence_template;

      if (name === "signature") {
        if (checked) {
          // Append the signature only if it’s not already at the end
          if (!updatedTemplate.endsWith(signature)) {
            updatedTemplate += signature;
          }
          if (templateEditorRef.current) {
            templateEditorRef.current.insertSignature(signature);
          }
        } else {
          // Remove the signature if it matches exactly at the end
          const savedSignature = signature; // Access the saved signature from state
          if (updatedTemplate.endsWith(savedSignature)) {
            updatedTemplate = updatedTemplate.slice(0, -savedSignature.length);
          }
        }
      }

      return {
        ...prevFormData,
        [name]: checked,
        sequence_template: updatedTemplate,
      };
    });
  };

  const handleVariableClick = (variable, editorType) => {
    if (templateEditorRef.current) {
      templateEditorRef.current.insertVariable(variable.key, editorType);
    }
  };

  const processTemplate = (template = "") => {
    let processedTemplate = template;
    dynamicVariables.forEach((variable) => {
      const regex = new RegExp(variable.key, "g");
      processedTemplate = processedTemplate.replace(regex, variable.value);
    });
    return processedTemplate;
  };

  return (
    <Flex>
      <Box
        flex={1}
        w="auto"
        h="100vh"
        bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
      >
        {" "}
        <Box
          p={5}
          pl={2}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          h="10.7vh"
          borderBottom="1px"
          borderColor="blackAlpha.200"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack>
            <IconButton
              icon={<ArrowBackIcon boxSize={5}/>}
              onClick={handleBackClick}
              size="md"
              bg="none"
              _hover="none"
              aria-label="Go back"
            />
            {!isFromBackend && (
              <Heading fontSize="lg" mb={0}>
                Edit Sequence
              </Heading>
            )}
            {isFromBackend && (
              <VStack spacing={0} align="left">
                <Heading fontSize="lg" mb={1}>
                  {currentCampaign?.campaign_name}
                </Heading>
                <Divider
                  m={0}
                  p={0}
                  orientation="horizontal"
                  borderColor="gray.300"
                />
                <HStack mt={1} justifyContent="left" alignItems="center">
                  <Icon as={EmailIcon} boxSize={5} color="gray.500" />
                  <Text fontSize="xs" color="gray.500" mb={0}>
                    {currentCampaign?.connected_email}
                  </Text>
                </HStack>
              </VStack>
            )}
          </HStack>
          {!isFromBackend && (
            <Button
              size="sm"
              colorScheme="blue"
              onClick={() => {
                // console.log("Save button clicked!");
                handleSaveSequence(formData);
                handleBackClick();
              }}
            >
              Save
            </Button>
          )}
        </Box>
        <Box
          as="section"
          m="2"
          h="86.5vh"
          p={4}
          pt={0}
          pb={0}
          pl={2}
          pr={2}
          bg={colorMode === "dark" ? "gray.700" : "white"}
          borderRadius="lg"
          border="1px"
          borderColor="blackAlpha.200"
        >
          <Flex direction={{ base: "column", md: "row" }} w="100%" h="86.5vh">
            {/* Left Panel: Template and Email Preview */}

            <TemplatePanel
              formData={formData}
              setFormData={setFormData}
              dynamicVariables={dynamicVariables}
              handleVariableClick={handleVariableClick}
              campaignId={campaignId}
              setCampaigns={setCampaigns}
              sequenceCount={sequenceCount}
              processTemplate={processTemplate}
              currentSequenceId={currentSequenceId}
              ref={templateEditorRef}
            />
            <SequenceDetailsPanel
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
              handleCheckboxChange={handleCheckboxChange}
              handleMeetingCheckboxChange={handleMeetingCheckboxChange}
              delayNumber={delayNumber}
              setDelayNumber={setDelayNumber}
              delayUnit={delayUnit}
              setDelayUnit={setDelayUnit}
            />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default EditSequenceFormBox;
