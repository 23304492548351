import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ChatDropDown from "./ChatDropDown";
import Role from "./Role";
import {
  Avatar,
  Box,
  Tooltip,
  Text,
  Flex,
  IconButton,
  useToast,
  Input,
  FormControl,
  FormLabel,
  VStack,
  Spacer,
  Select,
  Icon,
} from "@chakra-ui/react";
import { MdSend } from "react-icons/md";
import { GrClear } from "react-icons/gr";
import { CloseIcon } from "@chakra-ui/icons";
import { BiSolidDownload } from "react-icons/bi";
import { RxAvatar } from "react-icons/rx";
import "../../styles/chat.css";
import Tabs from "../Tabs";
import _ from "lodash"; // for deep cloning
import { saveAs } from "file-saver";
import { logout } from "../../auth";
import DOMPurify from "dompurify";
import OpenaiCredentials from "./OpenaiCredentials";
import { useColorMode } from "@chakra-ui/react";
import usePersistedState from "./../usePersistedState";
import axiosInstance from "../../helpers/axiosInstance";
import { marked } from "marked";
import { FaUser } from "react-icons/fa";
import { FaRobot } from "react-icons/fa";
import { AiOutlineRobot } from "react-icons/ai";

const Chat = () => {
  //console.log("In Chat...");
  const { colorMode } = useColorMode();
  const [messages, setMessages] = useState({});
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");
  const [canSend, setCanSend] = useState(false);
  // const chatboxRef = useRef(null); // This ref is used to manage scrolling in the chat box
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedRole, setSelectedRole] = useState("");
  const [selectedRole, setSelectedRole] = usePersistedState(
    "selectedRoleChat",
    null
  );
  // Initialize selectedModel from localStorage or default to an empty string
  const [selectedModel, setSelectedModel] = useState(() => {
    const savedModel = localStorage.getItem("selectedModel");
    return savedModel ? JSON.parse(savedModel) : "gpt-4-turbo-preview";
  });
  // const [temperature, setTemperature] = useState(() => {
  //   const savedTemp = localStorage.getItem("temperature");
  //   return savedTemp ? JSON.parse(savedTemp).toString() : "0";
  // });

  // useEffect(() => {
  //   localStorage.setItem("temperature", JSON.stringify(temperature));
  // }, [temperature]);

  const [organization, setOrganization] = useState("");
  // const [maxTokens, setMaxTokens] = useState(() => {
  //   const maxToken = localStorage.getItem("maxTokens");
  //   return maxToken ? JSON.parse(maxToken) : 3508;
  // });

  // const [topP, setTopP] = useState(() => {
  //   const top = localStorage.getItem("topP");
  //   return top ? JSON.parse(top) : 1;
  // });

  // const [frequencyPenalty, setFrequencyPenalty] = useState(() => {
  //   const frequency = localStorage.getItem("frequencyPenalty");
  //   return frequency ? JSON.parse(frequency) : 0;
  // });

  // const [presencePenalty, setPresencePenalty] = useState(() => {
  //   const presence = localStorage.getItem("presencePenalty");
  //   return presence ? JSON.parse(presence) : 0;
  // });

  const [deepSearch, setDeepSearch] = useState(() => {
    const search = localStorage.getItem("deepSearch");
    return search ? JSON.parse(search) : true;
  });

  // useEffect(() => {
  //   // Scroll to the bottom of the chatbox when new messages are added
  //   chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
  // }, [messages]);

  useEffect(() => {
    // This will run every time `selectedRole` is updated.
    // If you need to perform any actions after the role is updated,
    // do it here.
    //console.log("Updated selectedRole:", selectedRole);
  }, [selectedRole]);

  const handleSendMessage = (event) => {
    //checking if the message is empty
    if (message.trim() === "") return;

    if (!localStorage.getItem("REACT_TOKEN_AUTH_KEY")) {
      setError("No authorization token found. Please login again.");
      return;
    }

    setIsLoading(true);

    // Clone current state because we're dealing with nested state (avoids direct state mutation)
    let newMessages = _.cloneDeep(messages);

    // If this module doesn't have any messages yet, initialize it with an empty array
    if (!newMessages[selectedModule]) {
      newMessages[selectedModule] = [];
    }

    // Add the new message to the correct module
    newMessages[selectedModule].push({
      user_message: message,
      bot_message: "",
    });

    // Clear the input field
    setMessage("");

    // Update the state
    setMessages(newMessages);

    handleBotResponse(newMessages);
  };

  const handleBotResponse = async (newMessages) => {
    //console.log("selectedRole:", selectedRole);
    const rolePrompt = selectedRole ? selectedRole.role_prompt : null;

    const messageBody = {
      module_name: selectedModule,
      area_name: selectedArea,
      input_data: message,
      module_messages: messages[selectedModule] ?? [], //chat history of selected module
      persona: rolePrompt,
      model: selectedModel,
      temperature: "0",
      // max_tokens: maxTokens,
      // top_p: topP,
      // frequency_penalty: frequencyPenalty,
      // presence_penalty: presencePenalty,
      deep_search: deepSearch,
    };
    //console.log("messageBody", messageBody);
    //console.log("payload", messageBody);
    try {
      // Send a POST request to the server to send a message
      const response = await axiosInstance.post("/genie/chat", messageBody);

      if (response.status !== 200) {
        setError(`Error: ${response.status}`);
        toast({
          title: "Error",
          description: `An error occurred: ${response.status}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      // Add the new bot message to the correct module
      newMessages[selectedModule][
        newMessages[selectedModule].length - 1
      ].bot_message = response.data.bot_message;
      // Add the new bot message to the correct module
      // newMessages[selectedModule][newMessages[selectedModule].length - 1].bot_message = response.data.result[0].bot_message.replace(/- /g, '• ');

      // Store messages in local storage
      localStorage.setItem("chat_messages", JSON.stringify(newMessages));

      // Update the state
      setMessages(newMessages);

      setIsLoading(false);
    } catch (error) {
      //console.log(error);

      handleError(error);
      setIsLoading(false);
    }
  };

  const handleError = (error) => {
    if (error.response && error.response.status === 401) {
      logout(); // assuming logoutAndClear is in scope, this is your logout function that also clears localStorage
      setError("Your session has expired. Please login again.");
      toast({
        title: "Session Expired",
        description: "Your session has expired. Please login again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setError("An error occurred while trying to send a message.");
      toast({
        title: "Error",
        description: "An error occurred while trying to send a message.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  function downloadChat() {
    let textFile = null;
    let data = new Blob([JSON.stringify(messages[selectedModule], null, 2)], {
      type: "text/plain",
    });

    // If we are replacing a previously generated file we need to manually revoke the object URL to avoid memory leaks.
    if (textFile !== null) {
      window.URL.revokeObjectURL(textFile);
    }

    textFile = window.URL.createObjectURL(data);

    // returns a URL you can use as a href
    return textFile;
  }

  useEffect(() => {
    // Try to load messages from local storage
    const storedMessages = localStorage.getItem("chat_messages");
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }
  }, []);

  useEffect(() => {
    setCanSend(!!selectedArea.length && !!selectedModule && !!selectedRole);
  }, [selectedArea, selectedModule, selectedRole]);

  // Convert Markdown to HTML
  const markdownToHtml = (markdown) => {
    return DOMPurify.sanitize(marked(markdown));
  };

  const username = localStorage.getItem("username");

  return (
    //replace gray.700 with #444654
    <Box
      minHeight="calc(100vh - 52px)"
      display="flex"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
    >
      {/* Side 1 */}
      <Box
        flex="2"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        display="flex"
        flexDirection="column"
        mr={0}
        mt={2}
        ml={2}
        mb={2}
        pt="1px"
        pl="1px"
        borderTopRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
      >
        {/* <Box flex="1" bg="white" borderTopRadius="lg" mr={0} mt={2} ml={2} mb={2} pt="1px" pl="1px"> */}
        <Tabs />
        {/* </Box> */}
      </Box>
      {/* Side 2 */}
      <Box flex="6" display="flex" flexDirection="column" ml={2}>
        {/* Top Box */}
        <Box flex="2" display="flex" flexDirection="column">
          {/* First inner box */}
          <Box
            flex="1"
            display="flex"
            alignItems="center"
            justifyContent="start"
            p={2}
            pl={0}
            pb={1}
          >
            <Box
              display="flex"
              alignItems="center"
              bg={colorMode === "dark" ? "gray.700" : "white"}
              width="100%"
              height="100%"
              p={4}
              py={2}
              boxSizing="border-box"
              borderTopRadius="lg"
            >
              <RxAvatar size={30} />
              <Text
                fontSize="md"
                fontWeight="bold"
                ml={2}
                mb={0}
                color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
              >
                Agent
              </Text>
            </Box>
          </Box>
          {/* Second inner box */}
          <Box
            flex="1"
            display="column"
            alignItems="center"
            justifyContent="start"
            pl={0}
            pb={2}
            pr={2}
          >
            <Box
              bg={colorMode === "dark" ? "gray.700" : "white"}
              width="100%"
              height="100%"
              p={4}
              pl={5}
              pb={2}
              pt={2}
              boxSizing="border-box"
              display="column"
              borderBottomRadius="lg"
            >
              <Text
                fontSize="sm"
                fontWeight={"bold"}
                color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
                mb={0}
              >
                About NexusGenie
              </Text>
              <Text
                fontSize="sm"
                color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
                mt={2}
                mb={0}
              >
                NexusGenie is {organization}'s AI Assistant
              </Text>
            </Box>
          </Box>
        </Box>
        {/* Middle Box */}
        <Box
          flex="6"
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          pr={2}
          maxH="calc(57vh - 52px)"
        >
          <Box
            bg={colorMode === "dark" ? "gray.700" : "white"}
            width="100%"
            height="100%"
            boxSizing="border-box"
            borderRadius="lg"
            p={2}
            pl={4}
            pt={4}
            overflowY="scroll"
            scrollBehavior="hidden"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <VStack spacing={4} align="stretch" width="100%">
              {messages[selectedModule] ? (
                messages[selectedModule].map((messageObj, index) => (
                  <VStack
                    key={index}
                    spacing={2}
                    width="100%"
                    alignItems="flex-start"
                  >
                    {messageObj.user_message && (
                      <Box
                        w="100%"
                        p={2}
                        pt={0}
                        borderRadius="md"
                        alignSelf="flex-start"
                        bg={colorMode === "dark" ? "#444654" : "white"} // Adjusted for better contrast
                        boxShadow={
                          colorMode === "dark"
                            ? "0 -2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 6px -1px rgba(0, 0, 0, 0.1)" // Dark mode: top and general shadow
                            : "0 -2px 4px 0 rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.1)" // Light mode: top and general shadow
                        } // Example of adding a shadow
                        overflow="hidden" // Ensures no overflow from content
                      >
                        <Flex alignItems="center" mb={0}>
                          <Icon
                            as={FaUser}
                            mr={2}
                            color={
                              colorMode === "dark"
                                ? "gray.200"
                                : "blackAlpha.800"
                            }
                          />
                          <Text
                            color={
                              colorMode === "dark"
                                ? "gray.200"
                                : "blackAlpha.800"
                            }
                            fontWeight="bold"
                            pt={4}
                          >
                            {username}
                          </Text>
                        </Flex>
                        <Text
                          color={
                            colorMode === "dark" ? "gray.200" : "blackAlpha.800"
                          }
                          mb={0}
                          // fontWeight="bold"
                          fontStyle="italic" // Example of altering text style
                          pl={5}
                        >
                          {messageObj.user_message}
                        </Text>
                      </Box>
                    )}
                    {messageObj.bot_message && (
                      <Box
                        w="100%"
                        p={2}
                        pt={0}
                        borderRadius="md"
                        alignSelf="flex-start"
                        bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
                        overflow="hidden" // Ensures no overflow from content
                      >
                        <Flex alignItems="center" mb={0}>
                          <Avatar
                            size="xs"
                            name="NexusGenie"
                            bg="#5a67d8" // Sets the background color to purple
                            color="white"
                            mr={2}
                          />
                          <Text
                            color={
                              colorMode === "dark"
                                ? "gray.200"
                                : "blackAlpha.800"
                            }
                            fontWeight="bold"
                            pt={4}
                          >
                            NexusGenie
                          </Text>
                        </Flex>
                        <Text
                          color={
                            colorMode === "dark" ? "gray.200" : "blackAlpha.800"
                          }
                          dangerouslySetInnerHTML={{
                            __html: markdownToHtml(messageObj.bot_message),
                          }}
                          pl={6}
                        ></Text>
                      </Box>
                    )}
                  </VStack>
                ))
              ) : (
                <Text
                  //color="gray.500"
                  color={colorMode === "dark" ? "gray.400" : "gray.500"}
                >
                  Start a conversation
                </Text>
              )}
              {isLoading && (
                <Box
                  className="blink"
                  w="100%"
                  p={2}
                  borderRadius="md"
                  alignSelf="flex-start"
                >
                  <Text
                    color={colorMode === "dark" ? "gray.400" : "blackAlpha.800"}
                    // color="blackAlpha.800"
                    mb={0}
                  >
                    Genie is thinking...
                  </Text>
                </Box>
              )}
            </VStack>
          </Box>
        </Box>
        {/* Bottom Box */}
        <Box
          flex="2"
          display="flex"
          flexDirection="column"
          pl={0}
          pt={2}
          pr={2}
        >
          {/* First inner box */}
          <Box
            flex="1"
            bg={colorMode === "dark" ? "gray.700" : "white"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding={2}
            pl={4}
            borderTopRadius="lg"
          >
            <Flex as="form" width="100%" onSubmit={(e) => e.preventDefault()}>
              <Input
                placeholder="Ask Anything"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                disabled={!canSend}
                mr={2}
                // bg="alpha.100"
                mt={1}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage(e);
                  }
                }}
              />
              <Tooltip label="Send message" fontSize="md">
                <IconButton
                  aria-label="Send"
                  icon={<MdSend />}
                  onClick={handleSendMessage}
                  disabled={!canSend}
                  colorScheme="alpha"
                  textColor={colorMode === "dark" ? "white" : "black"}
                  mt={1}
                />
              </Tooltip>
              <Tooltip label="Clear chat" fontSize="md">
                <IconButton
                  aria-label="Send"
                  icon={<GrClear />}
                  colorScheme="alpha"
                  color={colorMode === "dark" ? "white" : "black"}
                  mt={1}
                  onClick={() => {
                    let newMessages = _.cloneDeep(messages);
                    newMessages[selectedModule] = [];
                    setMessages(newMessages);
                    localStorage.setItem(
                      "chat_messages",
                      JSON.stringify(newMessages)
                    );
                  }}
                />
              </Tooltip>
              <Tooltip label="Download" fontSize="md">
                <IconButton
                  aria-label="Send"
                  icon={<BiSolidDownload />}
                  colorScheme="alpha"
                  textColor={colorMode === "dark" ? "white" : "black"}
                  mt={1}
                  onClick={() => {
                    let downloadLink = downloadChat();
                    saveAs(downloadLink, `chat_${selectedModule}.txt`);
                  }}
                />
              </Tooltip>
            </Flex>
          </Box>
          {/* Second inner box */}
          <Box
            flex="1"
            bg={colorMode === "dark" ? "gray.700" : "white"}
            display="flex"
            alignItems="center"
            justifyContent="left"
            padding={2}
            pl={4}
            width="100%"
          >
            <ChatDropDown
              selectedModule={selectedModule}
              setSelectedModule={setSelectedModule}
              selectedArea={selectedArea}
              setSelectedArea={setSelectedArea}
            />
            <Role
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
              selectedModule={selectedModule}
              setSelectedModule={setSelectedModule}
              organization={organization}
              setOrganization={setOrganization}
            />
          </Box>
        </Box>
      </Box>
      <Box flex="2" display="flex" flexDirection="column" pr={2} pb={2} pt={2}>
        <OpenaiCredentials
          // model={model}
          // setModel={setModel}
          // temperature={temperature}
          // setTemperature={setTemperature}
          // maxTokens={maxTokens}
          // setMaxTokens={setMaxTokens}
          // topP={topP}
          // setTopP={setTopP}
          // frequencyPenalty={frequencyPenalty}
          // setFrequencyPenalty={setFrequencyPenalty}
          // presencePenalty={presencePenalty}
          // setPresencePenalty={setPresencePenalty}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          deepSearch={deepSearch}
          setDeepSearch={setDeepSearch}
        />
      </Box>
    </Box>
  );
};

export default Chat;
