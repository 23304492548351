import axiosInstance from "../../../helpers/axiosInstance";

const API_BASE_URL = 'campaign_file_upload';

export const fetchData = async () => {
  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/fetch`);
    //console.log("fetch file leads",response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
