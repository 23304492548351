import { logout } from "../../../auth";
import axiosInstance from "../../../helpers/axiosInstance";
/**
 * Sends agenda points to the backend for minute generation.
 *
 * @param {string} selectedMinutesId
 * @param {array} agendaPoints
 * @param {string} token
 * @param {object|null} specificAgendaPoint
 * @param {number|null} index
 * @returns {object} The response from the backend.
 */
export const sendAgendaPointsToBackend = async (
  selectedMinutesId,
  agendaPoints,
  token,
  specificAgendaPoint = null,
  index = null
) => {
  ////console.log(" In sendAgendaPointsToBackend function");

  // const payload = specificAgendaPoint
  //   ? {
  //       str_id: selectedMinutesId,
  //       minute_master_prompt: [specificAgendaPoint],
  //       index,
  //     }
  //   : {
  //       str_id: selectedMinutesId,
  //       minute_master_prompt: agendaPoints,
  //     };
  const validAgendaPoints = agendaPoints.filter(
    (agenda) => agenda.heading && agenda.heading.trim() !== ""
  );

  const payload = specificAgendaPoint
    ? {
        str_id: selectedMinutesId,
        minute_master_prompt: [specificAgendaPoint],
        index,
      }
    : {
        str_id: selectedMinutesId,
        minute_master_prompt: validAgendaPoints,
      };

  ////console.log("payload", payload);

  try {
    const response = await axiosInstance.post("/minutes/generate-minute", payload);

    if (response.status === 200) {
      return {
        data: response.data,
        status: response.status,
      };
    } else {
      throw new Error("Failed to send agenda points.");
    }
  } catch (error) {
    throw error;
  }
};

/**
 * Fetches a preview of the minute.
 *
 * @param {string} selectedMinutesId
 * @param {number} index
 * @param {string} token
 * @returns {object} The preview response from the backend.
 */
export const handlePreview = async (selectedMinutesId, index = null, token) => {
  ////console.log("In handle Preview function");
  // const payload = {
  //   str_id: selectedMinutesId,
  //   index: index,
  // };
  // Conditionally set the payload based on the presence of the index
  const payload =
    index !== undefined && index !== null
      ? {
          str_id: selectedMinutesId,
          index,
        }
      : {
          str_id: selectedMinutesId,
        };
  try {
    const response = await axiosInstance.post("/minutes/handle-preview", payload);

  // try {
  //   // Mocked dummy response without making an actual API call
  //   const response = {
  //     status: 200,
  //     data: "This is a dummy preview text."
  //   };

    if (response.status === 200) {
      return response.data;
    } else {
      if (response.status === 401) {
        logout();
      }
      throw new Error("Failed to fetch the preview.");
    }
  } catch (error) {
    // Show a toast for "Failed to fetch the preview" error
    throw error;
  }
};