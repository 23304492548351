import React from "react";
import {
  Box,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Circle,
  Divider,
  Tooltip,
  Flex,
} from "@chakra-ui/react";

const SummaryDisplay = ({ summary }) => {
  console.log("Summary",summary);
  const getColorForComplianceLevel = (level) => {
    switch (level) {
      case "green":
        return "green";
      case "yellow":
        return "yellow";
      case "red":
        return "red";
      default:
        return "gray";
    }
  };

  const getHoverText = (color) => {
    const hoverTexts = {
      yellow: "Mild changes required",
      green: "Correct",
      red: "Major changes required",
    };
    return hoverTexts[color] || "No data";
  };

  // Access the sections array from summary.sections
  const groupedSections = summary?.sections?.length
    ? summary.sections.reduce((acc, section) => {
        if (!acc[section.category]) {
          acc[section.category] = [];
        }
        acc[section.category].push(section);
        return acc;
      }, {})
    : {};

  return (
    <Box ml={2} mb={6}>
      <Heading fontSize="xl">{summary?.fund_name || "No Fund Name"}</Heading>
      <Text mb={0}>
        {summary?.marketing_material_name || "No Marketing Material Name"}
      </Text>
      <Divider m="0" mt={4} p="0" />
      {Object.keys(groupedSections).length > 0 ? (
        Object.keys(groupedSections).map((category, index) => (
          <Box key={index} mt={4}>
            <Heading fontSize="lg" mb={2}>
              {category}
            </Heading>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Fund Type</Th>
                  <Th>Summary</Th>
                  <Th>Compliance Level</Th>
                </Tr>
              </Thead>
              <Tbody>
                {groupedSections[category].map((section, idx) => (
                  <Tr key={idx}>
                    <Td>{section.fund_type}</Td>
                    <Td>{section.content}</Td>
                    <Td>
                      <Flex justifyContent="center" alignItems="center">
                        <Tooltip
                          label={getHoverText(section.compliance_level)}
                          hasArrow
                        >
                          <Circle
                            size="20px"
                            bg={getColorForComplianceLevel(
                              section.compliance_level
                            )}
                          />
                        </Tooltip>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        ))
      ) : (
        <Text></Text>
      )}
      <Heading fontSize="lg" mt={6}>
        Report Summary
      </Heading>
      <Text mb={0}>{summary?.summary_paragraph || "No Summary Available"}</Text>
    </Box>
  );
};

export default SummaryDisplay;
