import React, { useEffect, useImperativeHandle, forwardRef } from "react";
import axiosInstance from "../../../../helpers/axiosInstance";
import {
  Box,
  IconButton,
  Heading,
  useToast,
  HStack,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import { EditorMenuBar } from "./EditorMenuBar";
import { SubjectEditorMenuBar } from "./SubjectEditorMenuBar";
import "../../../../styles/tiptap.css";
import { ReactComponent as UpdateIcon } from "../../../../assets/icons/update_template.svg";
import TemplatePanelHeader from "./TemplatePanelHeader";
import ViewTemplateModal from "./ViewTemplateModal";
import MarkdownIt from "markdown-it";

const TemplatePanel = (
  {
    formData,
    setFormData,
    dynamicVariables,
    handleVariableClick,
    campaignId,
    setCampaigns,
    sequenceCount,
    processTemplate,
    currentSequenceId,
  },
  ref
) => {
  useEffect(() => {
    // console.log("Props received in TemplatePanel:", { setFormData, formData });
  }, [setFormData, formData]);
  const { isOpen, onOpen, onClose } = useDisclosure(); // For the preview modal
  const toast = useToast(); // Toast for notifications

  // Configuration for the subject editor
  const subjectEditor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link.configure({
        autolink: true,
        openOnClick: true,
        linkOnPaste: true,
        HTMLAttributes: {
          class: "custom-link",
        },
      }),
      Placeholder.configure({
        placeholder: "Subject...", // Placeholder text for subject editor
        emptyEditorClass: "tiptap-placeholder", // Apply custom class if needed
      }),
    ],
    content: formData.subject || "", // Ensure content is empty if formData.subject is not set
    editable: true,
    autofocus: true,
    onUpdate: ({ editor }) => {
      const content = editor.getText();
      if (content !== formData.subject) {
        setFormData((prev) => ({ ...prev, subject: content }));
      }
    },
  });

  // Configuration for the sequence template editor
  const templateEditor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link.configure({
        autolink: true,
        openOnClick: true,
        linkOnPaste: true,
        HTMLAttributes: {
          class: "custom-link",
        },
      }),
      Placeholder.configure({
        placeholder: "Body...", // Placeholder text for sequence template editor
        emptyEditorClass: "tiptap-placeholder",
      }),
    ],
    content: formData.sequence_template || "", // Ensure content is empty if formData.sequence_template is not set
    editable: true,
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      if (content !== formData.sequence_template) {
        setFormData((prev) => ({ ...prev, sequence_template: content }));
      }
    },
  });

  useEffect(() => {
    if (subjectEditor && templateEditor) {
      subjectEditor.commands.setContent(formData.subject);
      // Update only if sequence_template content changes
      if (templateEditor.getHTML() !== formData.sequence_template) {
        templateEditor.commands.setContent(formData.sequence_template);
      }
    }
  }, [subjectEditor, templateEditor, formData.sequence_template]);
  

  const fetchCampaignDetails = async (id) => {
    // setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/campaign/get-campaign-by-id/${id}`
      );
      // console.log("get campaign by id",response.data);
      if (response.data && response.data.success) {
        const fetchedCampaign = response.data.data;
        const updatedCampaign = {
          ...fetchedCampaign,
          campaign_id: fetchedCampaign._id,
          sequences: fetchedCampaign.sequence_list.map((seq) => ({
            ...seq,
            sequence_insight: seq.sequence_insight || {}, // Ensure sequence_insight exists
          })),
          isFromBackend: true,
        };

        setCampaigns((prevCampaigns) => {
          let updatedCampaigns;
          const existingCampaign = prevCampaigns.find(
            (campaign) => campaign.campaign_id === id
          );
          if (existingCampaign) {
            updatedCampaigns = prevCampaigns.map((campaign) => {
              if (campaign.campaign_id === id) {
                return updatedCampaign;
              }
              return campaign;
            });
          } else {
            updatedCampaigns = [...prevCampaigns, updatedCampaign];
          }

          localStorage.setItem("campaigns", JSON.stringify(updatedCampaigns));
          return updatedCampaigns;
        });
      } else {
        toast({
          title: "Error fetching campaign",
          description: response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching campaign details:", error);
      toast({
        title: "Error",
        description: "An error occurred while fetching campaign details.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      // setLoading(false);
    }
  };

  // Function to handle updating the sequence template
  const handleUpdateTemplate = async () => {
    if (!currentSequenceId) {
      return;
    }

    const payload = {
      new_subject: formData.subject || "",
      new_template: formData.sequence_template || "",
    };
    console.log("payload", payload);
    try {
      const response = await axiosInstance.post(
        `/campaign/update-sequence-template/${campaignId}/${currentSequenceId}`,
        payload
      );
      // console.log("handleUpdateTemplate",response.data);
      if (response.status === 200) {
        toast({
          title: "Sequence Updated",
          description: response.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        fetchCampaignDetails(campaignId);
      } else {
        throw new Error("Failed to update sequence");
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error updating the sequence template.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Error updating sequence template:", error);
    }
  };

  const handleInsertTemplate = (subject, emailBody) => {
    // Split the plain text into lines
    const paragraphs = emailBody.split(/\n/).map((line) => {
      // Wrap each line in <p> tags if it contains text; otherwise, return an empty <p> for blank lines
      return line.trim() ? `<p>${line}</p>` : "<p></p>";
    });

    // Join lines to form the HTML structure
    const formattedEmailBody = paragraphs.join("");

    // Update formData with the new subject and formatted email body as HTML
    setFormData((prevData) => ({
      ...prevData,
      subject: subject || "",
      sequence_template: formattedEmailBody || "",
    }));

    // Update the editor contents directly
    if (subjectEditor) {
      subjectEditor.commands.setContent(subject || "");
    }
    if (templateEditor) {
      templateEditor.commands.setContent(formattedEmailBody || "");
    }
  };

  // Expose methods to parent component through ref
  useImperativeHandle(ref, () => ({
    insertVariable(variableKey, editorType) {
      if (editorType === "subject" && subjectEditor) {
        subjectEditor.chain().focus().insertContent(variableKey).run();
      } else if (editorType === "body" && templateEditor) {
        templateEditor.chain().focus().insertContent(variableKey).run();
      }
    },
    insertSignature(signature) {
      if (templateEditor) {
        // Insert the signature into the editor
        templateEditor.chain().focus().insertContent(signature).run();

        // Also update formData.sequence_template to keep it in sync
        const updatedContent = templateEditor.getHTML();
        setFormData((prevData) => ({
          ...prevData,
          sequence_template: updatedContent,
        }));
      }
    },
  }));

  return (
    <Box
      flex="6"
      p={4}
      h="100%"
      overflowY="auto"
      sx={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
      // borderRightRadius="xl"
      borderRight="1px solid"
      borderRightColor="blackAlpha.300"
      // boxShadow="8px 0 8px rgba(0, 0, 0, 0.3)"
    >
      {/* Template Section */}
      <TemplatePanelHeader
        handleInsertTemplate={handleInsertTemplate}
        formData={formData}
        onOpen={onOpen}
      />

      {/* Subject Editor with Fixed Menu */}
      {!sequenceCount >= 1 && (
        <Box w="full">
          {/* <Heading fontSize="md" fontWeight="semibold">
          Subject
        </Heading> */}
          <Box border="1px solid #e2e8f0" borderRadius="md">
            <SubjectEditorMenuBar
              editor={subjectEditor}
              dynamicVariables={dynamicVariables}
              handleVariableClick={(variable) =>
                handleVariableClick(variable, "subject")
              }
            />
            <EditorContent
              editor={subjectEditor}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                background: "#f5f5f5",
                padding: "8px",
                borderRadius: "4px",
              }}
            />
          </Box>
        </Box>
      )}
      {/* Sequence Template Editor with Fixed Menu */}
      <Box w="full" mt={2}>
        <HStack alignContent="center" justifyContent="flex-end" mb={2}>
          {currentSequenceId && (
            <Tooltip
              p={1}
              label="Update Template"
              bg="White"
              color="black"
              borderRadius="lg"
              boxShadow="lg"
            >
              <IconButton
                icon={<UpdateIcon />}
                size="xs"
                boxSize="4"
                bg="none"
                _hover="none"
                _active="none"
                mr={2}
                onClick={handleUpdateTemplate}
                aria-label="Insert Template"
              />
            </Tooltip>
          )}
        </HStack>

        <Box border="1px solid #e2e8f0" borderRadius="md">
          <EditorMenuBar
            editor={templateEditor}
            dynamicVariables={dynamicVariables}
            handleVariableClick={(variable) =>
              handleVariableClick(variable, "body")
            }
          />
          <EditorContent
            editor={templateEditor}
            style={{
              background: "#f5f5f5",
              height: "50vh",
              padding: "8px",
              borderRadius: "4px",
              overflow: "auto",
              boxSizing: "border-box", // Ensures padding doesn’t add to total height
            }}
          />
        </Box>
      </Box>
      <ViewTemplateModal
        formData={{
          subject: processTemplate(formData.subject),
          sequence_template: processTemplate(formData.sequence_template),
        }}
        sequenceCount={sequenceCount}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  );
};

// Export with forwardRef
export default forwardRef(TemplatePanel);
