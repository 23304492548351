import axiosInstance from "../../../helpers/axiosInstance"; // Adjust the path as necessary

const API_BASE_URL = "/outlook-mail-box"; // Base URL for your APIs

// Fetch all credentials
export const fetchCredentials = async () => {
    try {
      const response = await axiosInstance.get(`${API_BASE_URL}/get-all-credentials`);
      if (response.status === 200) {
        return response.data.data; // Assuming response.data is an array of credentials
      } else {
        throw new Error("Failed to fetch credentials");
      }
    } catch (error) {
      console.error("Error fetching credentials:", error);
      throw error;
    }
  };