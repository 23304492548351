import { useState, useEffect } from 'react';
import axiosInstance from '../../helpers/axiosInstance';
import usePersistedState from "../../components/usePersistedState";

const useCampaignData = (campaignId, isFromBackend, toast) => {
    const [campaigns, setCampaigns] = usePersistedState("campaigns", []);
    const [campaignInsights, setCampaignInsights] = useState(null);
    const [sequencesInsights, setSequencesInsights] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
  
    // Fetch campaign details
    useEffect(() => {
      if (isFromBackend) {
        fetchCampaignDetails(campaignId);
      }
    }, [campaignId, isFromBackend]);
  
    const fetchCampaignDetails = async (id) => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`/campaign/get-campaign-by-id/${id}`);
        // console.log("get campaign by id",response.data);
        if (response.data && response.data.success) {
          const fetchedCampaign = response.data.data;
          const updatedCampaign = {
            ...fetchedCampaign,
            campaign_id: fetchedCampaign._id,
            sequences: fetchedCampaign.sequence_list.map(seq => ({
              ...seq,
              sequence_insight: seq.sequence_insight || {}, // Ensure sequence_insight exists
            })),
            isFromBackend: true,
          };
  
          setCampaigns(prevCampaigns => {
            let updatedCampaigns;
            const existingCampaign = prevCampaigns.find(campaign => campaign.campaign_id === id);
            if (existingCampaign) {
              updatedCampaigns = prevCampaigns.map(campaign => {
                if (campaign.campaign_id === id) {
                  return updatedCampaign;
                }
                return campaign;
              });
            } else {
              updatedCampaigns = [...prevCampaigns, updatedCampaign];
            }
  
            localStorage.setItem('campaigns', JSON.stringify(updatedCampaigns));
            return updatedCampaigns;
          });
        } else {
          toast({
            title: 'Error fetching campaign',
            description: response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Error fetching campaign details:', error);
        toast({
          title: 'Error',
          description: 'An error occurred while fetching campaign details.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };
   
  
    const fetchCampaignInsights = async (id) => {
      try {
        const response = await axiosInstance.get(`/campaign/get-insghts/${id}`);
        if (response.data && response.data.success) {
          // console.log("fetchedSequencesInsights",response.data.data.sequence_insights);
          // console.log("fetchCampaignInsights",response.data.data.campaign_insight);
          const fetchedSequencesInsights = response.data.data.sequence_insights;
          const fetchedCampaignInsights = response.data.data.campaign_insight;
          setCampaignInsights(fetchedCampaignInsights);
          setSequencesInsights(fetchedSequencesInsights);
        
        }
      } catch (error) {
        console.error('Error fetching campaign insights:', error);
      }
    };
  
    useEffect(() => {
      if (campaignId) {
        fetchCampaignInsights(campaignId);
      }
    }, [campaignId]);
    // Fetch campaign insights
    // const fetchCampaignInsights = async (id) => {
    //   try {
    //     const response = await axiosInstance.get(`/campaign/get-insghts/${id}`);
    //     if (response.data && response.data.success) {
    //       // console.log("fetchCampaignInsights",response.data.data);
    //       const fetchedSequencesInsights = response.data.data.sequence_insights;
    //       const fetchedCampaignInsights = response.data.data.campaign_insight;
    //       setCampaignInsights(fetchedCampaignInsights);
  
    //       setCampaigns(prevCampaigns => {
    //         return prevCampaigns.map(campaign => {
    //           if (campaign.campaign_id === id) {
    //             const updatedSequences = campaign.sequences.map(seq => {
    //               const matchingInsight = fetchedSequencesInsights.find(
    //                 insight => insight.sequence_name === seq.sequence_name
    //               );
    //               return matchingInsight
    //                 ? {
    //                     ...seq,
    //                     sequence_insight: {
    //                       ...matchingInsight,
    //                     },
    //                   }
    //                 : seq;
    //             });
    //             return { ...campaign, sequences: updatedSequences };
    //           }
    //           return campaign;
    //         });
    //       });
    //     }
    //   } catch (error) {
    //     console.error('Error fetching campaign insights:', error);
    //   }
    // };
  
    useEffect(() => {
      if (campaignId) {
        fetchCampaignInsights(campaignId);
      }
    }, [campaignId]);
  
    return { campaigns, setCampaigns, campaignInsights, sequencesInsights, loading, error };
  };
  
  export default useCampaignData;
  