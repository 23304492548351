import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Text,
  Flex,
  Checkbox,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  VStack,
  HStack,
  Divider,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SequenceDetailsPanel = ({
  formData,
  setFormData,
  handleChange,
  handleCheckboxChange,
  handleMeetingCheckboxChange,
  delayNumber,
  setDelayNumber,
  delayUnit,
  setDelayUnit,
}) => {
  const handleDelayChange = (valueAsString) => {
    setDelayNumber(valueAsString);
    setFormData((prevFormData) => ({
      ...prevFormData,
      delay_time: `${valueAsString}${delayUnit}`,
    }));
  };

  const handleUnitChange = (e) => {
    const value = e.target.value;
    setDelayUnit(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      delay_time: `${delayNumber}${value}`,
    }));
  };

  return (
    <Box
      flex={5}
      p={4}
      h="100%"
      overflowY="auto"
      sx={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      <HStack spacing={5} alignItems="stretch">
        <FormControl isRequired mb={4} w="35%">
          <FormLabel fontSize="md" fontWeight="bold" mb={0}>
            Sequence name:
          </FormLabel>
          <Text fontSize="xs" fontWeight="semibold">
            Enter the sequence name.
          </Text>
          <Input
            size="xs"
            value={formData.sequence_name}
            onChange={handleChange}
            name="sequence_name"
            placeholder="e.g. Day 1"
            w="80%"
          />
        </FormControl>

        {/* Vertical Divider */}
        <Divider
          orientation="vertical"
          mx={4}
          borderColor="gray.300"
          height="auto"
        />

        {/* When to Start */}
        <FormControl isRequired mb={4} w="60%">
          <FormLabel fontSize="md" fontWeight="bold" mb={0}>
            Sequence Delay Time:
          </FormLabel>
          <Text fontSize="xs" fontWeight="semibold">
            Delay Before starting this sequence.
          </Text>
          <Flex align="center">
            <NumberInput
              isRequired
              size="xs"
              value={delayNumber}
              onChange={handleDelayChange}
              min={0}
              name="delayNumber"
              w="25%"
              style={{ position: "relative" }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Select
              ml={2}
              size="xs"
              value={delayUnit}
              onChange={handleUnitChange}
              name="delayUnit"
              w="auto"
            >
              <option value="m">minutes</option>
              <option value="h">hours</option>
              <option value="d">days</option>
            </Select>
          </Flex>
        </FormControl>
      </HStack>

      {/* Is Active */}
      {/* <FormControl isRequired mb={4}>
        <Flex alignItems="center">
          <Checkbox
            isChecked={formData.is_active}
            onChange={handleChange}
            name="is_active"
          />
          <FormLabel fontSize="sm" ml={2} mb={0}>
            Is Active
          </FormLabel>
        </Flex>
      </FormControl> */}

      {/* Include Signature */}
      <FormControl isRequired mb={4}>
        <Flex alignItems="center">
          <Checkbox
            isChecked={formData.signature}
            onChange={handleCheckboxChange}
            name="signature"
          />
          <FormLabel fontSize="sm" ml={2} mb={0}>
            Include Signature
          </FormLabel>
        </Flex>
      </FormControl>

      {/* Send Team Invite */}
      <FormControl mb={4}>
        <Flex alignItems="center">
          <Checkbox
            isChecked={formData.meeting_invite}
            onChange={handleMeetingCheckboxChange}
            name="meeting_invite"
          />
          <FormLabel fontSize="sm" ml={2} mb={0}>
            Send Team Invite
          </FormLabel>
        </Flex>
      </FormControl>

      {/* Conditionally Render Meeting Fields */}
      {formData.meeting_invite && (
        <VStack spacing={4} align="start">
          <FormControl>
            <FormLabel fontSize="sm">Meeting Name</FormLabel>
            <Input
              size="sm"
              value={formData.meeting_name || ""}
              onChange={handleChange}
              name="meeting_name"
              placeholder="Enter meeting subject"
              w="100%"
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize="sm">Meeting Notes</FormLabel>
            <ReactQuill
              theme="snow"
              value={formData.meeting_note || ""}
              onChange={(value) =>
                handleChange({ target: { name: "meeting_note", value } })
              }
              placeholder="Enter invite notes"
            />
          </FormControl>
        </VStack>
      )}
    </Box>
  );
};

export default SequenceDetailsPanel;
