import axios from 'axios';
import { logout } from "../../auth"; // replace with your actual auth library, if any
import axiosInstance from "../../helpers/axiosInstance";

export const fetchMinuteDetails = async (id, token, handleErrorr) => {
    //console.log("Fetching minute details...");
    // Check if id is null
    if (id == null) {
      //console.log("No minute selected, _id is null");
      return;
    }
    try {
      const response = await axiosInstance.post(
        "/minutes/fetch-minute-by-id",
        { str_id: id }
      );

      //console.log("Minute Details:", response.data);

      if (response.status !== 200) {
        console.error("Error fetching minute details:", response);
        if (response && response.status === 401) {
          logout();
          handleErrorr("Session expired", "Your session has expired. Please login again.");
        }
        return;
      }
      return response.data;
    } catch (error) {
      console.error(error);
      // handle other errors if necessary
    }
};

export const API_ENDPOINT_GENERATE_MINUTE = "/minutes/generate-minute";
export const API_ENDPOINT_PREVIEW = "/your_preview_api_endpoint";
const TOKEN_KEY = "REACT_TOKEN_AUTH_KEY";


// Helper to get authorization headers
const getAuthHeaders = () => {
  const token = localStorage.getItem(TOKEN_KEY)?.replace(/"/g, "");
  return {
    Authorization: `Bearer ${token}`,
    "content-type": "application/json",
  };
};

// Generic API call function
export const apiCall = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};