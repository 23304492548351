import axios from 'axios';
import { logout } from '../auth'; // This should be a function that clears the auth token and logs the user out

const REACT_TOKEN_AUTH_KEY = "REACT_TOKEN_AUTH_KEY";

export const getAuthHeader = () => {
  const token = localStorage.getItem(REACT_TOKEN_AUTH_KEY)?.replace(/"/g, "");
  return { Authorization: `Bearer ${token}` };
};

export const handleUnauthorized = (toast) => {
  console.log("No Session");
  logout();
  toast({
    title: "Session expired",
    description: "Your session has expired. Please login again.",
    status: "error",
    duration: 5000,
    isClosable: true,
  });
};

export const openInNewTab = (url) => {
  window.open(url, "_blank");
};

export const handleError = (toast, title, description) => {
  toast({
    title,
    description,
    status: "error",
    duration: 5000,
    isClosable: true,
  });
};

// Other helper functions can be added here
