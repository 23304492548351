import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { Button, useToast } from "@chakra-ui/react";
import { logout } from "../../auth";
import { useColorMode } from "@chakra-ui/react";
// import '../styles/fileHandlingPage.css';
import axiosInstance from "../../helpers/axiosInstance";

const FileDownload = ({ uploading, selectedMinutesId }) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const handleDownload = async () => {
   // console.log("Downloading function", selectedMinutesId);
    try {
      const response = await axiosInstance.post(
        "/minutes/download",
        { str_id: selectedMinutesId },
        {
          headers: {
            "Content-Type":
              "application/json", // Overrides the default Content-Type
          },
          responseType: "blob", // Set responseType to "blob" to handle binary data
        }
      );

      saveAs(response.data, `output.docx`);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
        toast({
          title: "Session expired",
          description: "Your session has expired. Please login again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error(error);
      }
    }
  };

  return (
    <Button
      _hover={{}}
      bgColor="blue.500"
      textColor="white"
      w="49%"
      onClick={handleDownload}
      isDisabled={uploading}
      mt={2}
      p={3}
    >
      Download
    </Button>
  );
};

export default FileDownload;
