import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

const theme = extendTheme({
  config: {
    initialColorMode: "light", // This is the default value. You can switch to "dark" if you want dark mode by default.
    useSystemColorMode: true, // This will set the color mode based on user's system preference.
  },
  components: {
    Popover: {
      baseStyle: {
        content: {
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>,
  document.getElementById("root")
);
