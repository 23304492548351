import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Stack,
  useToast,
  useColorMode,
  Tooltip,
  Icon,
  Select,
  Label,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { AddIcon } from "@chakra-ui/icons";
import { logout } from "../../auth";
import axiosInstance from "../../helpers/axiosInstance";

const Credentials = () => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [fetchedEnvValue, setFetchedEnvValue] = useState("");
  const [fetchedCloudValue, setFetchedCloudValue] = useState("");
  const [fetchedRegionValue, setFetchedRegionValue] = useState("");
  const [initialCredentials, setInitialCredentials] = useState({
    OPENAI_API: [""], // This is now an array
    Pinecone_API: "",
    Pinecone_Spec: "",
    Pinecone_ENV: "",
    cloud: "",
    region: "",
  });
  //console.log("Credentials", initialCredentials);
  const validateKey = (value) => {
    if (!value) {
      return "Key is required";
    }
  };

  const sendCredentials = async (credentials) => {
    try {
      const response = await axiosInstance.post(
        "/credentials/keys",
        credentials
      );
      // console.log("payload", credentials);
      if (response.status === 200) {
        toast({
          title: "Credentials Created/Updated Successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        handleError(response);
        return;
      }
      //console.log("Credentials saved successfully!");
      const newCreds = await fetchCredentials(); // Fetch the credentials after saving
      setInitialCredentials(
        newCreds || {
          OPENAI_API: "",
          Pinecone_API: "",
          Pinecone_Spec: "",
          Pinecone_ENV: "",
          cloud: "",
          region: "",
        }
      );
    } catch (error) {
      //console.log(error);
      //console.log("An error occurred while trying to save credentials.");
    }
  };

  const fetchCredentials = async () => {
    try {
      const response = await axiosInstance.get("/credentials/keys");

      if (response.status === 200) {
        setFetchedEnvValue(response.data.Pinecone_ENV);
        setFetchedCloudValue(response.data.cloud);
        setFetchedRegionValue(response.data.region);
        // console.log("Response", response.data);
        //console.log("Response", response.data.OPENAI_API);
        //console.log("Response", response.data.Pinecone_API);
        //console.log("Response", response.data.Pinecone_ENV);
        let data = response.data;

        // Convert OPENAI_API to an array if it's not
        if (!Array.isArray(data.OPENAI_API)) {
          data.OPENAI_API = [data.OPENAI_API];
        }

        return data;
      } else {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }

      throw new Error(`Error: ${response.status}`);
    } catch (error) {
      //console.log(
      //"An error occurred while trying to fetch credentials:",
      // error
      // );
      return null;
    }
  };

  useEffect(() => {
    const getAndSetCredentials = async () => {
      const credentials = await fetchCredentials();
      if (credentials) {
        //console.log("Fetched Credentials:", credentials);
        setInitialCredentials((prevState) => {
          const updatedCredentials = {
            OPENAI_API: credentials.OPENAI_API,
            Pinecone_API: credentials.Pinecone_API,
            Pinecone_Spec: credentials.Pinecone_Spec,
            Pinecone_ENV: credentials.Pinecone_ENV,
            cloud: credentials.cloud,
            region: credentials.region,
          };
          //console.log(
          //"Updated initialCredentials inside setter:",
          //updatedCredentials
          //);
          return updatedCredentials;
        });
      }
    };

    getAndSetCredentials();
  }, []);

  const deleteCredentials = async () => {
    //console.log("Delete button clicked");
    try {
      const response = await axiosInstance.delete("/credentials/keys");

      if (response.status === 200) {
        toast({
          title: "Success",
          description: "Credentials deleted successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setFetchedEnvValue("");
        setFetchedCloudValue("");
        setFetchedRegionValue("");
        return true;
      }
    } catch (error) {
      //console.log(
      //  "An error occurred while trying to delete credentials:",
      // error
      // );

      if (error.response && error.response.status) {
        switch (error.response.status) {
          case 401:
            toast({
              title: "Session expired",
              description: "Your session has expired. Please login again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            break;
          case 404:
            toast({
              title: "Error",
              description: "Credentials not found. Enter your credentials.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            break;
          default:
            //console.log(`Unhandled error status: ${error.response.status}`);
            break;
        }
      } else {
        //console.log("An error occurred without a response object.", error);
      }
      return false;
    }
  };

  const handleError = (response) => {
    if (response.status === 401) {
      logout();
      toast({
        title: "Session expired",
        description: "Your session has expired. Please login again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      //console.log("Session expired. Please login again.");
    } else {
      //console.log(`Error: ${response.status}`);
    }
  };

  return (
    <Flex
      height="calc(100vh - 52px)"
      width="full"
      align="center"
      justifyContent="center"
      style={{ overflow: "hidden" }}
    >
      <Box
        borderWidth={1}
        px={4}
        width="full"
        maxWidth="800px"
        borderRadius={4}
        textAlign="center"
        boxShadow="lg"
        overflowY="scroll"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
        maxHeight="90vh"
      >
        <Box p={4}>
          <Formik
            initialValues={initialCredentials}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              sendCredentials(values).then(() => {
                //setIsSubmitted(true); // Set to true here after successful submission
                actions.setSubmitting(false);
              });
            }}
          >
            {(props) => (
              <Form>
                <Stack spacing={4}>
                  {initialCredentials.OPENAI_API.map((key, index) => (
                    <Field
                      name={`OPENAI_API[${index}]`}
                      validate={validateKey}
                      key={index}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.OPENAI_API &&
                            form.touched.OPENAI_API &&
                            form.errors.OPENAI_API[index] &&
                            form.touched.OPENAI_API[index]
                          }
                        >
                          <FormLabel
                            htmlFor={`OPENAI_API[${index}]`}
                            display="flex"
                            alignItems="center"
                          >
                            OPENAI API KEY {index + 1}
                            {/* {index === 0 && ( */}
                            {index ===
                              initialCredentials.OPENAI_API.length - 1 && (
                              <Tooltip
                                label="Add another OPENAI API Key"
                                fontSize="sm"
                                ml={2}
                              >
                                <Box
                                  ml={4}
                                  mb={1}
                                  as="button"
                                  type="button"
                                  onClick={() => {
                                    setInitialCredentials((prev) => ({
                                      ...prev,
                                      OPENAI_API: [...prev.OPENAI_API, ""],
                                    }));
                                  }}
                                >
                                  <Icon as={AddIcon} w={4} h={4} />
                                </Box>
                              </Tooltip>
                            )}
                          </FormLabel>

                          <Input
                            size="md"
                            {...field}
                            id={`OPENAI_API[${index}]`}
                            placeholder="Insert API Key"
                          />
                          <FormErrorMessage>
                            {form.errors.OPENAI_API &&
                              form.errors.OPENAI_API[index]}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  ))}

                  <Field name="Pinecone_API" validate={validateKey}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.Pinecone_API && form.touched.Pinecone_API
                        }
                      >
                        <FormLabel htmlFor="Pinecone_API">
                          PINECONE API KEY
                        </FormLabel>
                        <Input
                          size="md"
                          {...field}
                          id="Pinecone_API"
                          placeholder="Insert Pinecone API Key"
                        />
                        <FormErrorMessage>
                          {form.errors.Pinecone_API}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {/* Add a Select Field for Pinecone Environment Selection */}
                  {/* <FormLabel htmlFor="Pinecone_Spec" mb={0}>
                    SPECIFICATIONS
                  </FormLabel>
                  <Field
                    as={Select}
                    name="Pinecone_Spec"
                    label="Environment"
                    onChange={(e) => {
                      const { value } = e.target;
                      // Set the value of Pinecone_Spec based on the selection
                      props.setFieldValue("Pinecone_Spec", value);

                      // If "Serverless" is selected, set Pinecone_ENV to an empty string
                      if (value === "Serverless") {
                        props.setFieldValue("Pinecone_ENV", "");
                      } else if (value === "Pod") {
                        // Set Pinecone_ENV to the fetched value when "Pod" is selected
                        props.setFieldValue("Pinecone_ENV", fetchedEnvValue);
                      }
                    }}
                  >
                    <option value="">Select Specifications</option>
                    <option value="Serverless">Serverless</option>
                    <option value="Pod">Pod</option>
                  </Field> */}
                  <FormLabel htmlFor="Pinecone_Spec" mb={0}>
                    SPECIFICATIONS
                  </FormLabel>
                  <Field
                    as={Select}
                    name="Pinecone_Spec"
                    label="Environment"
                    onChange={(e) => {
                      const { value } = e.target;
                      // Set the value of Pinecone_Spec based on the selection
                      props.setFieldValue("Pinecone_Spec", value);

                      // If "Serverless" is selected, set Pinecone_ENV to an empty string
                      if (value === "Serverless") {
                        props.setFieldValue("Pinecone_ENV", "");
                        props.setFieldValue("cloud", fetchedCloudValue);
                        props.setFieldValue("region", fetchedRegionValue);
                      } else if (value === "Pod") {
                        // Set Pinecone_ENV to the fetched value when "Pod" is selected
                        props.setFieldValue("Pinecone_ENV", fetchedEnvValue);
                        props.setFieldValue("cloud", "");
                        props.setFieldValue("region", "");
                      }
                    }}
                  >
                    <option value="">Select Specifications</option>
                    <option value="Serverless">Serverless</option>
                    <option value="Pod">Pod</option>
                  </Field>
                  {props.values.Pinecone_Spec === "Serverless" && (
                    <Field name="cloud" validate={validateKey}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.cloud && form.touched.cloud
                          }
                        >
                          <FormLabel htmlFor="cloud">Cloud Host</FormLabel>
                          <Input
                            size="md"
                            {...field}
                            id="cloud"
                            placeholder="Insert Cloud Host"
                          />
                          <FormErrorMessage>
                            {form.errors.cloud}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                  {props.values.Pinecone_Spec === "Serverless" && (
                    <Field name="region" validate={validateKey}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.region && form.touched.region}
                        >
                          <FormLabel htmlFor="region">Region</FormLabel>
                          <Input
                            size="md"
                            {...field}
                            id="region"
                            placeholder="Insert Region"
                          />
                          <FormErrorMessage>
                            {form.errors.region}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}

                  {/* Conditionally Render Pinecone Environment Input */}
                  {props.values.Pinecone_Spec === "Pod" && (
                    <Field name="Pinecone_ENV" validate={validateKey}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.Pinecone_ENV &&
                            form.touched.Pinecone_ENV
                          }
                        >
                          <FormLabel htmlFor="Pinecone_ENV">
                            PINECONE ENVIRONMENT
                          </FormLabel>
                          <Input
                            size="md"
                            {...field}
                            id="Pinecone_ENV"
                            placeholder="Insert Pinecone ENV"
                          />
                          <FormErrorMessage>
                            {form.errors.Pinecone_ENV}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  )}
                  <Box>
                    <Button
                      // size="md"
                      w="49%"
                      // mt={4}
                      _hover={{}}
                      bgColor="blue.500"
                      textColor="white"
                      isLoading={props.isSubmitting}
                      type="submit"
                    >
                      Submit
                    </Button>
                    <Button
                      // size="md"
                      w="49%"
                      mt={0}
                      ml={2}
                      _hover={{}}
                      bgColor="red.500"
                      textColor="white"
                      onClick={async () => {
                        if (await deleteCredentials()) {
                          const newCreds = await fetchCredentials();
                          setInitialCredentials(
                            newCreds || {
                              OPENAI_API: "",
                              Pinecone_API: "",
                              Pinecone_Spec: "",
                              Pinecone_ENV: "",
                              cloud: "",
                              region: "",
                            }
                          );
                          props.resetForm();
                        }
                      }}
                    >
                      Delete Credentials
                    </Button>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Flex>
  );
};
export default Credentials;
