import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  useColorMode,
  IconButton,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
} from "chart.js";
// import SequenceInsights from "./SequenceInsights";

// Register the chart elements
ChartJS.register(ArcElement, ChartTooltip);

const CustomLegend = ({ labels, colors, tooltips }) => {
  return (
    <Box>
      {labels.map((label, index) => (
        <Flex key={index} alignItems="center" mb={0}>
          <Box
            minH="12px"
            minW="12px"
            maxH="12px"
            maxW="12px"
            bg={colors[index]}
            borderRadius="full"
            mr={2}
          />
          <Text fontSize="xs" mr={0} mb={0} w="50%">
            {label}
          </Text>
          <Tooltip
            label={tooltips[index]}
            placement="right"
            aria-label="A tooltip"
          >
            <IconButton
              aria-label="Info"
              icon={<AiOutlineInfoCircle />}
              size="xs"
              variant="ghost"
            />
          </Tooltip>
        </Flex>
      ))}
    </Box>
  );
};

const CampaignInsightsWithChart = ({
  currentCampaign,
  sequence,
  status_red_count,
  status_green_count,
  status_grey_count,
  status_amber_count,
  agreed_for_meeting_count,
  bounced_count,
  ooo_count,
  opened_count,
  replied_count,
  sent_count,
  total_active_email,
  isFromBackend,
}) => {
  const { colorMode } = useColorMode();
  const history = useHistory();
  const selectedEmail=currentCampaign.connected_email;
  const selectedCampaign =currentCampaign.campaign_id;

  const tooltips = [
    "Not a good fit, please stop interaction",
    "Interested to find out more",
    "We do not know yet",
    "Not sure if this is a good fit",
  ];

  const pieData = {
    labels: ["Red", "Green", "Grey", "Amber"],
    datasets: [
      {
        label: "Count #",
        data: [
          status_red_count || 0,
          status_green_count || 0,
          status_grey_count || 0,
          status_amber_count || 0,
        ],
        backgroundColor: ["Red", "Green", "Grey", "#FFBF00"],
        hoverBackgroundColor: ["Red", "Green", "Grey", "#FFBF00"],
      },
    ],
  };

  const allZero = pieData.datasets[0].data.every((value) => value === 0);

  // Placeholder chart data
  const placeholderData = {
    labels: ["No Data"],
    datasets: [
      {
        label: "No Data",
        data: [1],
        backgroundColor: ["lightgray"],
        hoverBackgroundColor: ["lightgray"],
      },
    ],
  };

  const handleColorClick = (color) => {
    history.push(
      `/emails?feedback=${color}&selectedEmail=${encodeURIComponent(
        selectedEmail
      )}&selectedCampaign=${encodeURIComponent(selectedCampaign)}`
    );
  };

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: { display: false },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const clickedElementIndex = elements[0].index;
        const label = pieData.labels[clickedElementIndex];
        handleColorClick(label);
      }
    },
  };

  return (
    <Box
      onClick={(e) => e.stopPropagation()} 
    >
      {isFromBackend && (
        <Flex justifyContent="center" alignItems="center" mt={2} mb={2}>
          <Box
            width={{ base: "100px", md: "100px", lg: "200px" }}
            height="100px"
            display="flex"
          >
            {allZero ? (
              <Pie
                data={placeholderData}
                options={{
                  responsive: true,
                  plugins: { legend: { display: false } },
                }}
              />
            ) : (
              <Pie data={pieData} options={pieOptions} />
            )}
            <Box ml={4} height="100px">
              <CustomLegend
                labels={pieData.labels}
                colors={pieData.datasets[0].backgroundColor}
                tooltips={tooltips}
              />
            </Box>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default CampaignInsightsWithChart;
