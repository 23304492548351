import React, { useState, useEffect } from "react";
import { useAuth } from "../auth";
import { loadCurrentMessage } from "../components/websiteSettings/FetchMessage";
import {
  Box,
  Heading,
  VStack,
  Alert,
  AlertIcon,
  Container,
  Divider,
  useColorMode,
} from "@chakra-ui/react";
import ReactMarkdown from "react-markdown";

const Notifications = () => {
  const [logged] = useAuth();
  const [message, setMessage] = useState("");
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (logged) {
      const fetchMessage = async () => {
        try {
          const data = await loadCurrentMessage();
          setMessage(data);
        } catch (err) {
          console.error("Could not fetch message:", err);
        }
      };

      fetchMessage();
    }
  }, [logged]);

  const renderMessage = (message) => {
    return message.split("\n").map((line, index) => (
      <Box
        key={index}
        textColor={colorMode === "light" ? "black" : "white"} // Apply text color based on color mode
      >
        {line}
        <br />
      </Box>
    ));
  };

  return (
    <Box
      h="calc(100vh - 52px)"
      display="flex"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
      w="100%"
    >
      <Box
        bg={colorMode === "dark" ? "gray.700" : "white"}
        w="100%"
        py={5}
        align="left"
        pl={4}
        mr={2}
        mt={2}
        ml={2}
        mb={2}
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
      >
        <VStack spacing={5} align="center">
          <Box>
            <Heading as="h1" size="xl" mb={3} textAlign="left">
              Upcoming Enhancements
            </Heading>
            <Divider mb={0} />
          </Box>

          {message ? (
            <Box
              bg={colorMode === "light" ? "gray.50" : "gray.700"}
              h="calc(100vh - 52px - 8px - 8px - 8px - 76.19px - 8px - 8px)"
              w="50%"
              p={5}
              borderRadius="lg"
              shadow="md"
              overflow="scroll"
              overflowX="hidden"
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              {/* <ReactMarkdown>{message}</ReactMarkdown> */}
              <Box dangerouslySetInnerHTML={{ __html: message }} />
            </Box>
          ) : (
            <Alert status="info" borderRadius="lg" shadow="md" w="50%">
              <AlertIcon />
              No new notifications.
            </Alert>
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default Notifications;
