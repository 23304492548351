import React from "react";
import {
  useColorMode,
  useToast,
  Flex,
  Box,
  Grid,
  Text,
  Heading,
  Divider,
} from "@chakra-ui/react";
import ConnectBox from "../../components/common/ConnectBox";
import outlookImage from "../../assets/images/outlook.png";
import googleImage from "../../assets/images/google.png";
import {
  getAuthHeader,
  handleUnauthorized,
  openInNewTab,
} from "../../helpers/auth";
import { authApi } from "../../api/apps/authApi";

// Here we have an array of apps which can easily be added to
const appList = [
  {
    name: "Outlook",
    image: outlookImage,
    connectFunction: authApi.getMicrosoftAuthUrl,
  },
  {
    name: "Google",
    image: googleImage,
    connectFunction: authApi.getGoogleAuthUrl,
  },
  // Add more apps here
];

const Apps = () => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const OK = 200;
  const UNAUTHORIZED = 401;

  const handleConnect = async (connectFunction) => {
    try {
      const response = await connectFunction();

      if (response.status === OK) {
        const authUrl = response.data.data.auth_url;
        //console.log("Auth URL: ", authUrl);
        openInNewTab(authUrl);
      } else if (response.status === UNAUTHORIZED) {
        handleUnauthorized(toast);
      }
    } catch (error) {
      if (error.response && error.response.status === UNAUTHORIZED) {
        handleUnauthorized(toast);
      } else {
        console.error(error);
        // Possibly show a generic error toast or handle other status codes
      }
    }
  };

  return (
    <Flex
      h="calc(100vh - 52px)"
      overflow="hidden"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
    >
      <Box
        bg={colorMode === "dark" ? "gray.700" : "white"}
        width="20%"
        height="100%"
        m={3}
        mr={0}
        p={3}
        overflowY="auto"
        borderRadius="lg"
      >
        <Text
          fontSize="md"
          fontWeight="semibold"
          ml={2}
          mb="4px"
          color={colorMode === "dark" ? "gray.200" : "#1A202C"}
        >
          Apps
        </Text>
        <Heading
          fontSize="xs"
          color={colorMode === "dark" ? "gray.400" : "blackAlpha.700"}
          mb={0}
          pl={2}
          pb={2}
        >
          Connect to attach your Outlook account.
        </Heading>
        <Divider mt={0} mb={0} borderColor="gray.200" />
      </Box>

      <Grid
        templateColumns={[
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(3, 1fr)",
          "repeat(4, 1fr)",
        ]}
        templateRows="repeat(3, 1fr)"
        gap={3}
        width="80%"
        height="100%"
        p={3}
        bg="none"
        overflowY="auto"
      >
        {appList.map((app, index) => (
          <Box
            key={index}
            // bg="none"
            height="100%"
            overflowY="auto"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg={colorMode === "dark" ? "gray.700" : "white"}
            borderRadius="lg"
          >
            <ConnectBox
              imageSrc={app.image}
              altText={`${app.name} Icon`}
              platformName={app.name}
              handleConnect={() => handleConnect(app.connectFunction)}
            />
          </Box>
        ))}
      </Grid>
    </Flex>
  );
};

export default Apps;








// import React, { useState } from "react";
// import axios from "axios";
// import { logout } from "../../auth";
// import {
//   Box,
//   Text,
//   Flex,
//   Grid,
//   Image,
//   Heading,
//   Divider,
//   Button,
//   useToast,
//   Stack,
// } from "@chakra-ui/react";
// import outlookImage from "../../assets/outlook.png";
// import googleImage from "../../assets/google.png";
// import { useColorMode } from "@chakra-ui/react";

// const Apps = () => {
//   const { colorMode } = useColorMode();
//   const toast = useToast();

//   // Constants
//   const REACT_TOKEN_AUTH_KEY = "REACT_TOKEN_AUTH_KEY";
//   const API_ENDPOINT = "app/get-microsoft-authentication-url";
//   const UNAUTHORIZED = 401;
//   const OK = 200;

//   // Helper function to get authorization header
//   const getAuthHeader = () => {
//     const token = localStorage.getItem(REACT_TOKEN_AUTH_KEY)?.replace(/"/g, "");
//     return { Authorization: `Bearer ${token}` };
//   };

//   // Helper function to handle unauthorized scenario
//   const handleUnauthorized = () => {
//     console.log("No Session");
//     logout();
//     toast({
//       title: "Session expired",
//       description: "Your session has expired. Please login again.",
//       status: "error",
//       duration: 5000,
//       isClosable: true,
//     });
//   };

//   // Main function
//   const handleConnect = async (cardIndex) => {
//     try {
//       const response = await axios.get(API_ENDPOINT, {
//         headers: getAuthHeader(),
//       });

//       if (response.status === OK) {
//         const authUrl = response.data.data.auth_url;

//         // Check for unauthorized status
//         if (response.status === UNAUTHORIZED) {
//           handleUnauthorized();
//         } else {
//           console.log("Auth URL: ", authUrl);
//           openInNewTab(authUrl);
//         }
//       }
//     } catch (error) {
//       // Handle specific error scenarios based on the error response
//       if (error.response && error.response.status === UNAUTHORIZED) {
//         handleUnauthorized();
//       } else {
//         console.error(error);
//         // Possibly show a generic error toast or handle other status codes
//       }
//     }
//   };

//   // Helper function to open URL in a new tab
//   const openInNewTab = (url) => {
//     window.open(url, "_blank");
//   };

//   const ConnectBox = ({ imageSrc, altText, platformName, handleConnect }) => (
//     <Box
//       w="80%"
//       h="100%"
//       overflow="hidden"
//       m={0}
//       bg={colorMode === "dark" ? "gray.700" : "white"}
//     >
//       <Flex direction="column" alignItems="center" h="100%" p={3} m={0}>
//         <Image
//           src={imageSrc}
//           alt={altText}
//           borderRadius="lg"
//           boxSize="70px"
//           objectFit="cover"
//         />
//         <Heading mt={2} mb={0} size="xs">
//           {platformName}
//         </Heading>
//         <Divider mt={2} />
//         <Stack spacing={2} direction="row" justify="center">
//           <Button
//             mt={2}
//             mr={2}
//             size="sm"
//             variant="solid"
//             onClick={handleConnect}
//             isDisabled={platformName === "Google"}
//           >
//             Connect
//           </Button>
//         </Stack>
//       </Flex>
//     </Box>
//   );
//   return (
// <Flex
//   height="92vh"
//   overflow="hidden"
//   bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
// >
//   <Box
//     bg={colorMode === "dark" ? "gray.700" : "white"}
//     width="20%"
//     height="100%"
//     m={3}
//     mr={0}
//     p={3}
//     overflowY="auto"
//     borderRadius="lg"
//   >
//     {/* Heading 'Apps' */}
//     {/* <Heading
//       size="md"
//       mb={1}
//       pl={3}
//       fontWeight="bold"
//       color={colorMode === "dark" ? "blue.300" : "blue.700"}
//       textTransform="uppercase"
//     >
//       Apps
//     </Heading>
//     <Divider
//       mt={2}
//       mb={4}
//       borderColor={colorMode === "dark" ? "gray.500" : "gray.200"}
//     /> */}
//     <Text
//       fontSize="md"
//       fontWeight="semibold"
//       ml={2}
//       mb="4px"
//       color={colorMode === "dark" ? "gray.200" : "#1A202C"}
//     >
//       Apps
//     </Text>
//     <Heading
//       fontSize="xs"
//       color={colorMode === "dark" ? "gray.400" : "blackAlpha.700"}
//       mb={0}
//       pl={2}
//       pb={2}
//     >
//       Connect to attach your Outlook account.
//     </Heading>
//     <Divider mt={0} mb={0} borderColor="gray.200" />
//   </Box>

//   <Grid
//     templateColumns={[
//       "repeat(1, 1fr)",
//       "repeat(2, 1fr)",
//       "repeat(3, 1fr)",
//       "repeat(4, 1fr)",
//     ]}
//     templateRows="repeat(3, 1fr)"
//     gap={3}
//     width="80%"
//     height="100%"
//     p={3}
//     bg="none"
//     overflowY="auto"
//   >
//     {[outlookImage, googleImage].map((image, index) => (
//       <Box
//         key={index}
//         // bg="none"
//         height="100%"
//         overflowY="auto"
//         display="flex"
//         alignItems="center"
//         justifyContent="center"
//         bg={colorMode === "dark" ? "gray.700" : "white"}
//         borderRadius="lg"
//       >
//         <ConnectBox
//           imageSrc={image}
//           altText={index === 0 ? "Outlook Icon" : "Google Logo"}
//           platformName={index === 0 ? "Outlook" : "Google"}
//           handleConnect={handleConnect}
//         />
//       </Box>
//     ))}
//   </Grid>
// </Flex>
//   );
// };
// export default Apps;
