import axios from 'axios';
import { logout } from '../auth'; // Import the logout function
import getToken from './authToken';

const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json', // Default Content-Type header
        // You can add more default headers here
    },
    // Any other default settings
});


axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) { // Check for unauthorized status
            
            logout(); // Logout if token is expired or invalid
            window.location = '/login'; // Redirect to login page
        }
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.request.use(config => {
    const token = getToken();
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

export default axiosInstance;
