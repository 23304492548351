import React, { useEffect, useState } from "react";
import { useColorMode, Box, Text, Heading, Divider } from "@chakra-ui/react";
import usePersistedState from "../../components/usePersistedState";
import ModulesDropdown from "../../components/common/ModulesDropdown";
import RoleDropdown from "../../components/common/RoleDropdown";
import Upload from "../../components/DDQ_RFP/Upload";
import Questions from "../../components/DDQ_RFP/Questions";
import DDQ_RFP_minute from "../../components/DDQ_RFP/DDQ_RFP_minute";
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const DDQ_RFP = () => {
  const { colorMode } = useColorMode();
  const [outputText, setOutputText] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [selectedModule, setSelectedModule] = usePersistedState(
    "selectedModuleDDQ_RFP",
    ""
  );
  const [minutesList, setMinutesList] = usePersistedState(
    "minutesListUploadDDQ_RFP",
    []
  );
  const [selectedMinute, setSelectedMinute] = usePersistedState(
    "selectedMinuteDDQ_RFP",
    null
  );

  const [selectedRole, setSelectedRole] = usePersistedState(
    "selectedRoleDDQ_RFP",
    null
  );
  const [agendaPoints, setAgendaPoints] = usePersistedState(
    "agendaPointsDDQ_RFP",
    []
  );
  const [fetchedMinutes, setFetchedMinutes] = useState([]);
  // console.log("DDQ_RFP selectedRole",selectedRole);
  // console.log("DDQ_RFP selectedModule",selectedModule);
  // console.log("DDQ_RFP selectedMinutesId", selectedMinute?._id);

  const SafeHtmlContent = ({ markdownContent }) => {
    // Convert markdown to HTML
    const htmlContent = marked(markdownContent);
    // Sanitize the HTML content
    const sanitizedHtml = DOMPurify.sanitize(htmlContent);
  
    return (
      <Box dangerouslySetInnerHTML={{ __html: sanitizedHtml }} 
      //style={{ whiteSpace: "pre-line" }} 

      />
    );
  };


  return (
    <Box
      display={{ base: "block", md: "flex" }}
      width="100%"
      height="calc(100vh - 52px)"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
    >
      <Box
        w={{ base: "100%", md: "20%" }} // Responsive width
        bg={colorMode === "dark" ? "gray.700" : "white"}
        // mr={2}
        mt={2}
        ml={2}
        mb={2}
        // p={4} // Padding inside the box
        border="1px"
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"} // Slightly lighter border for visibility
      >
        <DDQ_RFP_minute
          selectedMinutesId={selectedMinute?._id}
          setSelectedMinute={setSelectedMinute}
          selectedMinute={selectedMinute}
          fetchedMinutes={fetchedMinutes}
          setFetchedMinutes={setFetchedMinutes}
          minutesList={minutesList}
          setMinutesList={setMinutesList}
          agendaPoints={agendaPoints}
          setAgendaPoints={setAgendaPoints}
        />
      </Box>

      <Box
        w={{ base: "100%", md: "20%" }} // Responsive width
        bg={colorMode === "dark" ? "gray.700" : "white"}
        mr={2}
        mt={2}
        ml={2}
        mb={2}
        p={4} // Padding inside the box
        border="1px"
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"} // Slightly lighter border for visibility
        // boxShadow={colorMode === "light" ? "sm" : "none"} // Shadow in light mode
      >
        <Text
          fontSize="md"
          fontWeight="semibold"
          ml={2}
          mb="4px"
          color={colorMode === "dark" ? "gray.200" : "#1A202C"}
        >
          Select Agent
        </Text>
        <ModulesDropdown
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
        />
        <Box my={4}></Box> {/* Spacer between dropdowns */}
        <Text
          fontSize="md"
          fontWeight="semibold"
          ml={2}
          mb="4px"
          color={colorMode === "dark" ? "gray.200" : "#1A202C"}
        >
          Select Persona
        </Text>
        <RoleDropdown
          selectedModule={selectedModule}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          size="sm"
        />
        <Box my={4}></Box> {/* Spacer between dropdowns */}
        <Upload
          selectedModule={selectedModule}
          selectedRole={selectedRole}
          selectedMinute={selectedMinute}
          setSelectedMinute={setSelectedMinute}
          fetchedMinutes={fetchedMinutes}
          setFetchedMinutes={setFetchedMinutes}
          minutesList={minutesList}
          setMinutesList={setMinutesList}
        />
      </Box>

      {/* Middle Box */}
      <Questions
        outputText={outputText}
        setOutputText={setOutputText}
        modalHeader={modalHeader}
        setModalHeader={setModalHeader}
        selectedMinutesId={selectedMinute?._id}
        setSelectedMinute={setSelectedMinute}
        setFetchedMinutes={setFetchedMinutes}
        agendaPoints={agendaPoints}
        setAgendaPoints={setAgendaPoints}
      />

      <Box
        width={{ base: "100%", md: "25%" }}
        bg={colorMode === "dark" ? "gray.700" : "white"}
        p={2}
        m={2}
        ml={0}
        border="1px"
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
      >
        <Text
          fontSize="md"
          fontWeight="semibold"
          ml={2}
          mt={2}
          top="20px"
          mb="4px"
          color={colorMode === "dark" ? "white" : "#1A202C"}
        >
          Preview
        </Text>

        <Heading
          fontSize="xs"
          color={colorMode === "dark" ? "white" : "blackAlpha.700"}
          mb={0}
          pl={2}
          pb={2}
        >
          Preview Document
        </Heading>

        <Divider mt={0} mb={2} borderColor="#E2E8F0" />
        <Box
          mb={4}
          overflowY="auto" // Enable scrolling for this Box
          maxH="calc(100vh - 158px)"
          sx={{
            "&::-webkit-scrollbar": {
              display: "block",
              width: "6px",
            },
            msOverflowStyle: "none", // Hide scrollbar for IE 11
            scrollbarWidth: "thin", // Scrollbar width for Firefox
          }}
        >
          {(modalHeader || outputText) && (
            <>
              {modalHeader && (
                <Text
                  ml={2}
                  mb={2}
                  fontWeight="bold"
                  color={colorMode === "dark" ? "white" : "black"}
                >
                  {modalHeader}
                </Text>
              )}
              <Divider mt={0} mb={0} borderColor="#E2E8F0" />
              {outputText && (
                
                <SafeHtmlContent markdownContent={outputText} />
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DDQ_RFP;
