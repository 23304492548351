import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { login } from "../auth";
import { useUser } from "./userRole/UserContext";

const TwoFactorAuthPage = () => {
  const { user, setUser } = useUser();
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
  const [twoFACode, setTwoFACode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [timer, setTimer] = useState(120); // 2-minute countdown in seconds
  const toast = useToast();
  const history = useHistory();
  const location = useLocation();

  // Extract the email passed from the LoginPage
  const email = location.state?.email || "";

  useEffect(() => {
    if (!email) {
      history.push("/login");
    }

    // Start countdown when the component mounts
    const interval = setInterval(() => {
      setTimer((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [email, history]);

  // Format the timer to display minutes and seconds
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const verify2FA = async () => {
    setIsVerifying(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, code: twoFACode }),
    };

    try {
      const response = await fetch("/auth/verify-2fa", requestOptions);
      const result = await response.json();

      if (response.status === 200) {
        // Save tokens and redirect to the homepage
        login(result); // Save access_token and refresh_token
        localStorage.setItem("email", result.email);
        localStorage.setItem("username", result.username);
        const role = result.role;
        const organization = result.organization;
        const moduleAccess = result.moduleAccess;
        setUser({
          role,
          organization,
          moduleAccess,
        });
        history.push("/"); // Redirect to homepage after successful 2FA
        setIsLoginSuccessful(true);
        toast({
          title: "Login successful",
          description: "You are now logged in.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "2FA verification failed",
          description:
            result.message || "The 2FA code is invalid or has expired.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to connect to the server.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsVerifying(false);
  };

  return (
    <Container
      maxW="lg"
      py={{ base: "10", md: "20" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="4">
        <Heading textAlign="center" size="lg">
          Enter 2FA Code
        </Heading>
        <Text color="gray.500" textAlign="center">
          Please enter the 2FA code sent to your email.
        </Text>
        {/* Display the countdown timer */}
        <Text color={timer > 0 ? "black" : "red"} textAlign="center">
          Time remaining: {formatTime(timer)}
        </Text>

        <Box
          py={{ base: "0", sm: "4" }}
          px={{ base: "4", sm: "10" }}
          boxShadow="sm"
        >
          <Stack spacing="6">
            <FormControl>
              <FormLabel htmlFor="twoFACode">2FA Code</FormLabel>
              <Input
                id="twoFACode"
                type="text"
                placeholder="Enter 2FA code"
                value={twoFACode}
                onChange={(e) => setTwoFACode(e.target.value)}
                isDisabled={timer === 0} // Disable input if timer expires
              />
            </FormControl>
            <Button onClick={verify2FA} isLoading={isVerifying} isDisabled={timer === 0}>
              Verify 2FA Code
            </Button>
            {/* Show message if timer expires */}
            {timer === 0 && (
              <Text color="red" textAlign="center">
                Your code has expired. Please go back to login.
              </Text>
            )}
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};

export default TwoFactorAuthPage;
