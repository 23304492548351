import React, { useState, useEffect } from "react";
import {
  Text,
  Flex,
  VStack,
  Progress,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tooltip,
} from "@chakra-ui/react";
import axiosInstance from "../../../../helpers/axiosInstance";
import {
  convertHtmlToPlainText,
  modifySequenceTemplate,
} from "../../../../utils/helpers";

const EmailStatsPanel = ({
  campaignId,
  emailSubject,
  emailBody,
  isModalOpen,
  setIsModalOpen,
}) => {
  const [stats, setStats] = useState(null);
  const [isStatsFetched, setIsStatsFetched] = useState(false);

  // Fetch stats function
  const fetchStats = async () => {
    try {
      // Prepare the payload based on whether emailSubject is present
      const payload = emailSubject
        ? {
            email_subject: convertHtmlToPlainText(emailSubject),
            email_body: modifySequenceTemplate(emailBody || ""),
          }
        : {
            email_body: modifySequenceTemplate(emailBody || ""),
          };

      // Make the API request with the conditional payload
      const response = await axiosInstance.post(
        "/template-insight/check-insight",
        payload
      );

      if (response.data) {
        setStats(response.data);
        setIsStatsFetched(true);
      }
    } catch (error) {
      console.error("Error fetching email stats:", error);
    }
  };

  // Trigger fetchStats when modal opens
  useEffect(() => {
    if (isModalOpen) {
      fetchStats();
    }
  }, [isModalOpen]);

  const closeModal = () => {
    setIsModalOpen(false);
    setStats(null); // Reset stats when closing modal, if needed
    setIsStatsFetched(false); // Reset fetch flag on close
  };

  // Render each stat item, with conditions for `expected` and `range` types
  const renderStat = (key, stat) => {
    const { value, minimum_value, maximum_value, detail, expected } = stat;

    let isInRange = false;
    let progressValue = 0;

    // Determine the progress value based on the type of `value`
    if (typeof value === "boolean") {
      // Full bar for true, empty bar for false
      progressValue = value ? 100 : 0;
      isInRange = value;
    } else if (minimum_value !== undefined && maximum_value !== undefined) {
      progressValue =
        ((value - minimum_value) / (maximum_value - minimum_value)) * 100;
      isInRange = value >= minimum_value && value <= maximum_value;
    } else if (expected !== undefined) {
      isInRange = value === expected;
    }

    return (
      <Flex direction="column" w="100%" key={key} py={2}>
        <Flex align="center" mb={1}>
          <Tooltip
            label={detail}
            p={2}
            fontSize="md"
            bg="white"
            color="black"
            border="1px solid"
            borderColor="blackAlpha.200"
            borderRadius="lg"
            boxShadow="lg"
          >
            <Text
              fontSize="sm"
              fontFamily="Helvetica, Arial, sans-serif"
              mr={2}
              mb={0}
            >
              {key.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase())}:{" "}
              <Text as="span" fontWeight="bold" mb={0}>
              {typeof value === "number" ? parseFloat(value.toFixed(2)) : String(value)}

              </Text>
            </Text>
          </Tooltip>

          {/* Display emoji based on the range check */}
          <Text ml="auto" fontSize="lg" mb={0}>
            {isInRange ? "🙂" : "🙁"}
          </Text>
        </Flex>

        {/* Show progress bar below text and emoji */}
        <Progress
          value={progressValue}
          height="12px" // Makes the bar thicker
          borderRadius="full" // Rounds the edges
          colorScheme={isInRange ? "blue" : "red"}
          bg="blackAlpha.300" // Light background for unfilled portion
        />
      </Flex>
    );
  };

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal} size="xl">
      <ModalOverlay />
      <ModalContent h="78vh" pb={4}>
        <ModalHeader>Email Content Insights</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          {isStatsFetched && stats ? (
            <VStack spacing={4}>
              {Object.keys(stats).map((key) => renderStat(key, stats[key]))}
            </VStack>
          ) : (
            <Text>Checking insights...</Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EmailStatsPanel;
