import React from "react";
import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Text,
  Flex,
} from "@chakra-ui/react";
import {
  FiBold,
  FiItalic,
  FiUnderline,
  FiLink,
  FiList,
  FiCode,
} from "react-icons/fi";
import { AiOutlineFontSize, AiOutlineOrderedList } from "react-icons/ai"; // Import ordered list icon
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  RiStrikethrough,
  RiEraserLine,
  RiCodeSSlashLine,
} from "react-icons/ri";
import { FaCaretDown } from "react-icons/fa";

export const EditorMenuBar = ({
  editor,
  dynamicVariables,
  handleVariableClick,
}) => {
  if (!editor) {
    return null;
  }

  const handleSetLink = () => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("Enter the URL", previousUrl || "https://");

    if (url === null) {
      // Do nothing if user cancels the prompt
      return;
    }

    if (url === "") {
      // Remove the link if the input is empty
      editor.chain().focus().unsetLink().run();
    } else {
      // Set the link with the provided URL
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    }
  };

  return (
    <HStack spacing={2} borderRadius="md" boxShadow="sm">
      {/* Paragraph Style Menu */}
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<AiOutlineFontSize />}
          variant="ghost"
          aria-label="Paragraph Style"
          color="purple.600"
          _hover={{ bg: "gray.200" }}
          _active={{ bg: "gray.300" }}
        >
          <ChevronDownIcon />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => editor.chain().focus().setParagraph().run()}>
            Paragraph
          </MenuItem>
          <MenuItem
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
          >
            Heading 1
          </MenuItem>
          <MenuItem
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
          >
            Heading 2
          </MenuItem>
          <MenuItem
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
          >
            Heading 3
          </MenuItem>
        </MenuList>
      </Menu>

      {/* Bold, Italic, Underline */}
      <IconButton
        icon={<FiBold />}
        onClick={() => editor.chain().focus().toggleBold().run()}
        isActive={editor.isActive("bold")}
        aria-label="Bold"
        variant="ghost"
        color="purple.600"
        _hover={{ bg: "gray.200" }}
        _active={{ bg: "gray.300" }}
      />
      <IconButton
        icon={<FiItalic />}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        isActive={editor.isActive("italic")}
        aria-label="Italic"
        variant="ghost"
        color="purple.600"
        _hover={{ bg: "gray.200" }}
        _active={{ bg: "gray.300" }}
      />
      <IconButton
        icon={<FiUnderline />}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        isActive={editor.isActive("underline")}
        aria-label="Underline"
        variant="ghost"
        color="purple.600"
        _hover={{ bg: "gray.200" }}
        _active={{ bg: "gray.300" }}
      />

      <IconButton
        icon={<RiStrikethrough />}
        onClick={() => editor.chain().focus().toggleStrike().run()}
        isActive={editor.isActive("strike")}
        aria-label="Strikethrough"
        variant="ghost"
        color="purple.600"
        _hover={{ bg: "gray.200" }}
        _active={{ bg: "gray.300" }}
      />
      <IconButton
        icon={<RiEraserLine />}
        onClick={() => editor.chain().focus().unsetAllMarks().run()}
        aria-label="Clear Formatting"
        variant="ghost"
        color="purple.600"
        _hover={{ bg: "gray.200" }}
        _active={{ bg: "gray.300" }}
      />
      {/* Code */}
      <IconButton
        icon={<RiCodeSSlashLine />}
        onClick={() => editor.chain().focus().toggleCode().run()}
        isActive={editor.isActive("code")}
        aria-label="Code"
        variant="ghost"
        color="purple.600"
        _hover={{ bg: "gray.200" }}
        _active={{ bg: "gray.300" }}
      />

      {/* Bullet List, Ordered List */}
      <IconButton
        icon={<FiList />}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        isActive={editor.isActive("bulletList")}
        aria-label="Bullet List"
        variant="ghost"
        color="purple.600"
        _hover={{ bg: "gray.200" }}
        _active={{ bg: "gray.300" }}
      />
      <IconButton
        icon={<AiOutlineOrderedList />}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        isActive={editor.isActive("orderedList")}
        aria-label="Ordered List"
        variant="ghost"
        color="purple.600"
        _hover={{ bg: "gray.200" }}
        _active={{ bg: "gray.300" }}
      />

      {/* Link */}
      <IconButton
        icon={<FiLink />}
        onClick={handleSetLink}
        isActive={editor.isActive("link")}
        aria-label="Link"
        variant="ghost"
        color="purple.600"
        _hover={{ bg: "gray.200" }}
        _active={{ bg: "gray.300" }}
      />
      <Popover placement="bottom">
        <PopoverTrigger>
          <Flex p={1} alignItems="center">
            <Text mb={0} mr={1} cursor="pointer" color="blue">
              Variables
            </Text>
            <FaCaretDown color="blue" style={{ cursor: "pointer" }} />
          </Flex>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Dynamic Variables</PopoverHeader>
          <PopoverBody h="50vh" overflowY="scroll">
            {dynamicVariables.map((variable, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                p={2}
                cursor="pointer"
                onClick={() => handleVariableClick(variable)}
              >
                <Text fontWeight="bold" fontSize="xs" mr={2} w="50%">
                  {variable.key}
                </Text>
                <Text fontSize="xs" w="50%">
                  {variable.value}
                </Text>
              </Box>
            ))}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </HStack>
  );
};
