import axiosInstance from '../../helpers/axiosInstance';

export const loadCurrentMessage = async () => {
 
  try {
    const response = await axiosInstance.get("/website_settings/currentMessage");

    if (response.status === 200) {
      return response.data.message;
    }
  } catch (error) {
    throw new Error("Error fetching the current message");
  }
};