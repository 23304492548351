import React, { useState,useEffect } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useColorMode
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { Logo } from "./Logo";
import axiosInstance from "../helpers/axiosInstance";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const toast = useToast();
  const { colorMode } = useColorMode();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axiosInstance.post("/auth/forgot_password", { email });

      // Show a success toast
      toast({
        title: "Reset Password",
        description: "Please check your email for a password reset link.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);

      // Show an error toast
      toast({
        title: "An error occurred.",
        description:
          "An error occurred while trying to send the reset link. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

   // Function to determine the boxShadow based on colorMode and screen size
   const boxShadow = () => ({
    base: "none",
    sm:
      colorMode === "light"
        ? "0px 4px 6px -1px rgba(0, 0, 0, 0.15)"
        : "0px 4px 6px -1px rgba(255, 255, 255, 0.2)",
  });

  const bgColor = useColorModeValue("white", "#171923");

  useEffect(() => {
    document.body.style.backgroundColor = bgColor;
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.backgroundColor = ""; // Reset to default
      document.body.style.overflow = "unset";
    };
  }, [bgColor]);

  return (
    <Container
      maxW="lg"
      py={{
        base: "10",
        md: "20",
      }}
      px={{
        base: "0",
        sm: "8",
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Logo />
          <Heading
            size={{
              base: "xs",
              md: "30px",
            }}
            textAlign="center"
          >
            Forgot Password
          </Heading>
        </Stack>
        <Box
          py={{
            base: "0",
            sm: "8",
          }}
          px={{
            base: "4",
            sm: "10",
          }}
          bg={{
            base: "transparent",
            sm: "bg.surface",
          }}
          // boxShadow={{
          //   base: "none",
          //   sm: "md",
          // }}
          boxShadow={boxShadow()}
          borderRadius={{
            base: "none",
            sm: "xl",
          }}
        >
          <Box as="form" onSubmit={handleSubmit}>
            <Stack spacing="6">
              <FormControl>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormControl>
              <Button type="submit"> Reset Password</Button>
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Container>
  );
};

export default ForgotPassword;
