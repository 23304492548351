import React, { useState, useEffect } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Select,
  VStack,
  Text,
  Box,
} from "@chakra-ui/react";
import axiosInstance from "../../../../helpers/axiosInstance";

const EmailTemplatesDrawer = ({ isOpen, onClose, onInsertTemplate }) => {
  const [templates, setTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [selectedOutreach, setSelectedOutreach] = useState("");

  // Fetch templates from the endpoint
  const fetchTemplates = async () => {
    try {
      const response = await axiosInstance.get("/template/fetch-email-template");
      console.log("Templates fetched", response.data);
      if (response.data) {
        setTemplates(response.data);
        setFilteredTemplates(response.data); // Set initial filtered templates
      }
    } catch (error) {
      console.error("Error fetching email templates:", error);
    }
  };

  // Call fetchTemplates when the drawer is opened
  useEffect(() => {
    if (isOpen) {
      fetchTemplates();
    }
  }, [isOpen]);

  // Handle outreach filter change
  const handleFilterChange = (event) => {
    const selected = event.target.value;
    setSelectedOutreach(selected);
    if (selected) {
      const filtered = templates.filter((template) => template.outreach === selected);
      setFilteredTemplates(filtered);
    } else {
      setFilteredTemplates(templates); // Show all if no filter is selected
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Email Templates</DrawerHeader>
        <DrawerBody>
          <Select
            placeholder="Filter by Outreach"
            value={selectedOutreach}
            onChange={handleFilterChange}
            mb={4}
          >
            {[...new Set(templates.map((template) => template.outreach))].map((outreach) => (
              <option key={outreach} value={outreach}>
                {outreach}
              </option>
            ))}
          </Select>

          <VStack align="stretch" spacing={4}>
            {filteredTemplates.map((template, index) => (
              <Box key={index} p={4} borderWidth="1px" borderRadius="md" bg="gray.50">
                <Text fontWeight="bold" fontSize="lg">
                  {template.subject || ""}
                </Text>
                <Text
                  mt={2}
                  fontSize="sm"
                  color="gray.600"
                  dangerouslySetInnerHTML={{
                    __html: template.email_body.replace(/\n/g, "<br />"),
                  }}
                />
                <Box display="flex" justifyContent="flex-end" w="100%">
                  <Button
                    mt={2}
                    colorScheme="blue"
                    size="sm"
                    onClick={() => {
                      onInsertTemplate(template.subject, template.email_body);
                      onClose(); // Close the drawer after insertion
                    }}
                  >
                    Insert
                  </Button>
                </Box>
              </Box>
            ))}
            {filteredTemplates.length === 0 && (
              <Text>No templates found for the selected outreach.</Text>
            )}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default EmailTemplatesDrawer;
