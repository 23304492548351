import React, { useEffect, useState } from "react";
import {
  Tooltip,
  Icon,
  useColorMode,
  Box,
  VStack,
  Select,
  EditableTextarea,
  List,
  Flex,
  Heading,
  IconButton,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  ListItem,
  Text,
  Link,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import Tabs from "../Tabs";
import { logout } from "../../auth";
import DOMPurify from "dompurify";
import usePersistedState from "./../usePersistedState";
import { fetchRolesForModule } from "../api/api";
import { fetchModules } from "../../api/fetchModules";
import Pagination from "../Pagination";
import RoleDropdown from "../common/RoleDropdown";
import CustomSelect from "../common/CustomSelect";
import axiosInstance from "../../helpers/axiosInstance";
import { SettingsIcon } from "@chakra-ui/icons";
import Settings from "./Settings";

// CustomEditable is a component that allows a portion of text to be edited by the user,
// and provides a callback for when the editing state changes
const CustomEditable = ({
  startWithEditView,
  onSubmit,
  ml,
  value,
  onChange,
  placeholder,
  maxWidth,
}) => {
  const [isEditing, setEditing] = useState(false);
  const { colorMode } = useColorMode();
  // Function to handle editing status
  const handleEditing = (nextIsEditing) => {
    if (nextIsEditing !== isEditing) {
      setEditing(nextIsEditing);
    }
  };

  return (
    // Editable component to make an item editable on user interaction
    <Editable
      startWithEditView={startWithEditView}
      onSubmit={onSubmit}
      ml={ml}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      maxWidth={maxWidth}
      onEdit={handleEditing}
    >
      <EditablePreview color={colorMode === "dark" ? "white" : "black"} />
      <EditableInput
        color="black"
        _placeholder={{ color: "gray.400" }}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      />
    </Editable>
  );
};

const Email = () => {
  //console.log("In Email...");
  const { colorMode } = useColorMode();
  const [modules, setModules] = usePersistedState("modules", []);
  const [error, setError] = usePersistedState("error", null);
  const [domains, setDomains] = usePersistedState("domains", {});
  const [threads, setThreads] = usePersistedState("threads", {});
  const [isEditing, setIsEditing] = usePersistedState("isEditing", {});
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [selectedThread, setSelectedThread] = usePersistedState(
    "selectedThread",
    null
  );
  const [selectedEmailIndex, setSelectedEmailIndex] = usePersistedState(
    "selectedEmailIndex",
    null
  );
  const [selectedEmailId, setSelectedEmailId] = usePersistedState(
    "selectedEmailId",
    null
  );
  const [uploading, setUploading] = usePersistedState("uploading", false);
  const [selectedModule, setSelectedModule] = usePersistedState(
    "selectedModuleEmail",
    ""
  );
  const [selectedThreadEmails, setSelectedThreadEmails] = usePersistedState(
    "selectedThreadEmails",
    []
  );
  const [selectedRole, setSelectedRole] = usePersistedState(
    "selectedRoleEmail",
    null
  );
  const handleSession = (response) => {
    if (response.status === 401) {
      logout();
      toast({
        title: "Session expired",
        description: "Your session has expired. Please login again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      setError(`Error: ${response.status}`);
    }
  };

  useEffect(() => {
    const fetchModuleData = async () => {
      try {
        const modulesData = await fetchModules();
        // Use the roles data however you need in this component
        setModules(
          modulesData.map((module) => ({ label: module, value: module }))
        );
      } catch (error) {
        handleSession(error);
      }
    };

    fetchModuleData();
  }, []);

  const fetchThreads = async (moduleName, domain = null) => {
    //console.log("In fetch Threads function");
    //console.log("Module Name", moduleName);
    //console.log("Domain name", domain);

    const payload = {
      module: moduleName,
      domain: domain,
    };
    //console.log("fetchThreads Payload", payload);
    try {
      const response = await axiosInstance.post(
        "/email/get-subjects",
        // { module: moduleName, domain },
        payload
      );

      //console.log("Response: ", response.data.data);

      if (response.status === 200 && response.data.data) {
        const threads = response.data.data;
        //console.log("Response: ", response);
        //console.log("Response.data: ", response.data);
        if (Array.isArray(threads)) {
          const threadObjects = threads.map((thread) => ({
            moduleName: moduleName,
            domain: domain,
            subject: thread.subject,
            conversationId: thread.conversationId,
          }));
          //console.log("Response: ", response);
          //console.log("Response.data: ", response.data);

          // setThreads((prev) => ({ ...prev, [domain]: threadObjects }));
          const key = domain || moduleName;
          setThreads((prev) => ({ ...prev, [key]: threadObjects }));
        } else {
          console.error(
            `Expected an array of threads, but received: ${response.data.data}`
          );
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
        toast({
          title: "Session expired",
          description: "Your session has expired. Please login again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        //console.log(error);
        setError("An error occurred while trying to fetch threads.");
      }
    }
  };

  const fetchEmails = async (domain, conversationId) => {
    //console.log("In fetch Emails function");
    //console.log("Domain:", domain);
    //console.log("Conversation ID:", conversationId);

    try {
      const response = await axiosInstance.post(
        "/email/get-emails-of-subjects",
        { domain, conversationId }
      );

      //console.log("Response:", response.data);

      if (response.status === 200 && response.data.data) {
        const emails = response.data.data;
        //console.log("Response:", response);
        //console.log("Response.data:", response.data.data);

        if (Array.isArray(emails)) {
          const emailObjects = emails.map((email) => ({
            subject: email.subject,
            bodyPreview: email.bodyPreview.replace(/\r?\n+/g, "<br />"),
            body: email.body,
            internetMessageId: email.internetMessageId,
            id: email.id,
            sender: email.sender,
            toRecipients: email.toRecipients,
            createdDateTime: email.createdDateTime,
            conversationId: email.conversationId,
            webLink: email.webLink,
          }));

          setSelectedThreadEmails(emailObjects);
        } else {
          console.error(
            `Expected an array of emails, but received: ${response.data.data}`
          );
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
        toast({
          title: "Session expired",
          description: "Your session has expired. Please login again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        //console.log(error);
        setError("An error occurred while trying to fetch emails.");
      }
    }
  };

  const makeDraft = async () => {
    //console.log("in makeDraft function");
    //console.log("makeDraft selectedEmailId:", selectedEmailId);
    //console.log("makeDraft selectedEmailIndex:", selectedEmailIndex);
    // Check if selectedRole is not set
    if (!selectedRole) {
      toast({
        title: "Selection missing",
        description: "Please select a persona first.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return; // Exit the function early if the condition is met
    }
    setUploading(true);
    // const removeHtmlComments = (html) => {
    //   return html.replace(/<!--[\s\S]*?-->/g, '');
    // }

    if (selectedEmailIndex !== null) {
      const currentEmail = selectedThreadEmails[selectedEmailIndex];

      const parser = new DOMParser();
      const doc = parser.parseFromString(currentEmail.body, "text/html");

      const element = doc.querySelector("body");

      // if (!element) {
      //   //console.log("Element not found");
      //   // setUploading(false);
      // }

      const emailBodies = element ? element.textContent : "";

      //console.log("makeDraft email history:", emailBodies);

      // Split the text by spaces
      const words = emailBodies.split(" ");

      // Retrieve the first 500 words
      const clientEmail = words.slice(0, 500).join(" ");

      //console.log("Email :", clientEmail);

      const payload = {
        company_name: "Lemanik",
        client_email: clientEmail,
        history_mail: emailBodies,
        module_name: selectedModule,
        persona: selectedRole,
      };
      //console.log("Payload", payload);
      try {
        const response = await axiosInstance.post(
          "/email/generate-email-response",
          payload
        );
        setUploading(true);
        //console.log("makeDraft Response:", response.data.data.email_response);
        // The request was successful
        if (response.status === 200) {
          const receivedText = response.data.data.email_response;
          //console.log("receivedText:", receivedText);
          setUploading(false);
          // Create a new array that includes the updated email
          const updatedEmails = selectedThreadEmails.map((email, index) => {
            if (index === selectedEmailIndex) {
              // This is the email that should be updated, so return a new object
              return { ...email, serverResponse: receivedText };
            } else {
              // This email should not be updated, so return it as-is
              return email;
            }
          });

          // Update the state with the new array
          setSelectedThreadEmails(updatedEmails);
          setUploading(false);
        }
      } catch (error) {
        setUploading(false);
        if (error.response && error.response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setUploading(false);
          //console.log(error);
          setError("An error occurred while trying to make a draft.");
        }
      }
    }
  };

  const handleSaveEditedResponse = (emailIndex, newValue) => {
    const updatedEmails = selectedThreadEmails.map((email, index) =>
      index === emailIndex ? { ...email, serverResponse: newValue } : email
    );
    setSelectedThreadEmails(updatedEmails);
  };

  const sendDraft = async () => {
    //console.log("In sendDraft function");

    if (selectedEmailIndex !== null) {
      const email = selectedThreadEmails[selectedEmailIndex];

      const payload = {
        email_body: email.serverResponse,
        message_id: email.id,
      };

      //console.log("Send draft payload", payload);

      try {
        const response = await axiosInstance.post("/email/make-draft", payload);

        //console.log("sendDraft Response", response.data);

        // The request was successful
        if (response.status === 200) {
          if (response.data.data.success === true) {
            //console.log("Draft sent successfully");
            toast({
              title: "Draft Sent Successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          } else {
            toast({
              title: "An error occurred while trying to send the draft.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          //console.log(error);
          setError("An error occurred while trying to send the draft.");
        }
      }
    } else {
      //console.log("No selected email to send.");
    }
  };

  // Function to handle changes in the editable input field.
  // This function is called whenever the user types into the editable field in the UI.
  // It updates the domains state with the new value that the user has typed in.
  const handleEditableChange = (moduleName, value) => {
    setDomains((prev) => ({ ...prev, [moduleName]: value }));
    if (value) {
      fetchThreads(moduleName, value);
    }
  };

  // Function to handle the edit button click.
  // This function is called whenever the user clicks the edit button associated with a module.
  // It updates the isEditing state, setting the module being edited to true.
  const handleEditClick = (moduleName, event) => {
    event.stopPropagation();
    setIsEditing((prev) => ({ ...prev, [moduleName]: true }));
  };

  // Function to handle when the user submits their edits.
  // This function is called whenever the user finishes editing a module's domain.
  // It updates the isEditing state, setting the module that has just been edited to false.
  const handleEditableSubmit = (moduleName) => {
    setIsEditing((prev) => ({ ...prev, [moduleName]: false }));
  };

  // Function to handle the user clicking on a thread.
  // This function is called whenever the user clicks on a thread from the list of threads.
  // It sets the selectedThread state to the thread that was clicked.
  const handleThreadClick = (thread) => {
    setSelectedThread(thread);
    fetchEmails(thread.domain, thread.conversationId);
  };

  // Function to handle the user clicking on a textbox.
  // This function is called whenever the user clicks on a textbox containing an email.
  const handleTextBoxClick = (email) => {
    const index = selectedThreadEmails.findIndex(
      (threadEmail) => threadEmail.id === email.id
    );
    setSelectedEmailIndex(index);
    //console.log("handleTextBoxClick index", index);
    setSelectedEmailId(email.id);
    //console.log("handleTextBoxClick selectedEmailId", selectedEmailId);
    onOpen();
  };

  // Function to handle the user clicking on a module.
  // This function is called whenever the user clicks on a module from the list of modules.
  // It sets the selectedModule state to the module that was clicked.
  const handleModuleClick = (module) => {
    setSelectedModule(module);
    fetchThreads(module, domains[module] || null); // Fetch threads based on the domain if it exists, otherwise pass null.
  };

  useEffect(() => {
    if (selectedModule) {
      fetchThreads(selectedModule, domains[selectedModule] || null); // Fetch threads based on the domain if it exists, otherwise pass null.
    }
  }, [selectedModule]); // The dependency is now the selectedModule so it fetches whenever the module changes.

  const [currentPage, setCurrentPage] = useState(1);
  const threadsPerPage = 10;

  const indexOfLastThread = currentPage * threadsPerPage;
  const indexOfFirstThread = indexOfLastThread - threadsPerPage;

  // Safely retrieve the currentThreads using optional chaining and provide a fallback empty array
  const currentThreads =
    threads[domains[selectedModule] || selectedModule]?.slice(
      indexOfFirstThread,
      indexOfLastThread
    ) || [];

  // Check for the existence of threads[domains[selectedModule]] before accessing its length.
  const totalThreads =
    threads[domains[selectedModule] || selectedModule]?.length || 0;

  useEffect(() => {
    // Reset the current page to 1 whenever the domainName changes
    setCurrentPage(1);
  }, [domains]);
  return (
    <Box
      display={{ base: "block", md: "flex" }}
      width="100%"
      height="calc(100vh - 52px)"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
    >
      <Box
        w="20%"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        mr={0}
        mt={2}
        ml={2}
        mb={2}
        border="1px"
        borderTopRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
      >
        <Tabs />
      </Box>
      <Box
        width={{ base: "100%", md: "35%" }}
        bg={colorMode === "dark" ? "gray.700" : "white"}
        p={2}
        mr={0}
        m={2}
        border="1px"
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
      >
        <Flex width={{ base: "100%", md: "35%" }}>
          <Heading
            fontSize="md"
            fontWeight="semibold"
            color={colorMode === "dark" ? "white" : "#1A202C"}
            mb={1}
            p={2}
            pb={1}
          >
            Agents
          </Heading>

          <Button
            ml={2}
            onClick={() => setIsSettingsOpen(true)}
            size="sm"
            variant="unstyled" // Makes the button have no background or border
            align = "flex-end"
          >
            <Tooltip label="Schedule Settings">
              <Icon as={SettingsIcon} />
            </Tooltip>
          </Button>
          <Settings
            isOpen={isSettingsOpen}
            onClose={() => setIsSettingsOpen(false)}
          />
        </Flex>
        <Heading
          fontSize="xs"
          color={colorMode === "dark" ? "gray.400" : "blackAlpha.700"}
          mb={0}
          pl={2}
          pb={2}
        >
          Choose an Agent.
        </Heading>
        <Divider
          mt={0}
          mb={0}
          borderColor={colorMode === "dark" ? "gray.600" : "#E2E8F0"}
        />
        <Box mt={0} pt={0}>
          <Accordion allowMultiple>
            <AccordionItem>
              <AccordionButton>
                <Flex
                  flex="1"
                  textAlign="left"
                  alignItems="center"
                  justify="space-between"
                  wrap="nowrap"
                >
                  {/* <Select
                    flex="1"
                    mr={2}
                    size="sm"
                    borderRadius="md"
                    placeholder="Select agent"
                    value={selectedModule}
                    onChange={(e) => handleModuleClick(e.target.value)}
                  >
                    {modules.map((module, index) => (
                      <option key={index} value={module}>
                        {module}
                      </option>
                    ))}
                  </Select> */}
                  <CustomSelect
                    options={modules}
                    value={selectedModule}
                    onChange={(e) => handleModuleClick(e.target.value)}
                    placeholder="Select agent"
                    size="sm"
                    borderRadius="md"
                    flex="1"
                    mr={2}
                  />
                  {/* <Select
                    flex="1"
                    mr={2}
                    borderRadius="md"
                    size="sm"
                    placeholder="Select persona"
                    value={dropdownSelections[selectedModule] || ""}
                    onChange={(e) =>
                      handleDropdownChange(selectedModule, e.target.value)
                    }
                  >
                    {roles
                      .filter(
                        (role) =>
                          role.module_name === selectedModule || role.is_default
                      )
                      .map((role, roleIdx) => (
                        <option key={roleIdx} value={role._id}>
                          {role.name}
                        </option>
                      ))}
                  </Select> */}
                  <RoleDropdown
                    selectedRole={selectedRole}
                    setSelectedRole={setSelectedRole}
                    selectedModule={selectedModule}
                    setSelectedModule={setSelectedModule}
                    size="sm"
                  />
                  <Flex
                    flex="1"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <CustomEditable
                      flex="1"
                      startWithEditView={isEditing[selectedModule]}
                      onSubmit={() => handleEditableSubmit(selectedModule)}
                      value={domains[selectedModule]}
                      onChange={(value) =>
                        handleEditableChange(selectedModule, value)
                      }
                      placeholder="@example.com"
                      color={colorMode === "dark" ? "white" : "black"}
                    />
                    <IconButton
                      ml={2}
                      aria-label="Edit module"
                      icon={<EditIcon />}
                      colorScheme="gray"
                      size="sm"
                      onClick={(event) =>
                        handleEditClick(selectedModule, event)
                      }
                    />
                  </Flex>
                </Flex>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel
                pb={4}
                overflowY="scroll"
                maxHeight="calc(100vh - 136px - 48px)"
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
              >
                {/* {selectedModule &&
                  Array.isArray(
                    threads[domains[selectedModule] || selectedModule]
                  ) && (
                    <>
                      <Heading size="xs" alignItems="left" mt={4} mb={2}>
                        Threads
                      </Heading>
                      <List spacing={2} styleType="none">
                        {threads[domains[selectedModule] || selectedModule].map(
                          (thread, threadIndex) => (
                            <ListItem key={threadIndex}>
                              <Link
                                color={
                                  thread === selectedThread
                                    ? "blue.300"
                                    : colorMode === "dark"
                                    ? "gray.200"
                                    : "black"
                                }
                                onClick={() => handleThreadClick(thread)}
                              >
                                {thread.subject}
                              </Link>
                            </ListItem>
                          )
                        )}
                      </List>
                    </>
                  )} */}
                {currentThreads.length > 0 && (
                  <>
                    <Heading size="xs" alignItems="left" mt={4} mb={2}>
                      Threads
                    </Heading>
                    <List spacing={2} styleType="none">
                      {currentThreads.map((thread) => (
                        <ListItem key={thread.conversationId}>
                          <Link
                            color={
                              thread === selectedThread
                                ? "blue.300"
                                : colorMode === "dark"
                                ? "gray.200"
                                : "black"
                            }
                            onClick={() => handleThreadClick(thread)}
                          >
                            {thread.subject}
                          </Link>
                        </ListItem>
                      ))}
                    </List>
                    <Pagination
                      currentPage={currentPage}
                      totalItems={totalThreads}
                      itemsPerPage={threadsPerPage}
                      onPageChange={(page) => setCurrentPage(page)}
                    />
                  </>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>
      <Box
        width={{ base: "100%", md: "45%" }}
        bg={colorMode === "dark" ? "gray.700" : "white"}
        boxSizing="border-box"
        p={2}
        m={2}
        ml={0}
        overflow="hidden"
        border="1px"
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
        display="flex" // Make this container a flex container
        flexWrap="wrap" // Allow children to wrap to the next line
      >
        {selectedThread && (
          <VStack spacing={2} alignItems="left" width="100%">
            <Box>
              <Heading
                fontSize="md"
                fontWeight="semibold"
                color={colorMode === "dark" ? "white" : "#1A202C"}
                mb={1}
                p={2}
                pb={1}
              >
                {selectedThread.moduleName}
              </Heading>
              {/* <Heading
                fontSize="xs"
                color={colorMode === "dark" ? "gray.400" : "blackAlpha.700"}
                mb={0}
                pl={2}
                pb={2}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
              >
                {selectedThread.subject}
              </Heading> */}
              <Heading
                fontSize="xs"
                color={colorMode === "dark" ? "gray.400" : "blackAlpha.700"}
                mb={0}
                pl={2}
                pb={2}
                whiteSpace="nowrap" // Prevent text from wrapping to the next line
                overflow="hidden" // Hide any overflowing text
                textOverflow="ellipsis" // Add ellipsis (...) for truncated text
              >
                {selectedThread.subject}
              </Heading>

              <Divider
                mt={0}
                mb={2}
                borderColor={colorMode === "dark" ? "gray.600" : "#E2E8F0"}
              />
            </Box>
            <Box
              mb={4}
              overflowY="scroll"
              maxHeight="calc(100vh - 160px)"
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              {selectedThreadEmails.map((email, index) => (
                <Box>
                  <Box
                    key={index}
                    bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
                    color={colorMode === "dark" ? "white" : "black"}
                    p={4}
                    width="100%"
                    maxHeight="100px"
                    borderWidth={1}
                    borderRadius="md"
                    cursor="pointer"
                    _hover={{
                      bg: colorMode === "dark" ? "gray.600" : "gray.200",
                    }}
                    onClick={() => handleTextBoxClick(email)}
                    overflowY="hidden"
                  >
                    <Text
                      mb={0}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(email.bodyPreview),
                      }}
                    ></Text>
                  </Box>
                  <Flex direction="row" justifyContent="flex-end">
                    <Text fontSize="xs" color="gray.500" mb={0}>
                      {email.createdDateTime}
                    </Text>
                  </Flex>
                </Box>
              ))}
            </Box>
          </VStack>
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent minH="50vh">
          <ModalHeader>
            {selectedThread ? selectedThread.subject : ""}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Heading size="sm">Email</Heading>
              <Box minH="25vh" maxH="35vh" overflowY="auto">
                {selectedThreadEmails[selectedEmailIndex] && (
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        selectedThreadEmails[selectedEmailIndex].body
                      ),
                    }}
                  ></Text>
                )}
              </Box>
            </Box>
            <Box>
              <Heading size="sm">Reply</Heading>
              <Box
                minH="15vh"
                maxH="20vh"
                overflowY="auto"
                scrollBehavior="hidden"
                sx={{
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
              >
                <Editable
                  value={
                    selectedEmailIndex !== null &&
                    selectedThreadEmails[selectedEmailIndex] &&
                    selectedThreadEmails[selectedEmailIndex].serverResponse
                      ? selectedThreadEmails[selectedEmailIndex].serverResponse
                      : "No response yet..."
                  }
                  onSubmit={(nextValue) =>
                    handleSaveEditedResponse(selectedEmailIndex, nextValue)
                  }
                  onChange={(nextValue) =>
                    handleSaveEditedResponse(selectedEmailIndex, nextValue)
                  }
                  color={colorMode === "dark" ? "white" : "black"}
                >
                  <EditablePreview />
                  <EditableTextarea />
                </Editable>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              _hover={{}}
              bgColor="blue.500"
              textColor="white"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              _hover={{}}
              bgColor="blue.500"
              textColor="white"
              onClick={makeDraft}
              isLoading={uploading}
              loadingText="Creating"
            >
              Create Draft
            </Button>
            {selectedEmailIndex !== null &&
              selectedThreadEmails[selectedEmailIndex] &&
              selectedThreadEmails[selectedEmailIndex].serverResponse && (
                <Button
                  ml={2}
                  _hover={{}}
                  bgColor="blue.500"
                  textColor="white"
                  onClick={() => sendDraft(selectedEmailIndex)}
                >
                  Send Draft
                </Button>
              )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default Email;
