import { getAuthHeader, handleUnauthorized } from "../../helpers/auth";
import { logout } from "../../auth";
import axiosInstance from "../../helpers/axiosInstance";

const API_BASE_URL = "/ddq-rfp"; // Adjust this as per your actual API URL

export const fetchDDQRFP = async (setFetchedMinutes, showToast) => {
  // //console.log("Fetching ddq-rfp...");

  try {
    const response = await axiosInstance.get(`${API_BASE_URL}/fetch-ddq`);

    if (response.status === 200) {
      setFetchedMinutes(response.data); // Update fetchedMinutes state
      // //console.log("Fetch DDQ/RFP response", response.data);
    } else {
      if (response && response.status === 401) {
        handleUnauthorized(showToast);
      }
    }
  } catch (error) {
    console.error(error);
    showToast({
      title: "Error",
      description: "Error in fetching DDQ/RFP.",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-center",
    });
  }
};

export const uploadFiles = async (formData) => {
  const response = await axiosInstance.post(
    `${API_BASE_URL}/generate-question`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Overrides the default Content-Type
      },
    }
  );
  return response.data;
};

/**
 * Sends agenda points to the backend for minute generation.
 *
 * @param {string} selectedMinutesId
 * @param {array} agendaPoints
 * @param {string} token
 * @param {object|null} specificAgendaPoint
 * @param {number|null} index
 * @returns {object} The response from the backend.
 */
export const sendAgendaPointsToBackend = async (
  selectedMinutesId,
  agendaPoints,
  specificAgendaPoint = null,
  index = null,
  selectedQuestionsForThisMinute
) => {
  //console.log(" In sendAgendaPointsToBackend function");
  const payload = specificAgendaPoint
    ? {
        str_id: selectedMinutesId,
        question_list: [specificAgendaPoint],
        index,
        linked_question: selectedQuestionsForThisMinute
      }
    : {
        str_id: selectedMinutesId,
        question_list: agendaPoints,
        linked_question: selectedQuestionsForThisMinute
      };

  console.log("payload", payload);

  try {
    const response = await axiosInstance.post("/ddq-rfp/generate-ddq-rfp", payload);

    if (response.status === 200) {
      return {
        data: response.data,
        status: response.status,
      };
    } else {
      throw new Error("Failed to send agenda points.");
    }
  } catch (error) {
    throw error;
  }
};


export const fetchFileDetails = async (id, handleErrorr) => {
  if (id == null) {
    //console.log("No File selected, _id is null");
    return;
  }
  try {
    const response = await axiosInstance.post(
      "/ddq-rfp/fetch-ddq-by-id",
      { str_id: id });

    //console.log("File Details:", response.data);

    if (response.status !== 200) {
      console.error("Error fetching File details:", response);
      if (response && response.status === 401) {
        logout();
        handleErrorr("Session expired", "Your session has expired. Please login again.");
      }
      return;
    }
    return response.data;
  } catch (error) {
    console.error(error);
    // handle other errors if necessary
  }
};


export const handlePreview = async (selectedMinutesId, index = null) => {
  const payload = { str_id: selectedMinutesId, index };
  const response = await axiosInstance.post(`${API_BASE_URL}/handle-preview`, payload);

  if (response.status === 401) {
    logout();
  }
  return response.data;
};