import React, {useEffect} from "react";
import { motion } from "framer-motion";
import { useColorMode, Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { ChevronRightIcon } from "@chakra-ui/icons";
import "../styles/home.css";

const HomePage = () => {
  const { colorMode } = useColorMode();
  const history = useHistory();

  const handleButtonClick = () => {
    history.push("/notifications"); // Replace '/target-page' with your target page's path
  };

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8 },
    },
  };

  const isDark = colorMode === "dark";

  useEffect(() => {
    // Temporarily hide overflow on the body
    document.body.style.overflowY = 'hidden';
    return () => {
      // Re-enable scrolling after component mounts
      document.body.style.overflowY = 'auto';
    };
  }, []);

  return (
    <motion.div
      className={`modern-container ${isDark ? "dark" : ""}`}
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <div className="content">
        <h1 className={`${isDark ? "dark" : ""}`}>
          Welcome to <span className="emphasis">NexusAIMS</span>
        </h1>
        <p className={`${isDark ? "dark" : ""}`}>Your AI Powered Platform.</p>
        <motion.div
          className={`underline ${isDark ? "dark" : ""}`}
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        />
        {/* Updated button with text and right arrow icon */}
        <Button
          mt="20px" // Margin top for spacing
          colorScheme="purple" // Color scheme for the button
          bg="#5a67d8" // Background color
          onClick={handleButtonClick}
          rightIcon={<ChevronRightIcon />} // Right arrow icon
          variant="solid"
          _hover={{ bg: "#5a67d8" }} // Keeps the background color the same on hover
        >
          Upcoming Updates
        </Button>
      </div>
    </motion.div>
  );
};

export default HomePage;
