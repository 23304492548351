import React from "react";
import { Button, useColorMode } from "@chakra-ui/react";

const Pagination = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
}) => {
  const { colorMode } = useColorMode();
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const prevEnabled = currentPage > 1;
  const nextEnabled = currentPage < totalPages;

  return (
    <div
      style={{ display: "flex", justifyContent: "left", alignItems: "center" }}
    >
      {prevEnabled && (
        <Button
          onClick={() => onPageChange(currentPage - 1)}
          _hover={{}}
          bgColor="blue.500"
          textColor="white"
        >
          Previous
        </Button>
      )}

      {/* Only show page number if totalPages > 1 */}
      {totalPages > 1 && (
        <span style={{ margin: "0 15px", fontWeight: "bold" }}>
          Page {currentPage} of {totalPages}
        </span>
      )}

      {nextEnabled && (
        <Button
          onClick={() => onPageChange(currentPage + 1)}
          _hover={{}}
          bgColor="blue.500"
          textColor="white"
        >
          Next
        </Button>
      )}
    </div>
  );
};

export default Pagination;
