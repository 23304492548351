import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { uploadFiles } from "../../api/DDQ_RFP/fileApi";
import { fetchDDQRFP } from "../../api/DDQ_RFP/fileApi";
import { useToast } from "@chakra-ui/react";
import { logout } from '../../auth';

const useFileUpload = (
  toast,
  selectedRole,
  selectedModule,
  selectedMinute,
  setSelectedMinute,
  // setSelectedMinutesId,
  fetchedMinutes,
  setFetchedMinutes,
  minutesList,
  setMinutesList,
  onMinuteCleared,
) => {
  // console.log("useFileUpload selectedRole", selectedRole);
  // console.log("useFileUpload selectedModule", selectedModule);
  // console.log("useFileUpload selectedMinute", selectedMinute);
  // // console.log("useFileUpload selectedMinutesId", selectedMinutesId);
  const [isLoading, setIsLoading] = useState(
    localStorage.getItem("setIsLoadingUpload") === "true"
  );
  const [selectedFiles, setSelectedFiles] = useState({
    transcript_file: { file: null, filename: "", type: "" },
  });
  // Function to handle error toast
  function toastError(description) {
    setIsLoading(false);
    localStorage.removeItem("setIsLoadingUpload");
    toast({
      title: "Error",
      description,
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-center",
    });
  }

  const changeHandler = (name, file) => {
    const extension = file.name.split(".").pop().toLowerCase();
    const newFilename = `${uuidv4()}.${extension}`;

    setSelectedFiles((prevFiles) => ({
      ...prevFiles,
      [name]: { file, filename: newFilename, type: extension },
    }));
  };

  const handleUpload = async () => {
    setIsLoading(true);
    localStorage.setItem("setIsLoadingUpload", "true");

    // Check if any file is missing
    if (Object.values(selectedFiles).some((fileData) => !fileData.file)) {
      toastError("File is required");
      return;
    }

    // Validation for required fields
    if (!selectedRole || !selectedModule) {
      toastError("All fields are required");
      return;
    }

    // Check if selectedMinute has either text or fund_name
    if (!selectedMinute) {
      toastError("DDP/RFP details are required");
      return;
    }

    const formData = new FormData();

    // Append files to formData
    Object.entries(selectedFiles).forEach(([key, fileData]) => {
      formData.append(key, fileData.file, fileData.filename);
    });

    // Append additional data
    formData.append("selectedPersona", selectedRole.role_prompt);
    formData.append("selectedModule", selectedModule);
    formData.append("ddqrfpName", selectedMinute.text || selectedMinute);

    // To log the contents of formData
    // for (let [key, value] of formData.entries()) {
      // console.log(key, value);
    // }

    try {
      const response = await uploadFiles(formData);
      // setSelectedMinutesId(response.data.fileId);
      setSelectedFiles({
        transcript_file: { file: null, filename: "", type: "" },
      });
      // setMinutesId(response.data._id);
      toast({
        title: "Success",
        description: "Files uploaded successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
      
      // Check if it’s a user-created minute
      if (selectedMinute && selectedMinute.id) {
        // Reset the input fields
        // clearFields();
        if (selectedMinute && selectedMinute.id) {
          // Remove the selected minute from the minutesList
          setMinutesList((prevState) =>
            prevState.filter((minute) => minute.id !== selectedMinute.id)
          );
          // Clear the selectedMinute
          setSelectedMinute(null);
          // console.log("Setting to null");
          fetchDDQRFP(setFetchedMinutes, toast);
        }
      }
    } catch (error) {
      // Error handling
      toast({
        title: "Upload Failed",
        description: "There was an error uploading the files.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-center",
      });
    } finally {
      setIsLoading(false);
      localStorage.removeItem("setIsLoadingUpload");
    }
  };

  return { isLoading, selectedFiles, changeHandler, handleUpload };
};

export default useFileUpload;
