import React, { useEffect, useState } from "react";
import {
  VStack,
  useToast,
  Button,
  Flex,
  Text,
  Divider,
  Box,
  useColorMode,
  Spinner,
} from "@chakra-ui/react";
import axiosInstance from "../../helpers/axiosInstance";
import ReportDisplay from "./ReportDisplay";
import FileDownload from "./FileDownload";
import SummaryDisplay from "./SummaryDisplay";

const Output = ({
  selectedFundsId,
  selectedFund,
  report = [],
  setReport,
  summary,
  setSummary,
  lastStep,
}) => {
  const toast = useToast();
  const documentId = 1;
  const { colorMode } = useColorMode();
  const [isLoading, setIsLoading] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [status, setStatus] = useState(null); // Store the current task status

  // Function to fetch the task status using task ID
  const fetchFundDetails = async (taskId) => {
    try {
      const response = await axiosInstance.get(
        `fund_docs/task-status/${taskId}`
      );
      if (response.status === 200) {
        const { state, progress, result } = response.data;
        setStatus(state);

        if (state === "PENDING") {
          toast({
            title: "Report is still being generated",
            description: `Progress: ${progress}%`,
            status: "info",
            duration: 3000,
            isClosable: true,
          });
        } else if (state === "FAILURE") {
          toast({
            title: "Report generation failed",
            description: result || "There was an issue generating the report.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (state === "SUCCESS") {
          const reportData = Array.isArray(result[0].data.report)
            ? result[0].data.report
            : [];
          setReport(reportData);
          setSummary(result[0].data.summary);
        }
      } else {
        toast({
          title: "Error fetching task status",
          description: `Unexpected status: ${response.status}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error caught in fetchFundDetails:", error);
      toast({
        title: "Error fetching report status",
        description: error.response?.data.message || "Failed to fetch status",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Function to fetch the task ID if needed
  const fetchTaskId = async () => {
    try {
      const response = await axiosInstance.get(
        `fund_docs/get-task-id/${selectedFundsId}`
      );
      if (response.status === 200) {
        const fetchedTaskId = response.data.data.task_id;
        setTaskId(fetchedTaskId);
      }
    } catch (error) {
      console.error("Error caught in fetchTaskId:", error);
    }
  };

  // Function to generate the report, which will return a task ID
  const fetchReportData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post("fund_docs/generate-report", {
        str_id: selectedFundsId,
      });
      setIsLoading(false);

      if (response.status === 200) {
        const generatedTaskId = response.data.task_id;
        setTaskId(generatedTaskId);
      } else {
        toast({
          title: "Error generating report",
          description: `Received status: ${response.status}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "Error generating report",
        description:
          error.response?.data.message || "Failed to generate report",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Reset taskId when selectedFundsId changes
  useEffect(() => {
    if (selectedFundsId) {
      setTaskId(null); // Clear the task ID when switching funds
      setStatus(null);
      setReport([]); // Clear the report data for the new fund
      setSummary(null); // Clear the summary for the new fund

      fetchTaskId(); // Fetch a new task ID if necessary
    }
  }, [selectedFundsId]);

  // Fetch task status whenever taskId changes
  useEffect(() => {
    if (taskId) {
      fetchFundDetails(taskId);
    }
  }, [taskId]);

  return (
    <VStack spacing={2}>
      <Flex width="full" justifyContent="space-between">
        {selectedFundsId &&
          report.length > 0 &&
          summary &&
          Object.keys(summary).length > 0 && (
            <Text
              fontSize="md"
              fontWeight="semibold"
              ml={2}
              mb="4px"
              color={colorMode === "dark" ? "white" : "#1A202C"}
            >
              Report
            </Text>
          )}
        {selectedFundsId &&
          report.length > 0 &&
          summary &&
          Object.keys(summary).length > 0 && (
            <FileDownload
              documentId={documentId}
              selectedFundsId={selectedFundsId}
            />
          )}
      </Flex>
      {selectedFundsId &&
        report.length > 0 &&
        summary &&
        Object.keys(summary).length > 0 && <Divider m="0" p="0" />}

      {!taskId && report.length === 0 && selectedFundsId && (
        <VStack
          spacing={4}
          justifyContent="center"
          alignItems="center"
          height="80vh"
        >
          <Button
            size="md"
            colorScheme="blue"
            onClick={fetchReportData}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {isLoading ? <Spinner size="sm" /> : "Generate"}
          </Button>
          <Text fontSize="lg" color="blackAlpha.700" fontWeight="medium">
            Generate a PitchComply report
          </Text>
        </VStack>
      )}
      {status === "PENDING" && lastStep !== "finalize" && (
        <VStack
          spacing={4}
          justifyContent="center"
          alignItems="center"
          height="80vh"
        >
          <Text fontSize="lg" color="blackAlpha.700" fontWeight="medium">
            PitchComply report is still being generated. Please check later...
          </Text>
        </VStack>
      )}
      {status === "FAILURE" && lastStep !== "finalize" && (
        <VStack
          spacing={4}
          justifyContent="center"
          alignItems="center"
          height="80vh"
        >
          <Text fontSize="lg" color="blackAlpha.700" fontWeight="medium">
            PitchComply report generation failed. Please try again later.
          </Text>
        </VStack>
      )}
      {(status === "SUCCESS" || lastStep === "finalize") && (
        <Box
          height="75vh"
          overflowY="scroll"
          scrollBehavior="hidden"
          sx={{
            "&::-webkit-scrollbar": { display: "none" },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {summary && Object.keys(summary).length > 0 && (
            <SummaryDisplay summary={summary} />
          )}
          {report.length > 0 && <ReportDisplay report={report} />}
        </Box>
      )}
    </VStack>
  );
};

export default Output;
