import { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Divider,
  Text,
  Button,
  useToast,
  Input,
  IconButton,
  useColorMode,
  List,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { EditIcon, MinusIcon, AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { sendAgendaPointsToBackend, handlePreview } from "./apiService"; // A separated API logic
import { fetchMinuteDetails } from "../api";
import DynamicInput from "../../common/DynamicInput";
import FileDownload from "../FileDownload";
import FileSent from "../FileSent";
import usePersistedState from "../../usePersistedState";
import { logout } from "../../../auth";
import axiosInstance from "../../../helpers/axiosInstance";
import DOMPurify from 'dompurify';

const GenerateMinutes = ({ minutesId, selectedMinutesId }) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const documentId = 1;
  const [agendaPoints, setAgendaPoints] = usePersistedState("agendaPoints", []);
  const [editing, setEditing] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [successfulGenerateAll, setSuccessfulGenerateAll] = usePersistedState(
    "successfulGenerateAll",
    {}
  );
  const [successfulGenerations, setSuccessfulGenerations] = usePersistedState(
    "successfulGenerations",
    {}
  );
  const [loadingAgendas, setLoadingAgendas] = usePersistedState(
    "loadingAgendas",
    {}
  );
  const [loadingGenerateAll, setLoadingGenerateAll] = usePersistedState(
    "loadingGenerateAll",
    {}
  );
  const [outputText, setOutputText] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingAll, setIsGeneratingAll] = useState(false);
  const [modalHeader, setModalHeader] = useState("Output");

  const token = localStorage.getItem("REACT_TOKEN_AUTH_KEY").replace(/"/g, "");

  const handleError = (title, description) => {
    toast({
      title,
      description,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Clear previous data
  //       setAgendaPoints([]);

  //       const minuteDetails = await fetchMinuteDetails(
  //         selectedMinutesId,
  //         token
  //       );

  //       // Check if minuteDetails is defined before accessing its properties
  //       const agendaData = minuteDetails
  //         ? minuteDetails.minute_user_prompt ||
  //           minuteDetails?.system_prompt?.agenda_point
  //         : [];

  //       let updatedAgendaPoints =
  //         (agendaData || []).map((agenda, index) => {
  //           const storageKey = `editedAgendaPoint-${selectedMinutesId}-${index}`;
  //           const storedAgendaPoint = localStorage.getItem(storageKey);
  //           const finalAgenda = storedAgendaPoint
  //             ? JSON.parse(storedAgendaPoint)
  //             : agenda;

  //           // If the agenda doesn't already have a heading, set it using main_value
  //           if (!finalAgenda.heading) {
  //             finalAgenda.heading = finalAgenda.main_value;
  //           }

  //           return finalAgenda;
  //         }) || [];

  //       if (selectedMinutesId) {
  //         // Add the default agenda point to the updatedAgendaPoints array
  //         updatedAgendaPoints.push({
  //           heading: "OPEN ACTION POINTS",
  //           main_value:
  //             "List all of the Open Action Points from the meeting. Anything that is outstanding from the previous board minutes, anything that has been requested or inquired about without answer during this board meeting, anything were the speakers told the managers they would get back to them later with specific information. All Action Points should be listed for Lemanik to keep track of. Please write the Action Points in a numbered List of Action Points with Task Description, Team in Charge, Deadline and Status (Opened/Closed).",
  //           sub_value: [],
  //         });
  //       }

  //       setAgendaPoints(updatedAgendaPoints);
  //     } catch (error) {
  //       handleError("Error fetching minute details", error.message);
  //     }
  //   };
  //   fetchData();
  // }, [selectedMinutesId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const minuteDetails = await fetchMinuteDetails(
          selectedMinutesId,
          token
        );

        let fetchedAgendaPoints;

        if (minuteDetails && minuteDetails.minute_user_prompt) {
          fetchedAgendaPoints = Object.values(minuteDetails.minute_user_prompt);
        } else if (
          minuteDetails &&
          minuteDetails.system_prompt &&
          minuteDetails.system_prompt.agenda_point
        ) {
          fetchedAgendaPoints = minuteDetails.system_prompt.agenda_point.map(
            (agenda) => {
              // Copy the agenda object
              const finalAgenda = { ...agenda };

              // If the agenda doesn't already have a heading, set it using main_value
              if (!finalAgenda.heading) {
                finalAgenda.heading = finalAgenda.main_value;
              }

              return finalAgenda;
            }
          );
        } else {
          fetchedAgendaPoints = [];
        }

        // Copy the original data to updatedAgendaPoints
        let updatedAgendaPoints = [...fetchedAgendaPoints];

        const storedKeys = JSON.parse(
          localStorage.getItem("agendaStorageKeys") || "[]"
        );

        storedKeys.forEach((key) => {
          const match = key.match(
            new RegExp(`^editedAgendaPoint-${selectedMinutesId}-(\\d+)$`)
          );
          if (match) {
            const index = parseInt(match[1], 10);
            const storedAgendaPoint = JSON.parse(localStorage.getItem(key));

            // Ensure index is within bounds to prevent overriding fetched data
            if (index < updatedAgendaPoints.length - 1) {
              updatedAgendaPoints[index] = storedAgendaPoint;
            } else {
              const secondLastIndex = updatedAgendaPoints.length - 1;
              updatedAgendaPoints.splice(secondLastIndex, 0, storedAgendaPoint);
            }
          }
        });

        if (
          selectedMinutesId &&
          minuteDetails &&
          !minuteDetails.minute_user_prompt
        ) {
          // Add the default agenda point to the updatedAgendaPoints array
          updatedAgendaPoints.push({
            heading: "OPEN ACTION POINTS",
            main_value:
              "List all of the Open Action Points from the meeting. Anything that is outstanding from the previous board minutes, anything that has been requested or inquired about without answer during this board meeting, anything were the speakers told the managers they would get back to them later with specific information. All Action Points should be listed for Lemanik to keep track of. Please write the Action Points in a numbered List of Action Points with Task Description, Team in Charge, Deadline and Status (Opened/Closed).",
            sub_value: [],
          });
        }

        setAgendaPoints(updatedAgendaPoints);
      } catch (error) {
        handleError("Error fetching minute details", error.message);
      }
    };

    fetchData();
  }, [selectedMinutesId]);

  const saveAgenda = (index) => {
    const agenda = agendaPoints[index];
    const storageKey = `editedAgendaPoint-${selectedMinutesId}-${index}`;

    localStorage.setItem(storageKey, JSON.stringify(agenda));

    // Add this storageKey to agendaStorageKeys array in local storage
    const storedKeys = JSON.parse(
      localStorage.getItem("agendaStorageKeys") || "[]"
    );
    if (!storedKeys.includes(storageKey)) {
      storedKeys.push(storageKey);
      //console.log(`Added key to agendaStorageKeys: ${storageKey}`);
      localStorage.setItem("agendaStorageKeys", JSON.stringify(storedKeys));
      //console.log(
      //  "Agenda Storage Keys After Save:",
      // JSON.parse(localStorage.getItem("agendaStorageKeys"))
      //);
    }
  };

  const handleInputChange = (index, type, value, subIndex = null) => {
    const updatedAgendaPoints = [...agendaPoints];

    if (type === "heading") {
      updatedAgendaPoints[index].heading = value;
    } else if (type === "main_value") {
      updatedAgendaPoints[index].main_value = value;
    } else if (type === "sub_value" && subIndex !== null) {
      updatedAgendaPoints[index].sub_value[subIndex] = value;
    }

    setAgendaPoints(updatedAgendaPoints);
    // Save to local storage
    saveAgenda(index);
  };

  //   const handleAddAgendaPoint = (value) => {
  //     if (!selectedMinutesId) {
  //         alert("Please select a minute first!");
  //         return;
  //     }

  //     const newAgendaPoint = {
  //         heading: value,
  //         main_value: "",
  //         sub_value: [],
  //     };

  //     setAgendaPoints((prevAgendaPoints) => [
  //         ...prevAgendaPoints.slice(0, prevAgendaPoints.length - 1), // Take all items except the last one
  //         newAgendaPoint,
  //         ...prevAgendaPoints.slice(prevAgendaPoints.length - 1), // Take only the last item
  //     ]);
  // };

  const handleAddAgendaPoint = (value) => {
    if (!selectedMinutesId) {
      alert("Please select a minute first!");
      return;
    }

    const newAgendaPoint = {
      heading: value,
      main_value: "",
      sub_value: [],
    };

    // Construct the new state first
    const updatedAgendaPoints = [
      ...agendaPoints.slice(0, agendaPoints.length - 1), // All items except the last one
      newAgendaPoint, // New agenda point
      agendaPoints[agendaPoints.length - 1], // Only the last item
    ];

    // Save the new agenda point
    const newIndex = updatedAgendaPoints.length - 2; // This is where the new agenda point is added (second last index)
    saveAgendaUsingValue(newIndex, newAgendaPoint); // Use a modified version of saveAgenda

    // Finally, update the state
    setAgendaPoints(updatedAgendaPoints);
  };

  const saveAgendaUsingValue = (index, agendaToSave) => {
    const storageKey = `editedAgendaPoint-${selectedMinutesId}-${index}`;

    localStorage.setItem(storageKey, JSON.stringify(agendaToSave));

    // Add this storageKey to agendaStorageKeys array in local storage
    const storedKeys = JSON.parse(
      localStorage.getItem("agendaStorageKeys") || "[]"
    );
    if (!storedKeys.includes(storageKey)) {
      storedKeys.push(storageKey);
      localStorage.setItem("agendaStorageKeys", JSON.stringify(storedKeys));
    }
  };

  // const deleteHeading = (index) => {
  //   // Clone the current agendaPoints array
  //   const newAgendaPoints = [...agendaPoints];

  //   // Remove the agenda point (which includes heading, main_value, and sub_values) at the given index
  //   newAgendaPoints.splice(index, 1);

  //   // Update the state
  //   setAgendaPoints(newAgendaPoints);

  //   // Update the local storage
  //   const agenda = agendaPoints[index];
  //   const storageKey = `editedAgendaPoint-${selectedMinutesId}-${index}`;

  //   //console.log("heading storageKey", storageKey);

  //   // Remove the item from local storage
  //   localStorage.removeItem(storageKey);
  //   //console.log(`Removed individual key: ${storageKey}`);

  //   localStorage.setItem(storageKey, JSON.stringify(newAgendaPoints[index]));

  //   // Remove the storageKey from the agendaStorageKeys array in local storage
  //   const storedKeys = JSON.parse(
  //     localStorage.getItem("agendaStorageKeys") || "[]"
  //   );
  //   const updatedStoredKeys = storedKeys.filter((key) => key !== storageKey);
  //   localStorage.setItem(
  //     "agendaStorageKeys",
  //     JSON.stringify(updatedStoredKeys)
  //   );
  //   //console.log(
  //   //"Agenda Storage Keys After Deletion:",
  //   //JSON.parse(localStorage.getItem("agendaStorageKeys"))
  //   //);
  // };

  // Assuming axiosInstance is already configured and available
  // Function to delete an agenda point in the backend, then update the frontend
  const deleteHeading = async (index) => {
    // Define the payload for the API call
    const payload = {
      str_id: selectedMinutesId,
      index: index,
    };
    console.log("delete heading", payload);
    // Make a POST request to the backend to delete the agenda point using Axios
    try {
      const response = await axiosInstance.post(
        "/minutes/delete-minute-by-index",
        payload
      );

      // If the backend confirms deletion successfully
      toast({
        title: "Agenda point deletion",
        description: response.data.message,
        status: response.data.status,
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });

      // Clone the current agendaPoints array after confirmation of deletion
      const newAgendaPoints = [...agendaPoints];

      // Remove the agenda point at the given index
      newAgendaPoints.splice(index, 1);

      // Update the state with the new array
      setAgendaPoints(newAgendaPoints);

      // Remove the item from local storage
      const storageKey = `editedAgendaPoint-${selectedMinutesId}-${index}`;
      localStorage.removeItem(storageKey);

      // Update the agendaStorageKeys in local storage to reflect the deletion
      const storedKeys = JSON.parse(
        localStorage.getItem("agendaStorageKeys") || "[]"
      );
      const updatedStoredKeys = storedKeys.filter((key) => key !== storageKey);
      localStorage.setItem(
        "agendaStorageKeys",
        JSON.stringify(updatedStoredKeys)
      );

      console.log(
        "Agenda point deleted successfully in the backend",
        response.data
      );
    } catch (error) {
      console.error("Error deleting agenda point:", error);
      // Optionally, handle error state in the UI here
    }
  };

  const handleAddMainValue = (index, mainValue) => {
    const newAgendaPoints = [...agendaPoints];
    newAgendaPoints[index].main_value = mainValue;
    setAgendaPoints(newAgendaPoints);
    saveAgenda(index);
  };

  const deleteMainValue = (index) => {
    // Clone the current agendaPoints array
    const newAgendaPoints = [...agendaPoints];

    // Remove the main value and its associated sub_values at the given index
    newAgendaPoints[index].main_value = "";
    newAgendaPoints[index].sub_value = [];

    // Update the state
    setAgendaPoints(newAgendaPoints);

    // Update the local storage
    const agenda = agendaPoints[index];
    const storageKey = `editedAgendaPoint-${selectedMinutesId}-${index}`;

    //console.log("storageKey", storageKey);

    // Update the item in local storage
    localStorage.setItem(storageKey, JSON.stringify(newAgendaPoints[index]));

    // Since the agenda point still exists (only main_value and sub_values were deleted),
    // there's no need to remove the storageKey from the agendaStorageKeys array in local storage.
  };

  const handleAddSubValue = (index, subValue) => {
    const newAgendaPoints = [...agendaPoints];
    newAgendaPoints[index].sub_value.push(subValue);
    setAgendaPoints(newAgendaPoints);
    saveAgenda(index);
  };

  const deleteSubValue = (index, subIndex) => {
    // Clone the current agendaPoints array
    const newAgendaPoints = [...agendaPoints];
    // Remove the sub-value at the given subIndex from the main value at the given index
    newAgendaPoints[index].sub_value.splice(subIndex, 1);
    // Update the state
    setAgendaPoints(newAgendaPoints);
    // Save the updated value to localStorage
    saveAgenda(index);
  };

  const generateMinutes = async (options) => {
    const { uniqueKey, setLoading, sendRequest } = options;

    setLoading((prev) => ({ ...prev, [uniqueKey]: true }));

    toast({
      title: "Generating...",
      description:
        "Your Minutes are being Generated. File will be sent to your email. You can continue using the app.",
      status: "info",
      duration: 5000,
      isClosable: true,
      position: "bottom-right",
    });

    try {
      const response = await sendRequest();

      if (response.status === 200) {
        setSuccessfulGenerations((prev) => ({ ...prev, [uniqueKey]: true }));
        setSuccessfulGenerateAll((prev) => ({ ...prev, [uniqueKey]: true }));
        toast({
          title: "Minutes Generated Successfully",
          description:
            "File will be sent to your email or You can download it!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom-right",
        });
      } else {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      handleError("Error generating minute", error.message);
      toast({
        title: "Generating Minutes Failed",
        description: "There was an error in Generating Minutes.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-center",
      });
    } finally {
      setLoading((prev) => ({ ...prev, [uniqueKey]: false }));
    }
  };

  const handleGenerateMinute = async (agendaIndex) => {
    if (isGenerating) {
      return; // Do not proceed if another button is being processed.
    }

    setIsGenerating(true); // Set the state to indicate a button is being processed.

    const uniqueKey = `${selectedMinutesId}-${agendaIndex}`;
    const sendRequest = () =>
      sendAgendaPointsToBackend(
        selectedMinutesId,
        agendaPoints,
        token,
        agendaPoints[agendaIndex],
        agendaIndex
      );

    try {
      await generateMinutes({
        uniqueKey,
        setLoading: setLoadingAgendas,
        sendRequest,
      });
    } finally {
      setIsGenerating(false); // Reset the state after completion.
    }
  };

  const handleGenerateAllClick = async () => {
    setIsGeneratingAll(true); // Set to true when starting the generation process
    const uniqueKey = selectedMinutesId;
    const sendRequest = () =>
      sendAgendaPointsToBackend(selectedMinutesId, agendaPoints, token);

    await generateMinutes({
      uniqueKey,
      setLoading: setLoadingGenerateAll,
      sendRequest,
    });
    try {
      const minuteDetails = await fetchMinuteDetails(selectedMinutesId, token);
      let fetchedAgendaPoints = minuteDetails
        ? Object.values(
            minuteDetails.minute_user_prompt ||
              minuteDetails?.system_prompt?.agenda_point
          )
        : [];

      setAgendaPoints(fetchedAgendaPoints);
    } catch (error) {
      handleError("Error fetching minute details", error.message);
    }

    setIsGeneratingAll(false);
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const details = await fetchMinuteDetails(selectedMinutesId, token);

        if (details && details.minute_user_prompt) {
          const uniqueKey = selectedMinutesId;
          setSuccessfulGenerations((prev) => ({ ...prev, [uniqueKey]: true }));
          setSuccessfulGenerateAll((prev) => ({ ...prev, [uniqueKey]: true }));
        }
      } catch (error) {
        handleError("Error fetching minute details", error.message);
      }
    };

    fetchDetails();
  }, [selectedMinutesId, token]);

  const fetchPreview = async (selectedMinutesId, index, token) => {
    try {
      const response = await handlePreview(selectedMinutesId, index, token);
      setOutputText(response); // Assuming the API returns the output as plain text
      onOpen();
      // If output comes in a different format, adjust accordingly
    } catch (error) {
      console.error("Failed to fetch the preview:", error.message);
      setOutputText(""); // Reset the output text in case of error
      toast({
        title: "Preview not available",
        description: "Sorry, we couldn't fetch the preview for you.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-right",
      });
    }
  };

  const generateAllButtonColorScheme =
    successfulGenerations[selectedMinutesId] ||
    successfulGenerateAll[selectedMinutesId]
      ? "green.500"
      : "blue.500"; // Replace 'yourDefaultColorScheme' with the color you use when it's not successful.

  const shouldShowGenerateMinuteButtons =
    generateAllButtonColorScheme === "green.500";

  // colorMode === "dark" ? "green.500" : "green.500"
  //   : colorMode === "dark" ? "#3182CE" : "blue.500"
  return (
    <Box
      flex="4"
      bg={colorMode === "dark" ? "gray.700" : "white"}
      color={colorMode === "dark" ? "white" : "black"}
      display="flex"
      flexDirection="column"
      mr={2}
      mt={2}
      ml={0}
      mb={2}
      borderRadius="lg"
      p={4}
      position="relative" // Added this for absolute positioning of the button
    >
      {selectedMinutesId && (
        <Button
          bgColor={generateAllButtonColorScheme}
          textColor="white"
          _hover={{}}
          onClick={handleGenerateAllClick}
          size="sm"
          position="absolute"
          top="10px"
          right="10px"
          isLoading={loadingGenerateAll[selectedMinutesId]}
          loadingText="Generating.."
          isDisabled={isGenerating || isGeneratingAll} // Disable the button based on both states
        >
          Generate All
        </Button>
      )}

      <Text
        fontSize="md"
        fontWeight="semibold"
        ml={2}
        mb="4px"
        color={colorMode === "dark" ? "white" : "#1A202C"}
      >
        Generate Minutes
      </Text>

      <Heading
        fontSize="xs"
        color={colorMode === "dark" ? "white" : "blackAlpha.700"}
        mb={0}
        pl={2}
        pb={2}
      >
        Add the Information.
      </Heading>

      <Divider mt={0} mb={0} borderColor="#E2E8F0" />
      <Box
        pl={1}
        overflowY="scroll"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        {selectedMinutesId && (
          <Box mb={2} mt={2}>
            <DynamicInput title="Agenda Point" onAdd={handleAddAgendaPoint} />
          </Box>
        )}

        {agendaPoints.map((agenda, index) => {
          // Declare the uniqueLoadingKey here:
          const uniqueLoadingKey = `${selectedMinutesId}-${index}`;

          return (
            <Box
              key={index}
              mt={2}
              borderWidth="1px"
              borderRadius="md"
              p={3}
              mb={1}
            >
              <Heading as="h3" size="md" mb={2} maxW="95%">
                {editing &&
                editing.type === "heading" &&
                editing.index === index ? (
                  <Box display="flex" alignItems="center">
                    <Input
                      size="xs"
                      value={agenda.heading}
                      onChange={(e) =>
                        handleInputChange(index, "heading", e.target.value)
                      }
                      onBlur={() => setEditing(null)}
                      autoFocus
                    />
                    <Button
                      size="xs"
                      ml={2}
                      onClick={() => setEditing(null)}
                      _hover={{}}
                      bgColor="blue.500"
                      textColor="white"
                    >
                      Save
                    </Button>
                  </Box>
                ) : (
                  <>
                    {agenda.heading.toUpperCase()}
                    <IconButton
                      variant="ghost"
                      aria-label="Edit Heading"
                      icon={<EditIcon />}
                      colorScheme="blue"
                      boxSize="10px"
                      size="xs"
                      ml={2}
                      onClick={() => setEditing({ type: "heading", index })}
                    />
                    <IconButton
                      variant="ghost"
                      aria-label="Delete Heading"
                      icon={<DeleteIcon />}
                      color="black"
                      boxSize="10px"
                      size="xs"
                      ml={0}
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure you want to delete the heading`
                          )
                        ) {
                          deleteHeading(index);
                        }
                      }}
                      // onClick={() => deleteHeading(index)}
                    />
                  </>
                )}
              </Heading>

              <List spacing={2} styleType="disc" fontSize="sm" maxW="95%">
                {editing &&
                editing.type === "main" &&
                editing.index === index ? (
                  <Box display="flex" alignItems="center">
                    <Input
                      size="xs"
                      value={agenda.main_value}
                      onChange={(e) =>
                        handleInputChange(index, "main_value", e.target.value)
                      }
                      onBlur={() => setEditing(null)}
                      autoFocus
                    />
                    <Button
                      size="xs"
                      ml={2}
                      onClick={() => setEditing(null)}
                      _hover={{}}
                      bgColor="blue.500"
                      textColor="white"
                    >
                      Save
                    </Button>
                  </Box>
                ) : (
                  agenda.main_value && (
                    <ListItem>
                      {agenda.main_value}
                      <IconButton
                        variant="ghost"
                        aria-label="Edit Main Value"
                        icon={<EditIcon />}
                        colorScheme="blue"
                        boxSize="10px"
                        size="xs"
                        ml={2}
                        onClick={() => setEditing({ type: "main", index })}
                      />
                      <IconButton
                        variant="ghost"
                        aria-label="Delete Main Value"
                        icon={<DeleteIcon />}
                        color="black"
                        boxSize="10px"
                        size="xs"
                        ml={0}
                        onClick={() => {
                          if (
                            window.confirm(
                              `Are you sure you want to delete the main value`
                            )
                          ) {
                            deleteMainValue(index);
                          }
                        }}
                        // onClick={() => deleteMainValue(index)}
                      />
                    </ListItem>
                  )
                )}
                {!agenda.main_value && !editing && (
                  <Box mt={3}>
                    <DynamicInput
                      title="Main Value"
                      onAdd={(mainValue) =>
                        handleAddMainValue(index, mainValue)
                      }
                    />
                  </Box>
                )}
                {agenda.sub_value.length > 0 &&
                  agenda.sub_value.map((subItem, subIndex) => (
                    <List
                      ml={4}
                      style={{ listStyleType: "circle" }}
                      fontSize="xs"
                      maxW="95%"
                    >
                      {editing &&
                      editing.type === "sub" &&
                      editing.index === index &&
                      editing.subIndex === subIndex ? (
                        <Box display="flex" alignItems="center">
                          <Input
                            size="xs"
                            value={subItem}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "sub_value",
                                e.target.value,
                                subIndex
                              )
                            }
                            onBlur={() => setEditing(null)}
                            autoFocus
                          />
                          <Button
                            size="xs"
                            ml={2}
                            onClick={() => setEditing(null)}
                            _hover={{}}
                            bgColor={
                              colorMode === "dark" ? "blue.500" : "blue.500"
                            }
                            textColor="white"
                          >
                            Save
                          </Button>
                        </Box>
                      ) : (
                        <ListItem>
                          {subItem}
                          <IconButton
                            variant="ghost"
                            aria-label="Edit Sub Value"
                            icon={<EditIcon />}
                            colorScheme="blue"
                            boxSize="10px"
                            size="xs"
                            ml={2}
                            onClick={() =>
                              setEditing({ type: "sub", index, subIndex })
                            }
                          />
                          <IconButton
                            variant="ghost"
                            aria-label="Delete Sub Value"
                            icon={<DeleteIcon />}
                            color="black"
                            boxSize="10px"
                            size="xs"
                            ml={0}
                            onClick={() => {
                              if (
                                window.confirm(
                                  `Are you sure you want to delete the sub agenda`
                                )
                              ) {
                                deleteSubValue(index, subIndex);
                              }
                            }}
                            // onClick={() => deleteSubValue(index, subIndex)}
                          />
                        </ListItem>
                      )}
                    </List>
                  ))}
                {agenda.main_value && (
                  <Box mt={3}>
                    <DynamicInput
                      title="Sub Agenda"
                      onAdd={(subValue) => handleAddSubValue(index, subValue)}
                    />
                  </Box>
                )}
              </List>
              {shouldShowGenerateMinuteButtons && (
                <Button
                  key={`${selectedMinutesId}-${index}`}
                  _hover={{}}
                  bgColor="blue.500"
                  textColor="white"
                  onClick={() => handleGenerateMinute(index)}
                  mt={2}
                  isLoading={loadingAgendas[`${selectedMinutesId}-${index}`]}
                  loadingText="Generating..."
                  isDisabled={isGenerating || isGeneratingAll}
                >
                  Generate Minute
                </Button>
              )}
              {shouldShowGenerateMinuteButtons && (
                <Button
                  bgColor="teal.500"
                  textColor="white"
                  _hover={{}}
                  onClick={() => {
                    fetchPreview(selectedMinutesId, index, token);
                    setModalHeader(agenda.heading || "Output"); // If the heading is null or undefined, it will default to "Output"
                  }}
                  ml={3}
                  mt={2}
                >
                  Preview
                </Button>
              )}
            </Box>
          );
        })}
        {selectedMinutesId && (
          <FileDownload
            documentId={documentId}
            selectedMinutesId={selectedMinutesId}
          />
        )}
        {selectedMinutesId && (
          <FileSent selectedMinutesId={selectedMinutesId} />
        )}
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent w="100%" h="80%">
          <ModalHeader mt={3}>{modalHeader}</ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody
            overflowY="scroll"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <Text style={{ whiteSpace: "pre-line" }}>
              {outputText ? outputText : "No Preview Available"}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default GenerateMinutes;
