import React, { useEffect, useState } from "react";
import axiosInstance from "../../helpers/axiosInstance";
import {
  Box,
  Flex,
  IconButton,
  Switch,
  Divider,
  Input,
  FormControl,
  FormLabel,
  VStack,
  Spacer,
  Select,
} from "@chakra-ui/react";
import { useColorMode, Text, useToast } from "@chakra-ui/react";
import { InputGroup, InputRightElement } from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { fetchModelList } from "../../api/chat/api";

const OpenaiCredentials = ({
  selectedModel,
  setSelectedModel,
  temperature,
  setTemperature,
  deepSearch, // Add this prop to control email alerts
  setDeepSearch, // Add this prop to set email alerts
}) => {
  const { colorMode } = useColorMode();
  const [modelOptions, setModelOptions] = useState([]); // State for the list of models
  const toast = useToast();

  useEffect(() => {
    localStorage.setItem("selectedModel", JSON.stringify(selectedModel));
  }, [selectedModel]);

  //........................................................................
  useEffect(() => {
    localStorage.setItem("temperature", JSON.stringify(temperature));
  }, [temperature]);

  useEffect(() => {
    localStorage.setItem("deepSearch", JSON.stringify(deepSearch));
  }, [deepSearch]);

  // Handle email alerts toggle
  const handleEmailAlertsToggle = () => {
    setDeepSearch(!deepSearch); // Toggle email alerts
  };

  useEffect(() => {
    const loadModels = async () => {
      try {
        const modelList = await fetchModelList();
        //console.log("fetchModelList", modelList);
        if (Array.isArray(modelList)) {
          setModelOptions(modelList);
        } else {
          throw new Error("Received data is not an array");
        }
      } catch (error) {
        console.error("Failed to fetch models:", error);
        setModelOptions([]); // Set to default or empty array on error

        toast({
          title: "Error fetching models",
          description:"An error occurred while fetching models.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-right", // or any other position you prefer
        });
      }
    };

    loadModels();
  }, []);

  return (
    <Box
      flex="1"
      bg={colorMode === "dark" ? "gray.700" : "white"}
      borderTopRadius="lg"
      p={4}
    >
      <VStack spacing={3} alignItems="start">
        {/* Model Selection */}
        <Text
          fontSize="md"
          fontWeight="semibold"
          mb={0}
          color={colorMode === "dark" ? "gray.200" : "#1A202C"}
        >
          Search Setting
        </Text>
        <Divider mt={0} mb={0} />
        <FormControl display="flex" alignItems="center">
          <FormLabel
            htmlFor="deep-serach"
            mb="0"
            display="flex"
            flexDirection="column"
          >
            Deep Search
            <Text
              fontSize="sm"
              color={colorMode === "dark" ? "gray.500" : "blackAlpha.700"}
              mt="0"
              mb={0}
            >
              360° of Analysis
            </Text>
          </FormLabel>
          <Switch
            mb={2}
            id="deep-serach"
            isChecked={deepSearch} // Set the switch state based on deepSearch prop
            onChange={handleEmailAlertsToggle} // Handle switch toggle
          />
        </FormControl>

        <Divider mt={0} mb={0} />

        <FormControl id="modelSelection">
          <FormLabel>Model Selection</FormLabel>
          <Select
            placeholder="Select a model"
            value={selectedModel}
            onChange={(e) => setSelectedModel(e.target.value)}
          >
            {modelOptions.map((model) => (
              <option key={model.value} value={model.value}>
                {model.label}
              </option>
            ))}
          </Select>
        </FormControl>

        {/* Input Fields */}
        {/* <FormControl id="temperature">
          <FormLabel>Creativity</FormLabel>
          <Input
            type="number"
            value={temperature}
            onChange={(e) => setTemperature(e.target.value)}
          />
        </FormControl> */}

        {/* <FormControl id="temperature">
          <FormLabel>Creativity</FormLabel>
          <Input
            type="number"
            value={temperature}
            onChange={(e) => {
              const val = e.target.value;
              if (val === "0" || val === "1") {
                setTemperature(val);
              }
            }}
            min="0"
            max="1"
            step="1"
          />
        </FormControl> */}
        {/* <FormControl id="temperature">
          <FormLabel>Creativity</FormLabel>
          <InputGroup>
            <Input type="text" value={temperature} />
            <InputRightElement>
              <VStack spacing={0}>
                <IconButton
                  size="xs" // reduce the size to better fit inside the input
                  variant="unstyled"
                  icon={<ChevronUpIcon />}
                  aria-label="Increase"
                  onClick={() => {
                    console.log(
                      "Increase button clicked, current temperature:",
                      temperature
                    );
                    if (temperature === "0") {
                      setTemperature("1");
                    }
                  }}
                />
                <IconButton
                  size="xs" // reduce the size to better fit inside the input
                  variant="unstyled"
                  icon={<ChevronDownIcon />}
                  aria-label="Decrease"
                  onClick={() => {
                    console.log(
                      "Decrease button clicked, current temperature:",
                      temperature
                    );
                    if (temperature === "1") {
                      setTemperature("0");
                    }
                  }}
                />
              </VStack>
            </InputRightElement>
          </InputGroup>
        </FormControl> */}
      </VStack>

      <Spacer />
    </Box>
  );
};
export default OpenaiCredentials;
