import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  Input,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  FormControl,
  FormLabel,
  useToast,
  Tooltip,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  IconButton,
  VStack,
  Text,
} from "@chakra-ui/react";
import { InfoOutlineIcon, CloseIcon } from "@chakra-ui/icons";
import CustomSelect from "../common/CustomSelect";
import { fetchModules } from "../../api/fetchModules";
import RoleDropdown from "../common/RoleDropdown";
import axiosInstance from "../../helpers/axiosInstance";

const Settings = ({ isOpen, onClose }) => {
  const [frequency, setFrequency] = useState("");
  const [lastEmail, setLastEmail] = useState("");
  const toast = useToast();
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedModule, setSelectedModule] = useState("");
  const [modules, setModules] = useState([]); // Existing states and useEffect
  //const [excludes, setExcludes] = useState([]);
  const [frequencyMap, setFrequencyMap] = useState("");
  const [lastEmailMap, setLastEmailMap] = useState("");
  const [selectedRoleMap, setSelectedRoleMap] = useState(null);
  const [selectedModuleMap, setSelectedModuleMap] = useState("");
  //const [excludesMap, setExcludesMap] = useState([]);
  //const excludeInputRef = useRef(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const fetchModuleData = async () => {
      try {
        const modulesData = await fetchModules();
        // Use the roles data however you need in this component
        setModules(
          modulesData.map((module) => ({ label: module, value: module }))
        );
      } catch (error) {}
    };

    fetchModuleData();
  }, []);

  const handleModuleClick = (module) => {
    setSelectedModule(module);
  };

  // const handleExcludeKeyPress = (event) => {
  //   if (event.key === "Enter" && event.target.value.trim() !== "") {
  //     event.preventDefault(); // Prevent form submission
  //     if (!excludes.includes(event.target.value.trim())) {
  //       setExcludes([...excludes, event.target.value.trim()]);
  //       event.target.value = ""; // Clear input after adding
  //     } else {
  //       toast({
  //         title: "Duplicate entry",
  //         description: "This item is already in the list.",
  //         status: "warning",
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //     }
  //   }
  // };

  // const removeExclude = (excludeToRemove) => {
  //   setExcludes(excludes.filter((exclude) => exclude !== excludeToRemove));
  // };

  // const handleClickBox = () => {
  //   excludeInputRef.current?.focus();
  // };

  const handleSubmit = async () => {
    if (
      !frequency ||
      lastEmail === "" ||
      !selectedModule ||
      !selectedRole 
      // ||
      // excludes.length === 0
    ) {
      toast({
        title: "Invalid input",
        description: "Please fill in all fields correctly.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const payload = {
      frequency,
      lastEmail: parseInt(lastEmail, 10),
      module_name: selectedModule,
      persona: selectedRole,
      exclude: "joe@example.com",
    };

    // Here you would send the payload to your endpoint "email/email-schedule"
    // This is a placeholder for where you would make your API call.
    //console.log("Submitting payload:", payload);
    try {
      const response = await axiosInstance.post(
        "email/schedule-drafts",

        payload
      );
      onClose(); // Close the modal after submission
      setFrequency();
      setLastEmail();
      setSelectedRole();
      setSelectedModule();
      // setExcludes([]);

      if (response.status === 200) {
        toast({
          title: "Settings Success",
          description: "Settings submitted successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {}
  };

  function handleFrequencyChange(e) {
    const value = parseInt(e.target.value, 10);
    // If the parsed value is not negative, set the value, otherwise reset to 0 or keep the positive value
    if (!isNaN(value) && value >= 0) {
      setLastEmail(value.toString());
    } else if (isNaN(value)) {
      // If the field is cleared, reset the state
      setLastEmail("");
    }
  }

  const fetchScheduleDrafts = async () => {
    try {
      const response = await axiosInstance.get("email/schedule-drafts");
      const data = response.data.response; // Adjust according to actual response structure
      // Assuming the response data structure matches your state structure
      if (data) {
        setFrequencyMap(data.frequency || "");
        setLastEmailMap(data.lastEmail ? data.lastEmail.toString() : "");
        setSelectedModuleMap(data.module_name || "");
        setSelectedRoleMap(data.persona ? data.persona.name : ""); // Ensure you're accessing a property that exists
        // setExcludesMap(data.exclude || []);
      }
    } catch (response) {
      if (response.status === 404) {
        toast({
          title: "Schedule Settings",
          description: "Schedule Settings Empty",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    if (tabIndex === 1) {
      fetchScheduleDrafts();
    }
  }, [tabIndex]);

  // Example function to delete a schedule draft by ID
  const deleteScheduleDraft = async () => {
    try {
      const response = await axiosInstance.delete(`email/schedule-drafts`);
      // Handle successful deletion
      // For example, you might want to remove the deleted item from the UI or fetch the updated list
      toast({
        title: "Deleted",
        description: "The schedule draft has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setFrequencyMap("");
      setLastEmailMap("");
      setSelectedRoleMap(null);
      setSelectedModuleMap("");
      // setExcludesMap([]);
    } catch (error) {
      // Handle error case
      toast({
        title: "Error",
        description:
          error.response?.data?.message ||
          "There was an error deleting the schedule draft.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Automate Draft Email</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Tabs
            isFitted
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
          >
            <TabList mb="1em">
              <Tab>Settings</Tab>
              <Tab>Other Settings</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <FormControl>
                  <FormLabel>Frequency</FormLabel>
                  <Select
                    placeholder="Select frequency"
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                  >
                    <option value="30 minutes">30 minutes</option>
                    <option value="60 minutes">60 minutes</option>
                    <option value="2 hours">2 hours</option>
                    <option value="3 hours">3 hours</option>
                    <option value="5 hours">5 hours</option>
                  </Select>
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>
                    Email History Count
                    <Tooltip
                      label="Amount of emails that need to be known by Agent to generate more accurate emails."
                      aria-label="A tooltip"
                      bgColor="white"
                      textColor="black"
                      borderColor="gray.500"
                      border="1px solid"
                      borderRadius="md"
                    >
                      <span>
                        <InfoOutlineIcon
                          color="gray.500"
                          ml={2}
                          cursor="pointer"
                        />
                      </span>
                    </Tooltip>
                  </FormLabel>
                  <Input
                    placeholder="Enter the number"
                    value={lastEmail}
                    onChange={handleFrequencyChange}
                  />
                </FormControl>

                <FormLabel mt={4}>Agent</FormLabel>
                <CustomSelect
                  options={modules}
                  value={selectedModule}
                  onChange={(e) => handleModuleClick(e.target.value)}
                  placeholder="Select agent"
                  size="md"
                  borderRadius="md"
                  flex="1"
                  mt={2}
                  mb={4}
                />
                <FormLabel>Persona</FormLabel>
                <RoleDropdown
                  selectedRole={selectedRole}
                  setSelectedRole={setSelectedRole}
                  selectedModule={selectedModule}
                  setSelectedModule={setSelectedModule}
                  size="md"
                />
                {/* <FormControl mt={4}>
                  <FormLabel>Exclude</FormLabel>
                  <Box
                    onClick={handleClickBox}
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    padding="2"
                    borderRadius="md"
                    border="1px solid"
                    borderColor="gray.300"
                    minH="10"
                    cursor="text"
                  >
                    {excludes.map((exclude, index) => (
                      <Tag size="md" key={index} borderRadius="full" margin="1">
                        <TagLabel>{exclude}</TagLabel>
                        <TagCloseButton
                          onClick={() => removeExclude(exclude)}
                        />
                      </Tag>
                    ))}
                    <Input
                      ref={excludeInputRef}
                      variant="unstyled"
                      size="sm"
                      minWidth="30px"
                      outline="none"
                      border="none"
                      _focus={{ boxShadow: "none" }}
                      placeholder={excludes.length ? "" : "joe@example.com"}
                      onKeyPress={handleExcludeKeyPress}
                    />
                  </Box>
                </FormControl> */}
              </TabPanel>
              <TabPanel>
                <Box
                  p={4} // Padding inside the box
                  borderWidth="1px" // Width of the border
                  borderRadius="lg" // Border radius for rounded corners
                  borderColor="gray.200" // Color of the border
                  boxShadow="sm" // Optional: adds a slight shadow for depth
                >
                  <VStack spacing={4} align="stretch">
                    {frequencyMap ||
                    lastEmailMap ||
                    selectedModuleMap ||
                    selectedRoleMap ? (
                     // excludesMap.length > 0 ? (
                      <>
                        <Flex
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {frequencyMap && (
                            <Text>
                              <strong>Frequency:</strong> {frequencyMap}
                            </Text>
                          )}
                          <Tooltip
                            label="Delete Settings"
                            aria-label="A tooltip"
                          >
                            <IconButton
                              aria-label="Delete all fields"
                              icon={<CloseIcon />}
                              size="sm"
                              onClick={deleteScheduleDraft}
                              color="black" // Change the icon color to black
                              bgColor="red.400"
                              _hover={{}}
                              mb={2}
                            />
                          </Tooltip>
                        </Flex>
                        {lastEmailMap && (
                          <Text>
                            <strong>Last Email:</strong> {lastEmailMap}
                          </Text>
                        )}
                        {selectedModuleMap && (
                          <Text>
                            <strong>Agent:</strong> {selectedModuleMap}
                          </Text>
                        )}
                        {selectedRoleMap && (
                          <Text>
                            <strong>Persona:</strong> {selectedRoleMap}
                          </Text>
                        )}
                        {/* {excludesMap.length > 0 && (
                          <Text>
                            <strong>Excludes:</strong> {excludesMap.join(", ")}
                          </Text>
                        )} */}
                      </>
                    ) : (
                      <Text>No settings available.</Text>
                    )}
                  </VStack>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        {tabIndex === 0 && (
          <ModalFooter>
            <Button onClick={onClose} mr={3}>
              Cancel
            </Button>

            <Button colorScheme="blue" onClick={handleSubmit}>
              Schedule
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default Settings;
