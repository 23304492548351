import React, { useState, useEffect } from 'react';
import { Select } from '@chakra-ui/react';
import axiosInstance from '../../helpers/axiosInstance'; // Ensure this path is correct
import usePersistedState from '../../components/usePersistedState'; // Ensure the path is correct
import { useToast } from "@chakra-ui/react";

const API_BASE_URL = "/outlook-mail-box"; // Base URL for your APIs

const EmailDropdown = ({ selectedEmail, setSelectedEmail }) => {
  const [credentials, setCredentials] = usePersistedState('emailsDDQ_RFP', []);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const fetchCredentials = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`${API_BASE_URL}/get-all-credentials`);
        if (response.status === 200) {
          setCredentials(response.data.data.map(cred => ({ label: cred.outlook_email, value: cred.outlook_email })));
        } else {
          throw new Error("Failed to fetch credentials");
        }
      } catch (error) {
        toast({
          title: "Error fetching emails",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchCredentials();
  }, [setCredentials, toast]);

  const handleEmailChange = (e) => {
    setSelectedEmail(e.target.value);
  };

  return (
    <Select
      placeholder="Select email"
      onChange={handleEmailChange}
      value={selectedEmail}
      isDisabled={loading || credentials.length === 0}
      size="sm"
      borderRadius="md"
    >
      {credentials.map((cred, index) => (
        <option key={index} value={cred.value}>
          {cred.label}
        </option>
      ))}
    </Select>
  );
};

export default EmailDropdown;
