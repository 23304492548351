import { getAuthHeader } from '../helpers/auth';
import axiosInstance from '../helpers/axiosInstance';

export const fetchModules = async () => {
  //console.log("In fetch Modules function");
  try {
    const response = await axiosInstance.get("/genie/modules");
    if (response.status !== 200) {
      throw new Error(`Error: ${response.status}`);
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};
