import { Flex, Image, useColorMode } from "@chakra-ui/react";
import logo from "../assets/images/NEXUSAIMS_dark_version.png";
import darkLogo from "../assets/images/NEXUSAIMS_light_version.png";

export const Logo = (props) => {
  const { colorMode } = useColorMode(); // useColorMode called inside the component

  return (
    <Flex alignItems="center" justifyContent="center">
      <Image
        width="100px"
        height="auto"
        objectFit="cover"
        src={colorMode === "light" ? logo : darkLogo}
        {...props}
      />
    </Flex>
  );
};
