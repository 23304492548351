import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Heading,
  useColorMode,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  ButtonGroup,
  Select,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import Sidebar from "../Sidebar";
import EmailHeader from "./EmailHeader";
import EmailThreadModal from "./EmailThreadModal";
import axiosInstance from "../../../helpers/axiosInstance";

function Emails() {
  const { colorMode } = useColorMode();
  const { search } = useLocation();
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [emails, setEmails] = useState([]);
  const [filteredEmails, setFilteredEmails] = useState([]);
  const [filters, setFilters] = useState({
    sender: "",
    subject: "",
    is_replied: "",
    is_bounced: "",
    is_opened: "",
    is_subscribed: "",
    feedback: "",
    is_opened_count: "",
    is_ooo: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeEmailId, setActiveEmailId] = useState(null);

  // Define feedback colors
  const feedbackColors = {
    Red: "red",
    Green: "green",
    Grey: "gray",
    Amber: "#FFBF00",
  };

  const getRowColor = (feedback) => feedbackColors[feedback] || "white";

  // Retrieve URL parameters and set initial state values
  useEffect(() => {
    const params = new URLSearchParams(search);
    const feedbackColor = params.get("feedback");
    const urlEmail = params.get("selectedEmail");
    const urlCampaign = params.get("selectedCampaign");

    if (urlEmail) setSelectedEmail(urlEmail);
    if (urlCampaign) setSelectedCampaign(urlCampaign);
    if (feedbackColor) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        feedback: feedbackColor,
      }));
    }
  }, [search]);

  const fetchCampaigns = async () => {
    try {
      const response = await axiosInstance.get("campaign/get-all-campaign");
      setCampaigns(response.data.data);
    } catch (error) {
      console.error("Failed to fetch campaigns:", error);
      setCampaigns([]);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchThreads = (campaignId, email) => {
    const payload = {
      campaign_id: campaignId,
      connected_email: email || "amnaikram97@outlook.com",
    };

    axiosInstance
      .post(`/threads/get-thread-emails`, payload)
      .then((response) => {
        if (response.data.success) {
          const data = response.data.data;
          const emailsList = data.email_list.map((email) => ({
            id: email.conversation_id,
            sender: email.recipient_email,
            subject: email.subject,
            is_replied: String(email.is_replied), // Convert to string
            is_bounced: email.is_bounced || 0,
            is_opened: String(email.is_opened), // Convert to string
            is_subscribed: String(email.is_subscribed), // Convert to string
            feedback: email.feedback || "No Feedback",
            is_opened_count: email.is_opened_count || 0,
            is_ooo: email.is_ooo || 0,
          }));

          setEmails(emailsList);
          setFilteredEmails(emailsList); // Set initial filtered data
          setTotalPages(Math.ceil(emailsList.length / 8));
        } else {
          setEmails([]);
          setFilteredEmails([]);
          setTotalPages(0);
        }
      })
      .catch((error) => {
        console.error("Error fetching threads:", error);
        setEmails([]);
        setFilteredEmails([]);
        setTotalPages(0);
      });
  };

  useEffect(() => {
    if (selectedEmail && selectedCampaign) {
      fetchThreads(selectedCampaign, selectedEmail);
    }
  }, [selectedCampaign, selectedEmail]);

  const handleCampaignChange = (e) => {
    const selectedCampaignId = e.target.value;
    setSelectedCampaign(selectedCampaignId);
    fetchThreads(selectedCampaignId, selectedEmail);
  };

  const handleEmailChange = (email) => {
    setSelectedEmail(email);
    if (selectedEmail) {
      fetchThreads(selectedCampaign, email);
    }
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
  };

  useEffect(() => {
    // Filter emails based on filter state
    const filtered = emails.filter((email) =>
      Object.keys(filters).every((key) =>
        filters[key]
          ? String(email[key])
              .toLowerCase()
              .includes(filters[key].toLowerCase())
          : true
      )
    );
    setFilteredEmails(filtered);
  }, [filters, emails]);

  const handleSubjectClick = (id) => {
    setActiveEmailId(id);
    onOpen();
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to get unique values for a specific field
  const getUniqueValues = (field) => {
    return [...new Set(emails.map((email) => email[field]))];
  };

  return (
    <Flex>
      <Sidebar />
      <Box flex="1" bg={colorMode === "dark" ? "gray.800" : "white"}>
        <EmailHeader
          handlePreviousClick={handlePreviousClick}
          handlePageChange={handlePageChange}
          handleNextClick={handleNextClick}
          totalPages={totalPages}
          currentPage={currentPage}
          selectedEmail={selectedEmail}
          handleEmailChange={handleEmailChange}
          selectedCampaign={selectedCampaign}
          handleCampaignChange={handleCampaignChange}
          campaigns={campaigns}
        />
        <Box
          overflowY="auto"
          bg={colorMode === "dark" ? "gray.700" : "white"}
          height="81vh"
        >
          <Table variant="simple" size="md">
            <Thead>
              <Tr>
                <Th>Email</Th>
                <Th>Subject</Th>
                <Th>Replied</Th>
                <Th>Bounced</Th>
                <Th>Opened</Th>
                <Th>Subscribed</Th>
                <Th>Feedback</Th>
                <Th>Email Opened Count</Th>
                <Th>Out of Office</Th>
              </Tr>
              {/* Filters Row */}
              <Tr>
                <Th>
                  <Select
                    size="xs"
                    placeholder="Filter Email"
                    value={filters.sender}
                    onChange={(e) =>
                      handleFilterChange("sender", e.target.value)
                    }
                  >
                    {getUniqueValues("sender").map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                </Th>
                <Th>
                  <Select
                    size="xs"
                    placeholder="Filter Subject"
                    value={filters.subject}
                    onChange={(e) =>
                      handleFilterChange("subject", e.target.value)
                    }
                  >
                    {getUniqueValues("subject").map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                </Th>
                <Th>
                  <Select
                    size="xs"
                    placeholder="Filter Replied"
                    value={filters.is_replied}
                    onChange={(e) =>
                      handleFilterChange("is_replied", e.target.value)
                    }
                  >
                    {getUniqueValues("is_replied").map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                </Th>
                <Th>
                  <Select
                    size="xs"
                    placeholder="Filter Bounced"
                    value={filters.is_bounced}
                    onChange={(e) =>
                      handleFilterChange("is_bounced", e.target.value)
                    }
                  >
                    {getUniqueValues("is_bounced").map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                </Th>
                <Th>
                  <Select
                    size="xs"
                    placeholder="Filter Opened"
                    value={filters.is_opened}
                    onChange={(e) =>
                      handleFilterChange("is_opened", e.target.value)
                    }
                  >
                    {getUniqueValues("is_opened").map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                </Th>
                <Th>
                  <Select
                    size="xs"
                    placeholder="Filter Subscribed"
                    value={filters.is_subscribed}
                    onChange={(e) =>
                      handleFilterChange("is_subscribed", e.target.value)
                    }
                  >
                    {getUniqueValues("is_subscribed").map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                </Th>
                <Th>
                  <Select
                    size="xs"
                    placeholder="Filter Feedback"
                    value={filters.feedback}
                    onChange={(e) =>
                      handleFilterChange("feedback", e.target.value)
                    }
                  >
                    {getUniqueValues("feedback").map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                </Th>
                <Th>
                  <Select
                    size="xs"
                    placeholder="Filter Opened Count"
                    value={filters.is_opened_count}
                    onChange={(e) =>
                      handleFilterChange("is_opened_count", e.target.value)
                    }
                  >
                    {getUniqueValues("is_opened_count").map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                </Th>
                <Th>
                  <Select
                    size="xs"
                    placeholder="Filter Out of Office"
                    value={filters.is_ooo}
                    onChange={(e) =>
                      handleFilterChange("is_ooo", e.target.value)
                    }
                  >
                    {getUniqueValues("is_ooo").map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Select>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredEmails.map((email, index) => (
                <Tr
                  key={index}
                  onClick={() => email.id && handleSubjectClick(email.id)}
                  backgroundColor={getRowColor(email.feedback)}
                  cursor="pointer"
                >
                  <Td>{email.sender}</Td>
                  <Td>{email.subject}</Td>
                  <Td textAlign="center">{email.is_replied}</Td>
                  <Td textAlign="center">{email.is_bounced}</Td>
                  <Td textAlign="center">{email.is_opened}</Td>
                  <Td textAlign="center">{email.is_subscribed}</Td>
                  <Td textAlign="center">{email.feedback}</Td>
                  <Td textAlign="center">{email.is_opened_count}</Td>
                  <Td textAlign="center">{email.is_ooo}</Td>
                </Tr>
              ))}
              {filteredEmails.length === 0 && (
                <Tr>
                  <Td colSpan={9} textAlign="center">
                    No emails to display
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <EmailThreadModal
        isOpen={isOpen}
        onClose={onClose}
        campaignId={selectedCampaign}
        connectedEmail={selectedEmail}
        conversationId={activeEmailId}
      />
    </Flex>
  );
}

export default Emails;
