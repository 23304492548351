import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Input,
  VStack,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Select,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { logout } from "../../auth";
import axiosInstance from "../../helpers/axiosInstance";

function ManagerPanel() {
  const [users, setUsers] = useState([]);
  const roles = ["Manager", "Employee"];
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState({}); // For form validation errors
  // Validate form data
  const validate = (data) => {
    const errors = {};
    if (!data.username) errors.username = "Username is required";
    if (!data.email) errors.email = "Email is required";
    if (!data.password) errors.password = "Password is required";
    if (!data.role) errors.role = "Role is required";
    return errors;
  };
  const initialState = {
    username: "",
    email: "",
    password: "",
    role: "",
  };

  const [newUserData, setNewUserData] = useState(initialState);
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5); // or whatever default limit you want
  const [totalPages, setTotalPages] = useState(1); // Initialize with 1

  const navbarHeight = 57;
  const buttonHeight = 40;
  const footerHeight = 40;
  const headingHeight = 40.5;
  const rowHeight = 73; // Adjust this value according to your desired row height
  const [isRoleChangeAlertOpen, setIsRoleChangeAlertOpen] = useState(false);
  const [isDeleteUserAlertOpen, setDeleteUserAlertOpen] = useState(false);
  const [userToChange, setUserToChange] = useState({ email: "", role: "" });
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const calculateRowsToFit = () => {
    const viewportHeight = window.innerHeight;
    const occupiedSpace =
      navbarHeight + buttonHeight + footerHeight + headingHeight;
    const availableSpace = viewportHeight - occupiedSpace;
    return Math.floor(availableSpace / rowHeight);
  };

  useEffect(() => {
    const handleResize = () => {
      setLimit(calculateRowsToFit());
    };

    // Calculate initially
    handleResize();

    // Update on resize
    window.addEventListener("resize", handleResize);

    return () => {
      // Cleanup
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [currentPage, limit]);

  const addUser = () => {
    //console.log("newUserData", newUserData);
    axiosInstance
      .post("/manager/add_user", newUserData)
      .then((response) => {
        //console.log("newUserData response", response);
        if (response.status === 201) {
          toast({
            title: "Status",
            description: "User Created Successesfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          if (response.status === 401) {
            logout();
            toast({
              title: "Session expired",
              description: "Your session has expired. Please login again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }

        setNewUserData(initialState); // Reset the state
        setIsOpen(false); // Close the modal
        fetchUsers(); // Refresh the user list after adding
      })
      .catch((error) => {
        //console.log(error);
        if (error.response && error.response.status === 400) {
          toast({
            title: "User Creation Failed",
            description: "User already exists.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      });
  };

  const fetchUsers = () => {
    axiosInstance
      .get(`/auth/admin/get_users?page=${currentPage}&limit=${limit}`)
      .then((response) => {
        //console.log("API Response:", response.data);
        if (response.data && response.data.data) {
          setUsers(response.data.data);
          if (response.data && response.data.meta) {
            setTotalPages(response.data.meta.total_pages);
          }
        } else {
          console.error("Unexpected API response format");
          if (response.status === 401) {
            logout();
            toast({
              title: "Session expired",
              description: "Your session has expired. Please login again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }
      });
  };

  const changeUserRole = (email, role) => {
    //console.log("Changing user role function");
    const payload = {
      email: email,
      role: role,
    };
    //console.log("payload:", payload);

    axiosInstance
      .put("/auth/admin/change_role", payload)
      .then((response) => {
        if (response.status === 201) {
          toast({
            title: "Status",
            description: "Role changed successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        }
        // Refresh the user list
        fetchUsers();
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const deleteUser = (email) => {
    axiosInstance
      .delete(`/auth/admin/delete_user?email=${encodeURIComponent(email)}`)
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: "Status",
            description: "User Deleted Successesfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          if (response.status === 401) {
            logout();
            toast({
              title: "Session expired",
              description: "Your session has expired. Please login again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        }
        // Refresh the user list
        fetchUsers();
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  // Handle form submission
  const handleSubmit = () => {
    // Check if the password is at least 8 characters.
    if (newUserData.password.length < 8) {
      setPasswordError("Password should be at least 8 characters.");

      // Show the error toast
      toast({
        title: "Error",
        description: "Password should be at least 8 characters.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return; // Stop further execution
    }
    const validationErrors = validate(newUserData);
    if (Object.keys(validationErrors).length === 0) {
      // No errors, you can now submit data
      //console.log(newUserData);
      addUser();
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <Box overflowX="auto">
      <Button
        onClick={() => setShowAddUserModal(true)}
        ml={4}
        mb="6px"
        mt={3}
        px={5}
        py={2}
        boxShadow="md"
        fontSize="lg"
        fontWeight="semibold"
        bgColor="green.500"
        textColor="white"
        _hover={{
          bg: "teal.500",
          boxShadow: "lg",
        }}
      >
        Add User
      </Button>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>USERNAME</Th>
            <Th>EMAIL</Th>
            <Th>ROLE</Th>
            <Th>ORGANIZATION</Th> {/* New header */}
            <Th>ACTIONS</Th>
            <Th>CHANGE ROLE</Th>
          </Tr>
        </Thead>
        <Tbody>
          {/* {Array.isArray(users) && users.map((user, index) => ( */}
          {Array.isArray(users) &&
            users.slice(0, limit).map((user, index) => (
              <Tr key={index}>
                <Td>{user.username}</Td>
                <Td>{user.email}</Td>
                <Td>{user.role}</Td>
                <Td>{user.organization}</Td> {/* Display organization name */}
                <Td>
                  <Button
                    colorScheme="red"
                    bgColor="red.500"
                    textColor="white"
                    size="sm"
                    onClick={() => setDeleteUserAlertOpen(true)}
                  >
                    Delete
                  </Button>
                </Td>
                <AlertDialog
                  isOpen={isDeleteUserAlertOpen}
                  onClose={() => setDeleteUserAlertOpen(false)}
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent>
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Delete User
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        Are you sure you want to delete the user?
                      </AlertDialogBody>

                      <AlertDialogFooter>
                        <Button
                          variant="outline"
                          onClick={() => setDeleteUserAlertOpen(false)}
                        >
                          No
                        </Button>
                        <Button
                          colorScheme="red"
                          bgColor="red.500"
                          textColor="white"
                          onClick={() => {
                            deleteUser(user.email);
                            setDeleteUserAlertOpen(false);
                          }}
                          ml={3}
                        >
                          Yes
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
                <Td>
                  {/* <Select
                    defaultValue={user.role}
                    onChange={(e) => {
                      const updatedRole = e.target.value;
                      changeUserRole(user.email, updatedRole);
                    }}
                  > */}
                  <Select
                    value={user.role}
                    onChange={(e) => {
                      const updatedRole = e.target.value;
                      setUserToChange({
                        email: user.email,
                        role: updatedRole,
                      });
                      setIsRoleChangeAlertOpen(true);
                    }}
                  >
                    <option value="" disabled>
                      Select a role
                    </option>
                    {roles.map((role, idx) => (
                      <option key={idx} value={role}>
                        {role}
                      </option>
                    ))}
                  </Select>
                </Td>
              </Tr>
            ))}
        </Tbody>
        <Tfoot ml={0}>
          <Box mt={2} mb={2} ml={0} display="flex" justifyContent="center">
            <Button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev) => prev - 1)}
              _hover={{}}
              bgColor="blue.500"
              textColor="white"
            >
              Previous
            </Button>
            <Box mx={2} pt={2}>
              Page {currentPage} of {totalPages}
            </Box>
            <Button
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage((prev) => prev + 1)}
              _hover={{}}
              bgColor="blue.500"
              textColor="white"
            >
              Next
            </Button>
          </Box>
        </Tfoot>
      </Table>
      <Modal
        isOpen={showAddUserModal}
        onClose={() => setShowAddUserModal(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={errors.username}>
              <FormLabel htmlFor="text">Username</FormLabel>
              <Input
                id="text"
                type="text"
                placeholder="Your username"
                maxLength={25}
                value={newUserData.username}
                onChange={(e) =>
                  setNewUserData({ ...newUserData, username: e.target.value })
                }
              />
              <FormErrorMessage>{errors.username}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.email} mt={4}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="Your email"
                maxLength={80}
                value={newUserData.email}
                onChange={(e) =>
                  setNewUserData({ ...newUserData, email: e.target.value })
                }
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!passwordError} mt={4}>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                id="password"
                type="password"
                placeholder="Your password"
                value={newUserData.password}
                onChange={(e) => {
                  const passwordValue = e.target.value;
                  if (passwordValue.length < 8) {
                    setPasswordError(
                      "Password should be at least 8 characters."
                    );
                    setIsPasswordValid(false);
                  } else {
                    setPasswordError("");
                    setIsPasswordValid(true);
                  }
                  setNewUserData({ ...newUserData, password: passwordValue });
                }}
              />
              <FormErrorMessage>{passwordError}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.role} mt={4}>
              <FormLabel>Role</FormLabel>
              <Select
                value={newUserData.role}
                onChange={(e) =>
                  setNewUserData({ ...newUserData, role: e.target.value })
                }
              >
                <option value="" disabled>
                  Select a role
                </option>
                {roles.map((role, idx) => (
                  <option key={idx} value={role}>
                    {role}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.role}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              bgColor="blue.500"
              textColor="white"
              onClick={handleSubmit}
              isDisabled={!isPasswordValid}
            >
              Add User
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={isRoleChangeAlertOpen}
        onClose={() => setIsRoleChangeAlertOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Change Role
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to change the role?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setIsRoleChangeAlertOpen(false)}>
                No
              </Button>
              <Button
                colorScheme="red"
                bgColor="red.500"
                textColor="white"
                onClick={() => {
                  changeUserRole(userToChange.email, userToChange.role);
                  setIsRoleChangeAlertOpen(false);
                }}
                ml={3}
              >
                Yes
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

export default ManagerPanel;
