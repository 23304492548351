import React, { useEffect, useRef, useState } from "react";
import {
  VStack,
  Text,
  Input,
  Flex,
  Box,
  IconButton,
  CircularProgress,
} from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";
import { TiUpload } from "react-icons/ti";
import { useColorMode } from "@chakra-ui/react";

function FileUpload({ title, name, onChange, reset, selectedFile, isEditable }) {
  const { colorMode } = useColorMode();
  const fileInputRef = useRef(); // Create a ref
  const [progress, setProgress] = useState(0); // progress state

  const changeHandler = (event) => {
    const file = event.target.files[0];
    onChange(event.target.name, file);

    // Simulate reading file progress
    const reader = new FileReader();
    reader.onprogress = (data) => {
      if (data.lengthComputable) {
        const progress = parseInt((data.loaded / data.total) * 100, 10);
        setProgress(progress);
      }
    };
    reader.onloadend = () => {
      setProgress(0); // reset progress after file is fully read
    };
    reader.readAsArrayBuffer(file); // just reading the file, replace this or enhance as needed
  };

  return (
    // <VStack spacing={2} align="stretch">
    //     <Text as="h6" size="xs" fontWeight="bold" color="blackAlpha.700">{title}</Text>
    //     <Input ref={fileInputRef} placeholder={title} name={name} type="file" onChange={changeHandler} p={1} />
    // </VStack>
    <Flex align="center" justify="space-between" mr={4}>
      <Text
        as="h6"
        size="xs"
        fontWeight="bold"
        color={colorMode === "dark" ? "gray.300" : "blackAlpha.700"}
      >
        {title}
      </Text>
      <Box>
        <Input
          ref={fileInputRef}
          placeholder={title}
          name={name}
          type="file"
          onChange={changeHandler}
          p={1}
          display="none" // hide the default input
        />
        <IconButton
          isDisabled={!isEditable}
          icon={
            progress > 0 ? (
              <CircularProgress
                isIndeterminate
                value={progress}
                size="24px"
                color="green.500"
              />
            ) : (
              <TiUpload />
            )
          }
          color={
            selectedFile
              ? "green.500"
              : colorMode === "dark"
              ? "gray.300"
              : "blackAlpha.500"
          }
          onClick={() => fileInputRef.current.click()}
          variant="outline"
          aria-label={`Upload ${title}`}
        />
      </Box>
    </Flex>
  );
}

export default FileUpload;
