import React from "react";
import {
  Box,
  Image,
  Heading,
  Divider,
  Button,
  Flex,
  Stack,
  useColorMode,
} from "@chakra-ui/react";

const ConnectBox = ({ imageSrc, altText, platformName, handleConnect }) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      w="80%"
      h="100%"
      overflow="hidden"
      m={0}
      bg={colorMode === "dark" ? "gray.700" : "white"}
    
    >
      <Flex direction="column" alignItems="center" h="100%" p={3} pt={2} pb={2} m={0} >
        <Image
          src={imageSrc}
          alt={altText}
          borderRadius="lg"
          boxSize="70px"
          objectFit="cover"
        />
        <Heading mt={2} mb={0} size="xs">
          {platformName}
        </Heading>
        <Divider mt={2} />
        <Stack spacing={2} direction="row" justify="center">
          <Button
            mt={0}
            mr={2}
            size="sm"
            variant="solid"
            _hover={{}}
            bgColor="blue.500"
            textColor="white"
            onClick={() => handleConnect(platformName)}
            isDisabled={platformName === "Google"}
          >
            Connect
          </Button>
        </Stack>
      </Flex>
    </Box>
  );
};

export default ConnectBox;
