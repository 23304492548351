import React, { useEffect } from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useColorMode,
  Heading,
} from "@chakra-ui/react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";

const ViewTemplateModal = ({ formData, sequenceCount, isOpen, onClose }) => {
  const { colorMode } = useColorMode();

  // Initialize TipTap editors for subject and body with read-only configuration
  const subjectPreviewEditor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: formData.subject || "", // Initial content for subject
    editable: false, // Read-only mode
  });

  const bodyPreviewEditor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: formData.sequence_template || "", // Initial content for body
    editable: false, // Read-only mode
  });

  // Update content in the preview editors when formData changes
  useEffect(() => {
    if (subjectPreviewEditor && bodyPreviewEditor) {
      subjectPreviewEditor.commands.setContent(formData.subject || "");
      bodyPreviewEditor.commands.setContent(formData.sequence_template || "");
    }
  }, [formData, subjectPreviewEditor, bodyPreviewEditor]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Email Preview</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            borderWidth={1}
            borderRadius="sm"
            overflowY="auto"
            maxH="60vh" // Adjust height for modal content as needed
            p={4}
            bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
            sx={{
              "&::-webkit-scrollbar": { display: "none" },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            {/* Subject Preview */}
            {sequenceCount < 1 && (
              <Box display="flex" mb={4} fontWeight="bold">
                <Heading fontSize="md" mb={2} mr={2}>
                  Subject:
                </Heading>
                <Box
                  as={EditorContent}
                  editor={subjectPreviewEditor}
                  sx={{
                    "& .ProseMirror": {
                      minHeight: "auto",
                      maxHeight: "none",
                      overflow: "visible",
                    },
                  }}
                />
              </Box>
            )}

            {/* Body Preview */}
            <Box>
              {/* <Heading fontSize="md" mb={2}>Body:</Heading> */}
              <Box
                as={EditorContent}
                editor={bodyPreviewEditor}
                sx={{
                  "& .ProseMirror": {
                    minHeight: "auto",
                    maxHeight: "none",
                    overflow: "visible",
                  },
                }}
              />
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewTemplateModal;
