import React from "react";
import { useToast, Button, Box, Flex, Tooltip, Text } from "@chakra-ui/react";
import FileUpload from "../common/FileUpload";
import useFileUpload from "../../hooks/DDQ_RFP/useFileUpload";

const Upload = ({
  selectedRole,
  selectedModule,
  selectedMinute,
  setSelectedMinute,
  fetchedMinutes,
  setFetchedMinutes,
  minutesList,
  setMinutesList,
}) => {
  const toast = useToast();
  const { isLoading, selectedFiles, changeHandler, handleUpload } =
    useFileUpload(
      toast,
      selectedRole,
      selectedModule,
      selectedMinute,
      setSelectedMinute,
      fetchedMinutes,
      setFetchedMinutes,
      minutesList,
      setMinutesList,
    );

  return (
    <Box>
      <Flex direction="row" align="center" w="100%" mt={0} ml={1}>
        <Text fontWeight="bold" mr={4} pt={3}>
          Upload File
        </Text>
        <Tooltip label="Upload" placement="top">
          <span>
            <FileUpload
              name="transcript_file"
              onChange={changeHandler}
              selectedFile={selectedFiles.transcript_file.file}
              isEditable={!isLoading}
            />
          </span>
        </Tooltip>
      </Flex>
      <Button
        width="100%"
        p={3}
        mt={2}
        onClick={handleUpload}
        isLoading={isLoading}
        loadingText="Uploading..."
        _hover={{}}
        bgColor="blue.500"
        textColor="white"
      >
        Upload
      </Button>
    </Box>
  );
};

export default Upload;
