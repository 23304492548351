import React, { useState, useEffect } from "react";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Badge,
  Divider,
  useColorMode,
  Skeleton,
  SkeletonText,
  VStack,
} from "@chakra-ui/react";
import axiosInstance from "../../../helpers/axiosInstance";

const EmailThreadModal = ({
  isOpen,
  onClose,
  campaignId,
  connectedEmail,
  conversationId,
}) => {
  const [threads, setThreads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (campaignId && connectedEmail && conversationId && isOpen) {
      setIsLoading(true); // Set loading to true when the modal opens and data is being fetched

      const payload = {
        campaign_id: campaignId,
        connected_email: connectedEmail,
        conversation_id: conversationId,
      };

      axiosInstance
        .post(`/threads/get-thread`, payload)
        .then((response) => {
          if (response.data.success) {
            // Sort the threads by `createdDateTime` in descending order
            const sortedThreads = response.data.data.thread_email_list.sort(
              (a, b) =>
                new Date(b.createdDateTime) - new Date(a.createdDateTime)
            );
            setThreads(sortedThreads); // Set the sorted threads to the state
          } else {
            setThreads([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching email threads:", error);
          setThreads([]); // Reset threads on error
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false when the data is fetched
        });
    }
  }, [campaignId, connectedEmail, conversationId, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        sx={{
          width: "70%", // Sets the width to 70% of the viewport width
          maxWidth: "none", // Removes any maximum width constraints
          height: "78vh", // Sets the height to auto
        }}
      >
        <ModalHeader>Email Thread</ModalHeader>
        <Divider m={0} p={0} />
        <ModalCloseButton />
        <ModalBody>
          <Box
            h="66vh"
            overflowY="scroll"
            scrollBehavior="hidden"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            {isLoading ? (
              // Show skeletons while loading
              <VStack spacing={4}>
                {[...Array(4)].map((_, index) => (
                  <Box
                    key={index}
                    p={4}
                    bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
                    borderWidth={1}
                    borderRadius="md"
                    width="100%"
                  >
                    {/* Skeleton for the email subject */}
                    <Skeleton height="20px" width="40%" mb={3} />

                    {/* Skeleton for the sender and date */}
                    <Skeleton height="15px" width="20%" mb={3} />

                    {/* Skeleton for the body preview */}
                    <SkeletonText
                      mt="4"
                      noOfLines={4}
                      spacing="4"
                      skeletonHeight="15px"
                    />
                  </Box>
                ))}
              </VStack>
            ) : (
              // Show threads when loading is complete
              threads.map((thread, index) => {
                return (
                  <Box
                    h="40vh"
                    key={index}
                    p={4}
                    pt={2}
                    bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
                    color={colorMode === "dark" ? "white" : "black"}
                    borderWidth={1}
                    borderRadius="md"
                    mb={3}
                    position="relative"
                    display="flex"
                    justifyContent="space-between" // Distributes the items across the available space
                    alignItems="flex-start" // Aligns items at the top
                    overflowY="scroll"
                    scrollBehavior="hidden"
                    sx={{
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      msOverflowStyle: "none",
                      scrollbarWidth: "none",
                    }}
                  >
                    <Box flex="1">
                      {/* Email body with blue links */}
                      <Box
                        dangerouslySetInnerHTML={{ __html: thread.body }}
                        whiteSpace="pre-wrap"
                        sx={{
                          a: {
                            color: "blue !important", // Ensures that all links are blue
                            textDecoration: "underline !important", // Ensures all links are underlined
                          },
                          div: {
                            color: "black !important", // Override the unsubscribe div color
                          },
                        }}
                      />
                    </Box>

                    <Badge
                      colorScheme={
                        thread["draft email"]
                          ? "orange"
                          : thread.user_sent
                          ? "blue"
                          : "green"
                      }
                      position="sticky"
                      top="10px"
                      zIndex={1}
                    >
                      {thread["draft email"]
                        ? "Draft"
                        : thread.user_sent
                        ? "Sent"
                        : "Received"}
                    </Badge>
                  </Box>
                );
              })
            )}
            {!isLoading && threads.length === 0 && <Text></Text>}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EmailThreadModal;
